import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {
    Button, Card, CardContent,
    Grid, Typography
} from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';
import { STORAGE_KEYS } from '../../utils/utils';
import { getCheckToken } from '../../APICalls';

const ButtonStyles = { height: '35px', alignItems: 'center', display: 'flex', justifyContent: 'center' };

const PAGES_CARDS = [
    { "title": <b>Sales Order <br /> Processing</b>, redirect: "/SOPHome", logic: "ALL" },
    { "title": <b>Order Status <br /> Tracking</b>, redirect: "/orderTracking", logic: "VAO" },
    { "title": <b>Invoice <br /> Management</b>, redirect: "/invoiceManagement", logic: "VAO" }
]

const MiddlePageView = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [userTokenData, setUserTokenData] = useState(null);

    const navigate = useNavigate();

    const validationCallAPI = async () => {
        const { status, data } = await getCheckToken(localStorage.getItem(STORAGE_KEYS.TOKEN));
        if (status === 200) {
            setUserTokenData(data);
        }
    }

    const handleNavigate = (navigationURL) => () => {
        navigate(navigationURL)
    }

    const filteredData = () => {
        const isVao = userTokenData?.username?.includes("@vao.world");
        if (isVao) {
            return PAGES_CARDS
        } else {
            return PAGES_CARDS.filter((element) => element.logic === "ALL");
        }
    }

    useEffect(() => {
        validationCallAPI();
    }, [])

    return (
        <>
            <Grid
                container
                paddingTop={isSmallScreen ? 5 : 10}
                paddingBottom={isSmallScreen ? 10 : 10}
                alignItems={isSmallScreen ? 'center' : "center"}
                style={{
                    backgroundImage: 'url(https://vao-open-bucket.s3.amazonaws.com/landing-page/Calculator-background.jpg',
                    backgroundSize: isSmallScreen ? '100% 100%' : '100% 100%',
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    boxShadow: '0',
                    minHeight: '100vh',
                    fontFamily: 'Montserrat, sans-serif',
                    backgroundAttachment: "fixed"
                }}
                spacing={isSmallScreen ? 2 : 10}
            >
                <Grid item xs={12}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12} padding={isSmallScreen ? 2 : 0}>
                                    <Typography
                                        style={{
                                            fontFamily: 'Montserrat, sans-serif',
                                        }}
                                        variant={isSmallScreen ? "h4" : "h3"}
                                        textAlign="center"
                                        textJustify="inter-word"
                                        color="white"
                                    >
                                        <b>
                                            Welcome to VAO!
                                        </b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} padding={isSmallScreen ? 2 : 0}>
                                    <Typography
                                        style={{
                                            fontFamily: 'Montserrat, sans-serif',
                                        }}
                                        variant={isSmallScreen ? "h5" : "h4"}
                                        textAlign="center"
                                        textJustify="inter-word"
                                        color="white"
                                    >
                                        Please choose one of our products to start.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" >
                        {filteredData().map((element) => {
                            return (
                                <Grid item xs={12} md={4}>
                                    <Button
                                        onClick={handleNavigate(element.redirect)}
                                    >
                                        <Card
                                            style={{
                                                width: isSmallScreen ? '70vw' : "100%",
                                                backgroundColor: "#d9d9d9",
                                                color: "#183d44"
                                            }}
                                            sx={{ padding: isSmallScreen ? 2 : 5 }}
                                        >
                                            <CardContent>
                                                <Typography
                                                    variant="h4"
                                                    textAlign="center"
                                                >
                                                    <b>
                                                        {element.title}
                                                    </b>
                                                </Typography>
                                            </CardContent>
                                        </Card >
                                    </Button>
                                </Grid>
                            )
                        })}

                    </Grid>
                </Grid>
            </Grid >

        </>
    )
}

export default MiddlePageView;