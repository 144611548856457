import React, { useState } from 'react'
import { FileUploader } from "react-drag-drop-files";
import {
    Modal, Typography, Card, CardContent,
    CardActions, Button, Grid, CircularProgress,
} from '@mui/material';
import { green, pink } from '@mui/material/colors';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

const FILE_TYPES = ["CSV", "csv"];

const UploadCSVModal = ({
    openModal, handleCloseModal, handleAPICall, selectedMasterDataCollection
}) => {

    const [isTypeError, setIsTypeError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleAPIResponse = async (file) => {
        await handleAPICall(file)
            .then((response) => {
                if (response === "Successful") {
                    setIsLoading(false);
                    setSuccess(true);
                    setIsTypeError(false);
                    setErrorMessage(null);
                    handleCloseModal();
                } else {
                    setIsLoading(false);
                    setSuccess(false);
                    setIsTypeError(false);
                    const { message } = response;
                    setErrorMessage(message);
                }
            });
    }


    const handleChangeFile = (file) => {
        setIsTypeError(false);
        setIsLoading(true);
        setSuccess(false);
        handleAPIResponse(file);
    };

    const handleCloseMainModal = () => {
        handleCloseModal();
    }

    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
            }}
        >
            <Grid
                container
                justifyContent="center"
                flexDirection="row"
            >
                <Card
                    style={{
                        width: "50%",
                    }}
                >
                    <CardContent>
                        <Typography
                            variant="h4"
                            textAlign="center"
                        >
                            Upload Master Data: Browse and select a file to upload.
                        </Typography>
                        <Typography
                            color="text.secondary"
                            textAlign="center"
                        >
                            Support for uploading: CSV files.
                        </Typography>
                        <Grid
                            container
                            justifyContent="center"
                            paddingTop={5}
                        >
                            <FileUploader
                                handleChange={handleChangeFile}
                                name="file"
                                types={FILE_TYPES}
                                hoverTitle="Please Drag and drop a CSV File or click here and select a File to upload."
                                onTypeError={() => setIsTypeError(true)}
                            />
                            {isLoading && (
                                <Grid
                                    container
                                    justifyContent="center"
                                    paddingTop={2}
                                >
                                    <Grid
                                        container
                                        justifyContent="center"
                                    >
                                        <CircularProgress color="success" />

                                    </Grid>
                                    <Typography
                                        color="green"
                                        textAlign="center"
                                    >
                                        <b>
                                            We are processing the file, please wait...
                                        </b>
                                    </Typography>
                                </Grid>
                            )}
                            {success && (
                                <Grid
                                    container
                                    justifyContent="center"
                                    paddingTop={2}
                                >
                                    <Grid
                                        container
                                        justifyContent="center"
                                    >
                                        <DoneIcon sx={{ color: green[500] }} />
                                    </Grid>
                                    <Typography
                                        color="green"
                                        textAlign="center"
                                    >
                                        <b>
                                            Uploaded succesfully!
                                        </b>
                                    </Typography>
                                </Grid>
                            )}
                            {isTypeError && (
                                <Grid
                                    container
                                    justifyContent="center"
                                    paddingTop={2}
                                >
                                    <Grid
                                        container
                                        justifyContent="center"
                                    >
                                        <CloseIcon sx={{ color: pink[500] }} />
                                    </Grid>
                                    <Typography
                                        color="red"
                                        textAlign="center"
                                    >
                                        <b>
                                            Unsupported file format. Please upload a file in one of the following supported formats: CSV.
                                        </b>
                                    </Typography>
                                </Grid>
                            )}
                            {errorMessage && (
                                <Grid
                                    container
                                    justifyContent="center"
                                    paddingTop={2}
                                >
                                    <Grid
                                        container
                                        justifyContent="center"
                                    >
                                        <CloseIcon sx={{ color: pink[500] }} />
                                    </Grid>
                                    <Typography
                                        color="red"
                                        textAlign="center"
                                    >
                                        <b>
                                            Error: {errorMessage}
                                        </b>
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                    <CardActions
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            size="medium"
                            variant="contained"
                            color='error'
                            onClick={handleCloseMainModal}
                        >
                            Close
                        </Button>
                    </CardActions>
                </Card >
            </Grid>
        </Modal >
    )
}

export default UploadCSVModal;
