import _ from 'lodash';

import React, { useState, useEffect } from "react";
import { Grid, IconButton } from "@mui/material";
import { convert } from 'html-to-text';

import DataObjectIcon from '@mui/icons-material/DataObject';
import PropTypes from 'prop-types';
import EmailIcon from '@mui/icons-material/Email';
import DownloadIcon from '@mui/icons-material/Download';
import './email.css';

const EMAIL_CONSTANTS = ["Date", "From", "To", "Subject"]

const LoadEmailHTML = ({ rowFile, additionalData, companyName, PONumber, isInvoice = false }) => {

    const [emailData, setEmailData] = useState(null);

    const HtmlToTextOptions = {
        wordwrap: 130,
        // ...
    };

    const downloadEmailText = () => {
        const currentDateValue = new Date();
        // Extract date and time components
        const year = currentDateValue.getFullYear();
        const month = (currentDateValue.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, so add 1
        const day = currentDateValue.getDate().toString().padStart(2, '0');
        const hours = currentDateValue.getHours().toString().padStart(2, '0');
        const minutes = currentDateValue.getMinutes().toString().padStart(2, '0');
        const seconds = currentDateValue.getSeconds().toString().padStart(2, '0');

        // Create formatted date and time strings
        const formattedDateTime = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;

        //Library to convert HTML to TXT with all styles and configurations.
        const text = convert(rowFile, HtmlToTextOptions);

        const blob = new Blob([text], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${companyName}_${PONumber}_${formattedDateTime}.txt`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    useEffect(() => {
        if (additionalData) {
            const { date, from, subject, to } = additionalData;
            setEmailData({
                "Date": date
                    ? date.toString().toLocaleString('en-US')
                    : "--",
                "From": from
                    ? from.toString()
                    : "--",
                "Subject": subject
                    ? subject.toString()
                    : "--",
                "To": to
                    ? to.toString()
                    : "--"
            });
        }
    }, [additionalData]);

    const visualizeHTML = () => {
        return {
            __html: rowFile
        }
    }

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{
                overflow: "auto"
            }}
        >
            <Grid
                item
                xs={12}
                paddingBottom={2}
            >
                {!isInvoice && (
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        padding={3}
                    >
                        <Grid item>
                            <DataObjectIcon />
                        </Grid>
                        <Grid item>
                            <b style={{
                                color: "#1976d2"
                            }}
                            >
                                Email viewer:
                            </b>
                        </Grid>
                    </Grid>
                )}
                <Grid
                    container
                    textAlign="left"
                >
                    {!_.isNil(emailData) && (
                        EMAIL_CONSTANTS.map((element) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <b>{element}:  &nbsp;{emailData[element]}</b>
                                </Grid>
                            )
                        }))}
                </Grid>
                <Grid
                    container
                    justifyContent="center"
                >
                    <IconButton
                        onClick={downloadEmailText}
                        edge="end"
                        color="primary"
                    >
                        <DownloadIcon />
                        <EmailIcon />
                    </IconButton>
                </Grid>
                {
                    <iframe
                        className="article"
                        title="htmlViewer"
                        srcDoc={rowFile}
                    >
                    </iframe>
                }
            </Grid>
        </Grid>
    )
}

LoadEmailHTML.propTypes = {
    currentRow: PropTypes.number.isRequired,
};

export default LoadEmailHTML;
