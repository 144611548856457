import _ from 'lodash';

import { Button, Card, CardActions, CardContent, Grid, Modal, Tab, Tabs, Typography, Tooltip } from '@mui/material'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import React, { useEffect, useState } from 'react'

import DatasheetView from './AuditVisualization/DatasheetView'
import DescriptionIcon from '@mui/icons-material/Description';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import EmptyDataComponent from '../DetailsPage/UtilsComponents/EmptyDataComponent';
import LoadingComponent from '../DetailsPage/UtilsComponents/LoadingComponent';
import AttatchmentView from '../DetailsPage//AttatchmentsView/AttatchmentView';
import LoadEmailHTML from '../DetailsPage//Email/LoadEmailHTML';
import { //getAttatchmentsData,
  getCompanyData, getEmailAPI, getEmailAditionalDataAPI, getAttatchmentsData
} from '../../../../APICalls';
import { STORAGE_KEYS } from '../../../../helpers/helpers';
import EmailIcon from '@mui/icons-material/Email';



function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ModalCurrPrevValues = ({
  transactionIdValue, POJSON, openModal, handleCloseModal, previousModify, nextModify, totalKeys, metadata
}) => {
  const [selectedKey, setSelectedKey] = useState(0);
  const [selectedValue, setSelectedValue] = useState(null);
  const [middlePanelTabValue, setMiddlePanelTabValue] = useState(0);
  const [updatedState, setUpdatedState] = useState(false);
  const [affectedRows, setAffectedRows] = useState(null);
  const [PDFAttatchments, setPDFAttatchments] = useState([]);
  const [leftPanelTabValue, setLeftPanelTabValue] = useState(0);
  const [bodyText, setBodyText] = useState(null);
  const [emailAdditionalData, setEmailAdditionalData] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [isLoadingAttatchments, setIsLoadingAttatchments] = useState(false);
  const [isErrorLoadingAttatchments, setIsErrorLoadingAttatchments] = useState(false);
  const [excelAttatchments, setExcelAttatchments] = useState([]);
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [isErrorLoadingEmail, setIsErrorLoadingEmail] = useState(false);


  const getPONumberAttatchment = () => {
    const currentPONumber = selectedValue
      ? selectedValue.find(element => element["Data Variables"] === "Purchase order #")
      : '';
    if (currentPONumber) {
      return currentPONumber["Data input"]
    } else {
      return ""
    }
  }

  const loadEmailAdditionalData = async (token) => {
    if (token) {
      setIsLoadingEmail(true);
      setIsLoadingAttatchments(true);
      const bodyAPICall = await getEmailAditionalDataAPI(token, transactionIdValue, 'COMPLETED')
        .then(response => {
          setIsLoadingEmail(false);
          return response
        });
      const { status, data } = bodyAPICall
      if (!_.isNil(data)) {
        setIsLoadingEmail(false);
        setIsErrorLoadingEmail(false);
        setEmailAdditionalData(data)
        const { attachment_locations } = data;
        const excelOutputs = attachment_locations
          .filter((attatchment) => _.includes(attatchment.toLowerCase().trim(), ".xls"))
        const pdfOutputs = attachment_locations
          .filter((attatchment) => _.includes(attatchment.toLowerCase().trim(), ".pdf"))
        setIsLoadingAttatchments(true);
        const pdfValues = !_.isEmpty(pdfOutputs)
          ? await Promise.all(pdfOutputs.map((PDFFilePath) => {
            return getAttatchmentsData(token, transactionIdValue, 'COMPLETED', PDFFilePath, false);
          })).then(response => {
            setIsLoadingAttatchments(true);
            return response
          })
          : [];
        const excelValues = !_.isEmpty(excelOutputs)
          ? await Promise.all(excelOutputs.map(async (excelFilePath) => {
            return getAttatchmentsData(token, transactionIdValue, 'COMPLETED', excelFilePath, true);
          })).then(response => {
            setIsLoadingAttatchments(true);
            return response
          })
          : [];
        setPDFAttatchments(pdfValues);
        setExcelAttatchments(excelValues);
        if (pdfValues.length !== 0 || excelValues.length !== 0) {
          setLeftPanelTabValue(1)
        }
        setIsLoadingAttatchments(false);
      } else {
        setIsLoadingEmail(false);
        setIsErrorLoadingEmail(true);
        setIsLoadingAttatchments(false);
        setIsErrorLoadingAttatchments(false);
        setEmailAdditionalData([]);
        setExcelAttatchments([]);
        setPDFAttatchments([]);
      }
    }
  }

  const loadBodyEmail = async (token) => {
    if (token) {
      const bodyAPICall = await getEmailAPI(token, transactionIdValue, 'COMPLETED');
      const { status, data } = bodyAPICall
      if (!_.isNil(data)) {
        if (data.indexOf("<html") !== -1) {
          setBodyText(data);
        } else {
          const checkedEmail = data.replaceAll(/\n/g, "<br>")
          setBodyText(checkedEmail);
        }
      } else {
        setBodyText("No Email to show");
      }
    }
  }

  const adjustedAPICallCompanyData = async (token) => {
    const POJSONAPICall = await getCompanyData(token, transactionIdValue, 'COMPLETED')
      .then(response => {
        return response
      });
    const { status, data } = POJSONAPICall;
    if (status === 200) {
      setCompanyData(data);
    } else {
      setCompanyData(null);
    }
  }

  const handleChangeLeftTab = (event, newValue) => {
    setLeftPanelTabValue(newValue);
  };

  const handleChangeMiddleTab = (event, newValue) => {
    setUpdatedState(false);
    const value = event.target.textContent.replace("PO CSV #", "")
    if (Number(value) - 1 in POJSON.outputs) {
      setSelectedValue(POJSON.outputs[value - 1].data);
      setAffectedRows(metadata.changes[value - 1].rows);
    } else {
      if (!_.isNil(metadata['deleted_csvs'])) {
        const csv_removed = event.target.textContent.replace("PO CSV #", "").replace(" Deleted", "")
        setSelectedValue(metadata['deleted_csvs'][csv_removed - 1].data);
      }
    }
    setMiddlePanelTabValue(newValue);
  };

  const handlePrevClick = () => {
    previousModify()
  };

  const handleNextClick = () => {
    nextModify()
  };

  const checkLeftPanelComponent = () => {

    if (leftPanelTabValue === 0) {
      return (
        <LoadEmailHTML
          rowFile={bodyText}
          additionalData={emailAdditionalData}
          companyName={companyData}
          PONumber={getPONumberAttatchment()}
        />
      )
    }
    if (leftPanelTabValue === 1) {
      if (isErrorLoadingAttatchments) {
        return <EmptyDataComponent />
      }
      if (isLoadingAttatchments) {
        return <LoadingComponent />
      }
      return (
        <AttatchmentView
          POPDF={PDFAttatchments}
          excel={excelAttatchments}
          companyName={companyData}
          PONumber={getPONumberAttatchment()}
        />
      )
    };
  }

  useEffect(() => {
    if (POJSON) {
      const { outputs } = POJSON;
      if (!_.isNil(outputs) && !_.isEmpty(outputs)) {
        const tokenData = localStorage.getItem(STORAGE_KEYS.TOKEN);
        loadEmailAdditionalData(tokenData)
        loadBodyEmail(tokenData)
        adjustedAPICallCompanyData(tokenData)
        for (let key in outputs) {
          if (outputs.hasOwnProperty(key)) {
            setSelectedKey(key);
            setSelectedValue(outputs[key]['data']);
            break
          }
        }
        setUpdatedState(false)
      }
    }

  }, [POJSON]);

  useEffect(() => {
    if (selectedValue) {
      setUpdatedState(true);
    }
  }, [selectedValue]);

  useEffect(() => {
    if (!_.isNil(metadata) && !_.isEmpty(metadata)) {
      setAffectedRows(metadata.changes[0].rows);
    }
  }, [metadata]);

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'auto',
      }}
    >
      <Grid
        container
        justifyContent="center"
        flexDirection="row"
      >
        <Card
          style={{
            width: "80%",
            height: '100%'
          }}
        >
          <CardActions
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              size="medium"
              variant="contained"
              color='error'
              onClick={handleCloseModal}
            >
              X
            </Button>
          </CardActions>
          <Tabs
            value={middlePanelTabValue}
            onChange={handleChangeMiddleTab}
            variant="scrollable"
            scrollButtons
          >
            {!_.isNil(totalKeys)
              ? totalKeys.map((actualTab) => {
                const currentTabLabel = `PO CSV #${Number(actualTab) + 1}`;
                return (
                  <Tab
                    icon={<DescriptionIcon />}
                    iconPosition="start"
                    label={currentTabLabel}
                    {...a11yProps(actualTab)}
                  />
                )
              })
              : <Tab
                icon={<DescriptionIcon />}
                iconPosition="start"
                label="PO CSV / Excel"
                {...a11yProps(0)}
              />
            }
            {!_.isNil(metadata['deleted_csvs'])
              ? metadata['deleted_csvs'].map((actualTab, index) => {
                const currentTabLabel = `PO CSV #${Number(index) + 1} Deleted`;
                return (
                  <Tab
                    icon={<DescriptionIcon />}
                    iconPosition="start"
                    label={currentTabLabel}
                    {...a11yProps(index)}
                    style={{ textDecoration: 'line-through' }}
                  />
                )
              })
              : <>
              </>
            }

          </Tabs>
          <CardContent>
            <Typography
              variant="h4"
              textAlign="center"
            >
              Current Value Visualization
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip
                title={
                  <>
                    <b>Go to previous Audit</b>
                  </>
                }
                arrow
                placement="bottom"
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={handlePrevClick}
                >
                  <NavigateBeforeIcon />
                </Button>
              </Tooltip>
              <div style={{ flex: '1', textAlign: 'center' }}>
                <Typography variant="h6">
                  Rows changed are highlighted with color.
                </Typography>
              </div>
              <Tooltip
                title={
                  <>
                    <b>Go to next Audit</b>
                  </>
                }
                arrow
                placement="bottom"
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleNextClick}
                >
                  <NavigateNextIcon />
                </Button>
              </Tooltip>
            </div>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={6}>
                <div style={{ maxHeight: '450px', overflow: 'auto' }}>
                  {isErrorLoadingEmail && (
                    <EmptyDataComponent />
                  )}
                  {isLoadingEmail && (
                    <LoadingComponent />
                  )}
                  <Tabs
                    value={leftPanelTabValue}
                    onChange={handleChangeLeftTab}
                    variant="scrollable"
                    scrollButtons
                  >
                    <Tab
                      icon={<EmailIcon />}
                      iconPosition="start"
                      label="EMAIL"
                      {...a11yProps(0)}
                    />
                    <Tab
                      icon={<PictureAsPdfIcon />}
                      iconPosition="start"
                      label="Attatchments"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                  <Card
                    className='cardStyle'
                  >
                    {(!isErrorLoadingEmail && !isLoadingEmail) && (checkLeftPanelComponent())}
                  </Card>
                </div>
              </Grid>
              <Grid item xs={6}>
                {!_.isNil(selectedValue) && updatedState ? (
                  <DatasheetView
                    POJSON={selectedValue}
                    affectedRows={affectedRows}
                  />
                ) : (
                  <h3>No data to show</h3>
                )}
              </Grid>
            </Grid>

          </CardContent>
        </Card >
      </Grid>
    </Modal >
  )
}
