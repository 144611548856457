import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    Button, Grid, AppBar, Toolbar, Divider, Menu, MenuItem,
    Avatar, Badge, IconButton, Tooltip, Typography, Card
} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import useMediaQuery from '@mui/material/useMediaQuery';

import WebFont from 'webfontloader';

import { styled } from '@mui/material/styles';

import AppContext from '../AppContext';
import handleLogout from '../utils/handleLogout';

import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { STORAGE_KEYS } from '../utils/utils';
import './buttonEffect.css'
import ContactUsMainPageSalesOrderNew from './NewLandingPages/SalesOrderAutomation/ContactUsMainPageSalesOrderNew';
import MiddlePageView from './MiddlePage/MiddlePageView';

import { getCheckToken } from '../APICalls';

const Home = React.lazy(() => import('./Home/Home'))
const LoginPage = React.lazy(() => import('./Login/LoginPage'));
const HowItWorks = React.lazy(() => import('./Home/HowItWorks/HowItWorks'));
const Product = React.lazy(() => import('./Home/HomeComponents/Product'));
const Company = React.lazy(() => import('./Home/HomeComponents/About Us/Company'));
const Careers = React.lazy(() => import('./Home/HomeComponents/Careers'));
const BlogsMiddlePage = React.lazy(() => import('./Home/Blogs/BlogsMiddlePage'));
const PrivacyPolicy = React.lazy(() => import('./Home/AboutUs/PrivacyPolicy'));
const ContactUsMainPage = React.lazy(() => import('./ContactUs/ContactUsMainPage'));
const APILayerComponent = React.lazy(() => import('./Home/HomeComponents/APILayer/APILayerComponent'));
const ContactUsMainPageConsultation = React.lazy(() => import('./ContactUsAlts/ContactUsMainPage'));
const ContactUsMainPageSalesOrder = React.lazy(() => import('./NewLandingPages/ContactUsMainPage'));
const WebinarPage = React.lazy(() => import('./NewLandingPages/WebinarLanding/MainPageWebinar'))
const EventPage = React.lazy(() => import('./Home/HomeComponents/Events/EventsHomepage'));
const OrderTrackingProduct = React.lazy(() => import('./Home/HomeComponents/OrderTrackingProduct/HomeOrderTrackingProduct'));
const InvoiceManagementProduct = React.lazy(() => import('./Home/HomeComponents/InvoiceManagementProduct/HomeInvoiceManagement'));
const SignUpPage = React.lazy(() => import('./Login/NewSignup/MainContainer'));

const TITLES = ['product', 'about us', 'blogs', 'privacy policy', "docs"];

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));


const InnerComponent = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isSmallScreen = useMediaQuery('(max-width:1200px)');
    const parsedPathname = pathname.replace('/', '');
    const appContext = useContext(AppContext);

    const [vaoLogo, setVaoLogo] = useState("https://vao-open-bucket.s3.amazonaws.com/landing-page/gray-logo-vao.png");
    const [vaoLogoBlack, setVaoLogoBlack] = useState("https://vao-open-bucket.s3.amazonaws.com/landing-page/VAO-BLACK.png");
    const [vaoLogoWhite, setVaoLogoWhite] = useState("https://vao-open-bucket.s3.amazonaws.com/landing-page/white-logo-cut.png");
    const [vaoLogoBlog, setVaoLogoBlog] = useState('https://vao-open-bucket.s3.amazonaws.com/landing-page/blog-logo.png');
    const [surpasedInitialScreen, setSurpasedInitialScreen] = useState(false);
    const [initialColor, setInitialColor] = useState("https://vao-open-bucket.s3.amazonaws.com/landing-page/VAO-BLACK.png");
    const [selectedView, setSelectedView] = useState(parsedPathname);
    const [openSettings, setOpenSettings] = useState(false);
    const [anchorElSettings, setAnchorElSettings] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState('#07151a');
    const [textColor, setTextColor] = useState("white");
    const [productTooltipOpen, setProductTooltipOpen] = useState(false);
    const [userTokenData, setUserTokenData] = useState(null);
    const [username, setUsername] = useState(null);

    const validationCallAPI = async () => {
        const { status, data } = await getCheckToken(localStorage.getItem(STORAGE_KEYS.TOKEN));
        if (status === 200) {
            setUserTokenData(data);
        }
    }

    const handleTooltipProductOpen = () => {
        setProductTooltipOpen(true);
    }

    const handleTooltipProductClose = () => {
        setProductTooltipOpen(false);
    }

    const fetchImageDS2 = () => {
        setVaoLogoBlack("https://vao-open-bucket.s3.amazonaws.com/landing-page/gray-logo-vao.png");
    }

    const fetchImageWhite = () => {
        setVaoLogoBlack("https://vao-open-bucket.s3.amazonaws.com/landing-page/white-logo-cut.png");
    }

    const fetchImageDS1 = () => {
        setVaoLogo("https://vao-open-bucket.s3.amazonaws.com/landing-page/gray-logo-vao.png");
    }

    const fetchImageDS3 = () => {
        setVaoLogoBlog('https://vao-open-bucket.s3.amazonaws.com/landing-page/blog-logo.png')
    }

    const handleClickSettings = (event) => {
        setAnchorElSettings(event.currentTarget);
        setOpenSettings(true);
    }

    const handleCloseSettings = () => {
        setOpenSettings(false);
        setAnchorElSettings(null);
    }

    const handleSelectedView = (viewValue) => {
        setSelectedView(viewValue);
    };

    const goToDashboard = () => {
        navigate('/middlePage');
    };

    const showInnerComponent = () => {
        if (parsedPathname === "home") {
            return <Home />
        } else if (parsedPathname === "login") {
            return <LoginPage handleSelectedView={handleSelectedView} />
        } else if (parsedPathname.includes("product/sales-order-automation")) {
            return <Product />
        } else if (parsedPathname === "company") {
            return <Company />
        } else if (parsedPathname === "careers") {
            return <Careers />
        } else if (parsedPathname === "hiw") {
            return <HowItWorks />
        } else if (parsedPathname === "blogs") {
            return <BlogsMiddlePage />
        } else if (parsedPathname === "privacy") {
            return <PrivacyPolicy />
        } else if (parsedPathname === "docs") {
            return <APILayerComponent />
        } else if (parsedPathname === "contactUs" || parsedPathname === "contactUs/success") {
            return <ContactUsMainPage />
        }
        else if (parsedPathname.includes('consultation')) {
            return <ContactUsMainPageConsultation />
        } else if (parsedPathname.includes("sales-order-software")) {
            return <ContactUsMainPageSalesOrder />
        } else if (parsedPathname.includes("sales-order-automation")) {
            return <ContactUsMainPageSalesOrderNew />
        }
        else if (parsedPathname.includes("order-processing-software")) {
            return <ContactUsMainPageSalesOrder />
        }
        else if (parsedPathname.includes("order-management-platform")) {
            return <ContactUsMainPageSalesOrder />
        }
        else if (parsedPathname.includes("webinar")) {
            return <WebinarPage />
        } else if (parsedPathname.includes("events")) {
            return <EventPage />
        } else if (parsedPathname.includes("product/procurement-software")) {
            return <OrderTrackingProduct />
        } else if (parsedPathname.includes("middlePage")) {
            return <MiddlePageView />
        }
        else if (parsedPathname.includes("product/vendor-invoice-management")) {
            return <InvoiceManagementProduct />
        } else if (parsedPathname.includes("signUpPage")) {
            return <SignUpPage />
        }
    }

    const handleNavigation = () => {
        if (parsedPathname === "home") {
            navigate("/home");
        } else if (parsedPathname === "login") {
            navigate("/login");
        } else if (parsedPathname === "faq") {
            navigate("/faq");
        } else if (parsedPathname === "hiw") {
            navigate("/hiw");
        } else if (parsedPathname === "blogs") {
            navigate("/blogs")
        } else if (parsedPathname === "docs") {
            navigate("/docs")
        } else if (parsedPathname === "contactUs") {
            navigate("/contactUs")
        }
        else if (parsedPathname === 'consultation/:1') {
            navigate("/consultation/:1")
        }
        else if (parsedPathname === 'consultation/:2') {
            navigate("/consultation/:2")
        }
        else if (parsedPathname === 'consultation/:3') {
            navigate("/consultation/:3")
        }
        else if (parsedPathname === "sales-order-software") {
            return navigate("/sales-order-software")
        }
        else if (parsedPathname === "sales-order-software/success") {
            return navigate("/sales-order-software/success")
        } else if (parsedPathname === "sales-order-automation") {
            return navigate("/sales-order-automation")
        } else if (parsedPathname === "sales-order-automation/success") {
            return navigate("/sales-order-automation/success")
        }
        else if (parsedPathname === "order-processing-software") {
            return navigate("/order-processing-software")
        } else if (parsedPathname === "order-processing-software/success") {
            return navigate("/order-processing-software/success")
        }
        else if (parsedPathname === "order-management-platform") {
            return navigate("/order-management-platform")
        } else if (parsedPathname === "order-management-platform/success") {
            return navigate("/order-management-platform/success")
        }
        else if (parsedPathname === ("webinar")) {
            return navigate("/webinar")
        }
        else if (parsedPathname === ("webinar/success")) {
            return navigate("/webinar/success")
        } else if (parsedPathname === "/events") {
            return navigate("/events")
        } else if (parsedPathname === "/product/procurement-software") {
            return navigate("/product/procurement-software")
        } else if (parsedPathname === "/middlePage") {
            return navigate("/middlePage")
        } else if (parsedPathname === "/product/vendor-invoice-management") {
            return navigate("/product/vendor-invoice-management")
        } else if (parsedPathname === "/signUpPage") {
            return navigate("/signUpPage")
        }
    }

    const handleRedirectInnerPage = (value) => () => {
        navigate(`/${value}/:1`);
    }

    const handleRedirectInner = (value) => () => {
        navigate(`/${value}`)
    }

    const validateCaseCheck = (element) => {
        if (element === "privacy policy") {
            return 'privacy'
        } else if (element === "about us") {
            return 'company'
        } else {
            return element
        }
    }

    const openLinkedinPage = () => {
        window.open(`https://www.linkedin.com/company/vao-labs/`, '_blank')
    }

    const validatedCaseLogo = () => {
        if (selectedView === "blogs") {
            return vaoLogoBlog
        } else if (initialColor === "black") {
            return vaoLogoWhite
        } else {
            return vaoLogo
        }
    }

    useEffect(() => {
        setBackgroundColor(isSmallScreen ? '#07151a' : '#07151a');
        setTextColor('white');
        fetchImageDS1();
        fetchImageDS2();
        fetchImageWhite();
        WebFont.load({
            google: {
                families: ['Montserrat:300,400,500,700', 'Saira:300,400,500,700', 'sans-serif'],
            },
        });
        validationCallAPI();
    }, []);

    useEffect(() => {
        if (userTokenData) {
            setUsername(userTokenData?.username)
        }
    }, [userTokenData])

    useEffect(() => {
        handleNavigation();
        setSelectedView(parsedPathname);
        window.scrollTo(0, 0);

        if (parsedPathname === "home") {
            const handleScroll = () => {
                const scrollY = window.scrollY || document.documentElement.scrollTop;
                const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

                // Check if scroll position has surpassed 100% of the initial screen
                const hasSurpassedInitialScreen = scrollY >= viewportHeight;
                setSurpasedInitialScreen(hasSurpassedInitialScreen);
                // Adjust the color based on the surpass status
                const newColor = hasSurpassedInitialScreen ? 'rgba(255, 255, 255)' : '#07151a'
                const textColor = hasSurpassedInitialScreen ? '#173d44' : 'white';
                setBackgroundColor(newColor);
                setTextColor(textColor);
            };

            // Attach the scroll event listener
            window.addEventListener('scroll', handleScroll);

            // Remove the event listener on component unmount
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        } else {
            setTextColor('#173d44')
            setBackgroundColor('rgba(255, 255, 255)')
        }
    }, [parsedPathname]);

    useEffect(() => {
        const iconColor = (parsedPathname !== "home" || !parsedPathname)
            ? 'logo'
            : surpasedInitialScreen
                ? 'logo'
                : 'black'
        setInitialColor(iconColor);
    }, [surpasedInitialScreen, parsedPathname])

    useEffect(() => {
        const iconColor = (parsedPathname !== "home" || !parsedPathname)
            ? 'logo'
            : surpasedInitialScreen
                ? 'logo'
                : 'black'
        setInitialColor(iconColor);
    }, [])

    return (
        <div style={{
            backgroundColor: backgroundColor
        }}>
            <AppBar
                position="sticky"
                style={{
                    background: 'inherit',
                }}
            >
                <Toolbar>
                    <Grid
                        container
                        flexDirection="row"
                        justifyContent="space-around"
                        alignItems='center'
                        paddingBottom={isSmallScreen ? 1 : 0}
                        style={{ fontFamily: 'Montserrat, sans-serif' }}
                    >
                        <Grid
                            style={{
                                backgroundColor: "inherit"
                            }}
                            item
                            xs={12}
                            md={1}
                        >
                            <Button onClick={handleRedirectInnerPage('home')}>
                                <img
                                    src={validatedCaseLogo()}
                                    style={{
                                        maxWidth: isSmallScreen ? "20%" : "100%",
                                        height: "auto"
                                    }}
                                    alt="VAO Logo"
                                >
                                </img>
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Grid
                                container
                                justifyContent="space-around"
                                alignItems="center"
                            >
                                <ClickAwayListener onClickAway={handleTooltipProductClose}>
                                    <Grid item>
                                        <Tooltip
                                            enterTouchDelay={0}
                                            title={
                                                <>
                                                    <Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={handleRedirectInner('product/sales-order-automation')}
                                                        >
                                                            <Button
                                                                variant="text"
                                                                size="small"
                                                                sx={{
                                                                    color: 'white', fontFamily: 'Montserrat, sans-serif', fontSize: '15px',
                                                                    '&:hover': {
                                                                        color: "#00eae3",
                                                                    },
                                                                }}
                                                            >
                                                                <b>
                                                                    Order to Cash
                                                                </b>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Divider
                                                                style={{
                                                                    background: "white",
                                                                    borderBottomWidth: 3
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={handleRedirectInner('product/procurement-software')}
                                                        >
                                                            <Button
                                                                variant="text"
                                                                size="small"
                                                                sx={{
                                                                    color: 'white', fontFamily: 'Montserrat, sans-serif', fontSize: '15px',
                                                                    '&:hover': {
                                                                        color: "#00eae3",
                                                                    },
                                                                }}
                                                            >
                                                                <b>
                                                                    Procure to Pay
                                                                </b>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Divider
                                                                style={{
                                                                    background: "white",
                                                                    borderBottomWidth: 3
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={handleRedirectInner('product/vendor-invoice-management')}
                                                        >
                                                            <Button
                                                                variant="text"
                                                                size="small"
                                                                sx={{
                                                                    color: 'white', fontFamily: 'Montserrat, sans-serif', fontSize: '15px',
                                                                    '&:hover': {
                                                                        color: "#00eae3",
                                                                    },
                                                                }}
                                                            >
                                                                <b>
                                                                    Invoice Management
                                                                </b>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            }
                                            arrow
                                            placement="bottom"
                                        >
                                            <Button
                                                variant="text"
                                                color="info"
                                            >
                                                <b style={{ color: textColor, fontFamily: 'Montserrat, sans-serif' }}>
                                                    Solutions
                                                </b>
                                            </Button>
                                        </Tooltip>
                                        {['product'].includes(selectedView) && (
                                            <Divider
                                                style={{
                                                    background: "#68b9ff",
                                                    borderBottomWidth: 3
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </ClickAwayListener>
                                <Grid item >
                                    <Tooltip
                                        enterTouchDelay={0}
                                        title={
                                            <>
                                                <Grid>

                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={handleRedirectInner('company')}
                                                    >
                                                        <Button
                                                            variant="text"
                                                            size="small"
                                                            sx={{
                                                                color: 'white', fontFamily: 'Montserrat, sans-serif', fontSize: '15px',
                                                                '&:hover': {
                                                                    color: "#00eae3",
                                                                },
                                                            }}
                                                        >
                                                            <b>
                                                                Company
                                                            </b>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider
                                                            style={{
                                                                background: "white",
                                                                borderBottomWidth: 3
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={handleRedirectInner('careers')}
                                                    >
                                                        <Button
                                                            variant="text"
                                                            size="small"
                                                            sx={{
                                                                color: 'white', fontFamily: 'Montserrat, sans-serif', fontSize: '15px',
                                                                '&:hover': {
                                                                    color: "#00eae3",
                                                                },
                                                            }}
                                                        >
                                                            <b>
                                                                Careers
                                                            </b>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }
                                        arrow
                                        placement="bottom"
                                    >
                                        <Button
                                            variant="text"
                                            onClick={handleRedirectInner('company')}
                                            sx={{
                                                color: 'white', fontFamily: 'Montserrat, sans-serif', fontSize: '15px',
                                                '&:hover': {
                                                    color: "#00eae3",
                                                },
                                            }}
                                        >
                                            <b style={{ color: textColor, fontFamily: 'Montserrat, sans-serif', }}>
                                                About Us
                                            </b>
                                        </Button>
                                    </Tooltip>
                                    {['company', 'careers', 'privacy'].includes(selectedView) && (
                                        <Divider
                                            style={{
                                                background: "#68b9ff",
                                                borderBottomWidth: 3
                                            }}
                                        />
                                    )}
                                </Grid>
                                <Grid item >
                                    <Tooltip
                                        enterTouchDelay={0}
                                        title={
                                            <>
                                                <Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{ cursor: 'pointer', fontFamily: 'Montserrat, sans-serif', }}
                                                        onClick={handleRedirectInner('blogs')}
                                                    >
                                                        <Button
                                                            variant="text"
                                                            size="small"
                                                            sx={{
                                                                color: 'white', fontFamily: 'Montserrat, sans-serif', fontSize: '15px',
                                                                '&:hover': {
                                                                    color: "#00eae3",
                                                                },
                                                            }}
                                                        >
                                                            <b >
                                                                Blogs
                                                            </b>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider
                                                            style={{
                                                                background: "white",
                                                                borderBottomWidth: 3
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        style={{ cursor: 'pointer', fontFamily: 'Montserrat, sans-serif', }}
                                                        onClick={handleRedirectInner('events')}
                                                    >
                                                        <Button
                                                            variant="text"
                                                            size="small"
                                                            sx={{
                                                                color: 'white', fontFamily: 'Montserrat, sans-serif', fontSize: '15px',
                                                                '&:hover': {
                                                                    color: "#00eae3",
                                                                },
                                                            }}
                                                        >
                                                            <b>
                                                                Events
                                                            </b>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }
                                        arrow
                                        placement="bottom"
                                    >
                                        <Button
                                            variant="text"
                                            color="info"
                                        ///onClick={handleRedirectInner('company')}
                                        >
                                            <b style={{ color: textColor, fontFamily: 'Montserrat, sans-serif', }}>
                                                Resources
                                            </b>
                                        </Button>
                                    </Tooltip>
                                    {['blogs', 'events'].includes(selectedView) && (
                                        <Divider
                                            style={{
                                                background: "#68b9ff",
                                                borderBottomWidth: 3
                                            }}
                                        />
                                    )}
                                </Grid>
                                {
                                    !appContext.token && (
                                        <Grid item>
                                            <Button
                                                variant="text"
                                                color="info"
                                                onClick={() => navigate("/login")}
                                            >
                                                <b style={{ color: textColor, fontFamily: 'Montserrat, sans-serif', }}>
                                                    Login
                                                </b>
                                            </Button>
                                            {selectedView === "login" && (
                                                <Divider
                                                    style={{
                                                        background: "#68b9ff",
                                                        borderBottomWidth: 3
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                    )
                                }
                                <Grid item >
                                    <Button
                                        variant="text"
                                        color="info"
                                        onClick={appContext.token ? goToDashboard : () => navigate("/signUpPage")}
                                    >
                                        <b style={{ color: textColor, fontFamily: 'Montserrat, sans-serif', }}>
                                            {appContext.token ? 'Dashboard' : 'Sign Up'}
                                        </b>
                                    </Button>
                                    {selectedView === "signUpPage" && (
                                        <Divider
                                            style={{
                                                background: "#68b9ff",
                                                borderBottomWidth: 3
                                            }}
                                        />
                                    )}
                                </Grid>
                                {parsedPathname !== "home" && (
                                    <Grid item >
                                        <Button
                                            className='special'
                                            variant="text"
                                            color="info"
                                            onClick={() => navigate("/contactUs")}
                                        >
                                            <b style={{ color: textColor, fontFamily: 'Montserrat, sans-serif' }}>
                                                Book a Demo
                                            </b>
                                        </Button>
                                        {(selectedView === "contactUs" || selectedView === "contactUs/success") && (
                                            <Divider
                                                style={{
                                                    background: "#68b9ff",
                                                    borderBottomWidth: 3
                                                }}
                                            />
                                        )}
                                    </Grid>
                                )}
                                <Grid item md={1.5} xs={0} paddingTop={1} paddingBottom={1}>
                                    <Grid container style={{ backgroundColor: '#8c8c8c', borderRadius: "40px" }}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h4"
                                                textAlign="center"
                                                style={{ fontSize: "15px", fontFamily: 'Montserrat, sans-serif', }}
                                            >
                                                <b>Contact Us</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ fontSize: "10px", color: "#00ebe3", }}>
                                            <Typography
                                                variant="h4"
                                                textAlign="center"
                                                style={{ fontSize: "15px", fontFamily: 'Montserrat, sans-serif', }}
                                            >
                                                <b>
                                                    +1 415-903-0002
                                                </b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            appContext.token
                                ? (
                                    <Grid item xs={1}>
                                        <Grid
                                            container alignItems="center"
                                        >
                                            <Grid item >
                                                <IconButton
                                                    onClick={handleClickSettings}
                                                >
                                                    <StyledBadge
                                                        overlap="circular"
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        variant="dot"
                                                    >
                                                        <Avatar sx={{
                                                            position: "unset",
                                                            bgcolor: "#5db6d8",
                                                            height: '30px',
                                                            width: '30px',
                                                            fontSize: '13px',
                                                        }}
                                                            alt={username
                                                                ? username?.slice(0, 2).toUpperCase()
                                                                : username}
                                                        >
                                                            {username
                                                                ? username?.slice(0, 2).toUpperCase()
                                                                : username}
                                                        </Avatar>
                                                    </StyledBadge>
                                                </IconButton>
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={anchorElSettings}
                                                    open={openSettings}
                                                    onClose={handleCloseSettings}
                                                    MenuListProps={{
                                                        'aria-labelledby': 'basic-button',
                                                    }}
                                                >
                                                    <MenuItem sx={{ fontSize: '13px' }}><b>{username}</b></MenuItem>
                                                    <Divider />
                                                    <MenuItem sx={{ fontSize: '13px' }} onClick={goToDashboard}><b>Dashboard</b></MenuItem>
                                                    <MenuItem sx={{ fontSize: '13px' }} style={{ color: "red" }} onClick={() => handleLogout(() => navigate('/home'))}><b>Logout</b></MenuItem>
                                                </Menu>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                                : (
                                    <Grid item paddingLeft={20}></Grid>
                                )
                        }
                    </Grid>
                </Toolbar >
            </AppBar >
            {showInnerComponent()}
            {!(selectedView === "signUpPage") && (
                <AppBar
                    position="relative"
                    style={{
                        background: "#173d44",
                        zIndex: 2
                    }}
                    elevation={0}
                    sx={{ top: 'auto', bottom: 0 }}
                >
                    <Toolbar>
                        <Grid
                            container
                            alignItems="center"
                            padding={2}
                        >
                            <Grid item xs={12}>
                                <Grid container alignItems="center" justifyContent="center" padding={5}>
                                    <Grid item xs={12} md={4}>
                                        <Button onClick={handleRedirectInnerPage('home')}>
                                            <img
                                                src={vaoLogoBlack}
                                                style={{
                                                    maxWidth: isSmallScreen ? "25%" : "30%",
                                                    height: "auto"
                                                }}
                                                alt="VAO Logo"
                                            >
                                            </img>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Grid container spacing={3}>
                                            {TITLES.map((element) => {
                                                const validatedCase = validateCaseCheck(element)
                                                return (
                                                    <Grid item xs={4} style={{ textAlign: isSmallScreen ? 'center' : "left" }}>
                                                        <Button
                                                            variant="text"
                                                            style={{ color: "white" }}
                                                            onClick={handleRedirectInner(validatedCase === "product" ? 'product/sales-order-automation' : validatedCase)}
                                                        >
                                                            <b style={{ fontFamily: 'Montserrat, sans-serif' }}>
                                                                {element}
                                                            </b>
                                                        </Button>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Grid container justifyContent='center' alignItems="center">
                                            <Button onClick={openLinkedinPage} style={{ color: 'white' }}>
                                                <Grid item>
                                                    <LinkedInIcon sx={{ fontSize: '50px' }} />
                                                </Grid>
                                                <Grid item>
                                                    <b style={{ fontFamily: 'Montserrat, sans-serif' }}>FOLLOW US ON LINKEDIN</b>
                                                </Grid>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                        >
                                            <b
                                                style={{
                                                    color: "white",
                                                    textShadow: "4px 4px #000000",
                                                    fontFamily: 'Montserrat, sans-serif'
                                                }}
                                            >
                                                CONTACT US: Sales@vao.world
                                            </b>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <b
                                        style={{
                                            color: "white",
                                            textShadow: "4px 4px #000000",
                                            fontFamily: 'Montserrat, sans-serif'
                                        }}
                                    >
                                        © {new Date().getFullYear()} VAO Labs
                                    </b>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            )}
        </div >
    )
}

export default InnerComponent;