import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
    Button,
    Modal,
    Paper,
    Typography,
    IconButton,
    Select,
    MenuItem,
    Grid
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { updateUserPermits } from '../../../../../APICalls';
import { STORAGE_KEYS } from '../../../../../utils/utils';

export const PermissionModal = ({
    user, handleShowModal, handleCloseModal, setRefreshData
}) => {
    const permissions = user['app_metadata']['permissions']
    const [userPermissions, setUserPermissions] = useState(permissions);
    const [updatedSuccesfully, setUpdatedSuccesfully] = useState(false);
    const [updatedError, setUpdatedError] = useState(false);
    const [tokenData, setTokenData] = useState(null);

    useEffect(() => {
        const tokenData = localStorage.getItem(STORAGE_KEYS.TOKEN);
        setTokenData(tokenData);
    }, []);

    const updateUserPermitsCall = async () => {
        setUpdatedSuccesfully(false);
        setUpdatedError(false);
        const { user_id } = user;
        await updateUserPermits(tokenData, user_id, userPermissions)
            .then(response => {
                if (response === 'Successful') {
                    setRefreshData(true);
                    setUpdatedSuccesfully(true);
                    setUpdatedError(false);
                } else {
                    setUpdatedError(true);
                    setUpdatedSuccesfully(false);
                }
            });
    }

    const callUpdatePermitsAPI = () => {
        updateUserPermitsCall();
    }

    const handleSave = () => {
        handleCloseModal();
    };

    const validatePermissions = () => {
        if (!_.isNil(userPermissions)) {
            const objectKeysTitle = Object.keys(userPermissions);
            if (!_.isEmpty(objectKeysTitle)) {
                return objectKeysTitle.map((element) => (
                    <div key={element} style={{ textAlign: 'center', marginBottom: '10px', marginTop: '10px' }}>
                        <label>
                            <b>{element}</b>:
                            <Select
                                value={userPermissions[element]}
                                onChange={handleChangePermissions(element)}
                                style={{ marginLeft: '10px' }}
                            >
                                <MenuItem value={true}>Allow</MenuItem>
                                <MenuItem value={false}>Not Allow</MenuItem>
                            </Select>
                        </label>
                    </div>
                ))
            } else {
                return (
                    <div style={{ textAlign: 'center', marginBottom: '10px', marginTop: '10px' }}>
                        <Grid container padding={2} justifyContent="center">
                            <Grid item xs={12}>
                                <b>No permissions to show</b>
                            </Grid>

                        </Grid>
                    </div >
                )
            }
        } else {
            return (
                <div style={{ textAlign: 'center', marginBottom: '10px', marginTop: '10px' }}>
                    <Grid container padding={2} justifyContent="center">
                        <Grid item xs={12}>
                            <b>No permissions to show</b>
                        </Grid>

                    </Grid>
                </div >
            )
        }
    }

    const handleChangePermissions = (type) => (event) => {
        const newPermissionsObject = {
            ...userPermissions,
            [type]: event.target.value
        }
        setUserPermissions(newPermissionsObject);
    }

    return (
        <Modal
            open={handleShowModal}
            onClose={handleCloseModal}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Paper style={{ position: 'relative', padding: '20px' }}>
                <IconButton
                    style={{ position: 'absolute', top: '5px', right: '5px' }}
                    onClick={handleCloseModal}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '10px', marginTop: '10px' }}>
                    <b>Manage Permissions</b>
                </Typography>
                <Typography><b>User ID:</b> {user['user_id']}</Typography>
                <Typography><b>Username:</b> {user['email']}</Typography>
                <Grid container justifyContent='center'>
                    <Grid item xs={12}>
                        {validatePermissions()}
                    </Grid>
                </Grid>
                {updatedSuccesfully && (
                    <Grid>
                        <div style={{ textAlign: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <b style={{ color: '#2e7d32' }}>Saved successfully!</b>

                        </div>
                    </Grid>
                )}
                {updatedError && (
                    <Grid>
                        <div style={{ textAlign: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <b style={{ color: '#FF5555' }}>Error when saving.</b>
                        </div>
                    </Grid>
                )}
                <div style={{ textAlign: 'right' }}>
                    <Button variant="contained" color="success" onClick={callUpdatePermitsAPI}>
                        Save
                    </Button>
                </div>
            </Paper>
        </Modal>
    );
};
