import React from 'react'

import './loadingPage.css';

const LoadingPage = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div>
      <div className="header">
        <div className="inner-header flex">
          <h1>Loading, please wait!</h1>
        </div>
        <div>
          <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 25" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>

      </div>
      <div className="content flex">
        <a
          href="https://www.vao.world"
        >
          VAO Labs - {currentYear}
        </a>
      </div>
    </div>
  )
}

export default LoadingPage;