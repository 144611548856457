
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';

import React, { useEffect, useState } from 'react'
import {
    Grid, TextField, InputAdornment, Card, Modal, Button, CardActions, CardContent
} from '@mui/material'
import { useNavigate } from 'react-router-dom';

import CreateIcon from '@mui/icons-material/Create';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

import { updateWorkflow, createWorkflow, getCheckToken } from '../../../../../APICalls';
import { STORAGE_KEYS } from '../../../../../helpers/helpers';

const CustomizableConfiguration = ({
    openModal, selectedCardData, handleCloseModal, isCreate, refreshData
}) => {
    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [idConfig, setIdConfig] = useState(null);
    const [isNewConfig, setIsNewConfig] = useState(isCreate);
    const [succesfully, setSuccesfully] = useState(false);
    const [mailboxes, setMailboxes] = useState(null);
    const [workflowName, setWorkflowName] = useState(null);
    const [usernameOutlook, setUsernameOutlook] = useState(null);
    const [username, setUsername] = useState(null);

    // CORE
    const [emailExtraction, setEmailExtraction] = useState({
        "format_to_pdf": []
    })
    const [poClassification, setPOClassification] = useState({
        "allowed_body_words": [],
        "stopped_body_words": [],
        "allowed_subject_words": [],
        "stopped_subject_words": [],
        "classification_model_name": ""
    })
    const [poExtraction, setPoExtraction] = useState({
        "ensemble_pdf_model": [],
        "pdf_model_type": "",
        "qa_model_type": "",
        "mark_read_in_outlook": false,
        "post_processing": {
            "number_cleaning": ""
        }
    })
    const [fetchData, setFetchData] = useState({
        "matching_logic_rule": "",
    })

    //OUTLOOK

    const [outlook, setOutlook] = useState({
        "block_sender": [],
        "only_allowed_sender": "",
        "move_folder_on_approve": "",
        "tag_on_order_created": "",
        "tag_on_not_po": "",
        "client_id": "",
        "client_secret": ""
    })


    // UI
    const [uiSettings, setUiSettings] = useState({
        "custom_dashboard_fields": [],
        "custom_default_view_details": [],
        "premade_filters": [],
        "enabled_mass_approval": false,
        "dashboard_components": {},
        "default_fields_details_page": [],
        "notify_to_enabled": false,
        "stats_graphs_enabled": []
    });

    // App Service
    const [templateAppService, setTemplateAppService] = useState({
        "allow_fields": []
    })

    // Data Service
    const [sharepointUploadFile, setSharepointUploadFile] = useState({
        "location_on_approve": "",
        "naming_convention": "",
        "client_id": "",
        "client_secret": "",
        "dropping_folter_path": ""
    })
    const [makeApiCall, setMakeApiCall] = useState({
        "location": "",
        "naming_convention": "",
        "method": ""
    })
    const [msTeam, setMsTeam] = useState({
        "client_id": "",
        "client_secret": ""
    })

    // API Layer
    const [apiLayer, setApiLayer] = useState({
        "allowed_fields": [],
    })

    const [userTokenData, setUserTokenData] = useState(null);

    const validationCallAPI = async () => {
        const { status, data } = await getCheckToken(localStorage.getItem(STORAGE_KEYS.TOKEN));
        if (status === 200) {
            setUserTokenData(data);
        }
    }

    const handleLoginOutlook = () => {
        window.open('https://ecs-outlook.vao.world/');
    }

    // Functions handlers
    const handleChangeEmailExtraction = (field) => {
        const parsedValue = field.map((element) => {
            return element.value;
        })

        setEmailExtraction({
            ...emailExtraction,
            ['format_to_pdf']: parsedValue
        })
    }

    const handleChangeMailboxes = (field) => {
        const parsedValue = field.map((element) => {
            return element.value;
        })
        setMailboxes(parsedValue)
    }

    const handleChangePoClassification = (fieldName) => (field) => {
        const parsedValue = field.map((element) => {
            return element.value;
        })

        setPOClassification({
            ...poClassification,
            [fieldName]: parsedValue
        })
    }

    const handleChangePoClassificationTextField = (fieldName) => (field) => {
        setPOClassification({
            ...poClassification,
            [fieldName]: field.target.value
        })
    }

    const handleChangePoExtraction = (fieldName) => (field) => {
        const parsedValue = field.map((element) => {
            return element.value;
        })

        setPoExtraction({
            ...poExtraction,
            [fieldName]: parsedValue
        })
    }

    const handleChangePOExtractionSingle = (fieldName) => (field) => {
        setPoExtraction({
            ...poExtraction,
            [fieldName]: field.value
        })
    }

    const handleChangePoExtractionTextField = (fieldName) => (field) => {
        if (fieldName === "number_cleaning") {
            setPoExtraction({
                ...poExtraction,
                'post_processing': {
                    [fieldName]: field.target.value
                }
            })
        } else {
            setPoExtraction({
                ...poExtraction,
                [fieldName]: field.target.value
            })
        }
    }

    const handleChangeWorkflowName = (field) => {
        setWorkflowName(field.target.value)
    }

    const handleChangeOutlookUsername = (field) => {
        setUsernameOutlook(field.target.value)
    }


    const handleChangeFecthDataTextField = (fieldName) => (field) => {
        setFetchData({
            ...fetchData,
            [fieldName]: field.target.value
        })
    }

    const handleChangeOutlook = (fieldName) => (field) => {
        const parsedValue = field.map((element) => {
            return element.value;
        })

        setOutlook({
            ...outlook,
            [fieldName]: parsedValue
        })
    }

    const handleChangeOutlookTextField = (fieldName) => (field) => {
        setOutlook({
            ...outlook,
            [fieldName]: field.target.value
        })
    }

    const handleChangeTemplate = (fieldName) => (field) => {
        const parsedValue = field.map((element) => {
            return element.value;
        })

        setTemplateAppService({
            ...templateAppService,
            [fieldName]: parsedValue
        })
    }

    const handleChangeUI = (fieldName) => (field) => {
        const parsedValue = field.map((element) => {
            return element.value;
        })

        setUiSettings({
            ...uiSettings,
            [fieldName]: parsedValue
        })
    }

    const handleChangeUIDashboardSettings = (fieldName) => (value) => {
        const singleObject = value.reduce((acc, obj) => {
            acc[obj.value] = true;
            return acc;
        }, {});

        setUiSettings({
            ...uiSettings,
            [fieldName]: singleObject
        })

    }


    const handleChangeUISingle = (fieldName) => (field) => {
        setUiSettings({
            ...uiSettings,
            [fieldName]: field.value
        })
    }

    const handleChangeSharepointTextField = (fieldName) => (field) => {
        setSharepointUploadFile({
            ...sharepointUploadFile,
            [fieldName]: field.target.value
        })
    }

    const handleChangeMakeApiCallTextField = (fieldName) => (field) => {
        setMakeApiCall({
            ...makeApiCall,
            [fieldName]: field.target.value
        })
    }

    const handleChangeMSTextField = (fieldName) => (field) => {
        setMsTeam({
            ...msTeam,
            [fieldName]: field.target.value
        })
    }

    const handleChangeAPILayer = (fieldName) => (field) => {
        const parsedValue = field.map((element) => {
            return element.value;
        })

        setApiLayer({
            ...apiLayer,
            [fieldName]: parsedValue
        })
    }

    const usernameValidationVAODisabled = () => {
        if (username && username.includes("@vao.world")) {
            return false
        } else {
            return true
        }
    }

    const getSOPConfigurationsCall = async () => {
        if (!_.isEmpty(selectedCardData) && !_.isNil(selectedCardData)) {
            const { workflowId, workflowName, username, mailboxes, config } = selectedCardData
            const { core, outlook, appservice, api_layer, dataservice, ui } = config

            //USERNAME
            setUsernameOutlook(username);

            //MAILBOXES
            setMailboxes(mailboxes);

            //CORE
            const { email_extraction, fetch_data, po_classification, po_extraction } = core
            setEmailExtraction(email_extraction);
            setPOClassification(po_classification);
            setFetchData(fetch_data);
            setPoExtraction(po_extraction);

            //OUTLOOK
            setOutlook(outlook);

            //API LAYER
            setApiLayer(api_layer);

            //APPSERVICE
            const { template } = appservice;
            setTemplateAppService(template);

            //DATASERVICE
            const { ms_sharepoint_upload_worksoft_file, make_api_call, ms_team } = dataservice
            setSharepointUploadFile(ms_sharepoint_upload_worksoft_file);
            setMakeApiCall(make_api_call);
            setMsTeam(ms_team);

            //UI
            setUiSettings(ui)

            //ID:
            setIdConfig(workflowId);

            //NAME:
            setWorkflowName(workflowName);
            //Not first time configuring:
            setIsNewConfig(false);
        }
    }

    const createStructureForSendingAPI = () => {
        const newObjectStructure = {
            "configuration": {
                "core": {
                    "email_extraction": { ...emailExtraction },
                    "po_classification": { ...poClassification },
                    "po_extraction": { ...poExtraction },
                    "fetch_data": { ...fetchData },
                    "general_apis": {}
                },
                "outlook": { ...outlook },
                "appservice": {
                    "template": { ...templateAppService }
                },
                "ui": { ...uiSettings },
                "dataservice": {
                    "ms_sharepoint_upload_worksoft_file": { ...sharepointUploadFile },
                    "make_api_call": { ...makeApiCall },
                    "ms_team": { ...msTeam },
                },
                "api_layer": { ...apiLayer }
            }
        }
        return newObjectStructure;
    }

    const createStructureForUpdatingAPI = () => {
        const newObjectStructure = {
            "configuration": {
                "core": {
                    "email_extraction": { ...emailExtraction },
                    "po_classification": { ...poClassification },
                    "po_extraction": { ...poExtraction },
                    "fetch_data": { ...fetchData },
                    "general_apis": {}
                },
                "outlook": { ...outlook },
                "appservice": {
                    "template": { ...templateAppService }
                },
                "ui": { ...uiSettings },
                "dataservice": {
                    "ms_sharepoint_upload_worksoft_file": { ...sharepointUploadFile },
                    "make_api_call": { ...makeApiCall },
                    "ms_team": { ...msTeam },
                },
                "api_layer": { ...apiLayer }
            }
        }
        return newObjectStructure;
    }

    const callCreateConfigAPI = async () => {
        const configurationObject = createStructureForSendingAPI();
        const workflowObjectCreation = {
            'workflow_name': workflowName,
            "workflow_version": '1.0.0',
            'payload': {
                "username": usernameOutlook,
                "mailboxes": mailboxes,
                ...configurationObject
            }
        }
        const { status, data } = await createWorkflow(token, workflowObjectCreation)
        if (status === 200) {
            setSuccesfully(true);
            refreshData();
        } else {
            setSuccesfully(false);
        }
    }

    const callUpdateConfigAPI = async () => {
        const configurationObject = createStructureForUpdatingAPI();
        const workflowObjectCreation = {
            "id": idConfig,
            'workflow_name': workflowName,
            "workflow_version": '1.0.0',
            'payload': {
                "username": usernameOutlook,
                "mailboxes": mailboxes,
                ...configurationObject
            }
        }
        const { status, data } = await updateWorkflow(token, workflowObjectCreation)
        if (status === 200) {
            setSuccesfully(true);
            refreshData();
        } else {
            setSuccesfully(false);
        }
    }

    const handleNextStep = () => {
        setSuccesfully(false);
        validateCallData();
    }

    const validateCallData = () => {
        if (isNewConfig) {
            callCreateConfigAPI();
        } else {
            callUpdateConfigAPI();
        }
    }

    useEffect(() => {

        validationCallAPI();
    }, []);

    useEffect(() => {
        if (userTokenData) {
            setToken(localStorage.getItem(STORAGE_KEYS.TOKEN));
            setUsername(userTokenData?.username);
            setIsNewConfig(isCreate);
        }
    }, [userTokenData])

    useEffect(() => {
        if (token) {
            getSOPConfigurationsCall();
        }
    }, [token])

    return (
        <Modal
            open={openModal}
            style={{
                position: 'absolute',
                overflow: 'scroll',
                height: '100%',
                display: 'grid'
            }}
        >
            <Grid
                container
                justifyContent="center"
                flexDirection="row"
            >
                <Card
                    style={{
                        width: "80%",
                    }}
                >
                    <CardActions
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            size="medium"
                            variant="contained"
                            color='error'
                            onClick={handleCloseModal}
                        >
                            X
                        </Button>
                    </CardActions>
                    <CardContent>
                        <Grid container spacing={5} paddingRight={5} paddingTop={3}>
                            <Grid item xs={12}>
                                <h2>CUSTOMIZABLE WORKFLOW PAGE</h2>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                <h2>WORKFLOW NAME</h2>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <CreateIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeWorkflowName}
                                                            variant="outlined"
                                                            value={workflowName}
                                                            placeholder='Fill the Workflow Name value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                <h2>OUTLOOK USERNAME</h2>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <MarkEmailReadIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeOutlookUsername}
                                                            variant="outlined"
                                                            value={usernameOutlook}
                                                            placeholder='Fill the Outlook Username value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                <h2>LOGIN IN OUTLOOK</h2>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            size="large"
                                                            variant="contained"
                                                            color='info'
                                                            onClick={handleLoginOutlook}
                                                        >
                                                            Click here to login in Outlook
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                <h2>MULTIPLE MAILBOXES</h2>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isMulti
                                                            isClearable
                                                            placeholder="Write and add new Mailboxes to be attatched, then press Enter."
                                                            onChange={handleChangeMailboxes}
                                                            style={{ width: '100%' }}
                                                            value={!_.isEmpty(mailboxes)
                                                                ? mailboxes.map((element) => {
                                                                    return { label: element, value: element }
                                                                })
                                                                : []
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                <h2>CORE SETTINGS</h2>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Email Extraction</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Format to pdf:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isMulti
                                                            isClearable
                                                            options={[{ label: "Excel", value: "excel" }, { label: "HTML", value: "html" }, { label: "Doc", value: "doc" }, { label: "Docx", value: "docx" }]}
                                                            placeholder="Write and add new email extraction options, then press Enter."
                                                            onChange={handleChangeEmailExtraction}
                                                            style={{ width: '100%' }}
                                                            value={!_.isEmpty(emailExtraction["format_to_pdf"])
                                                                ? emailExtraction["format_to_pdf"].map((element) => {
                                                                    return { label: element, value: element }
                                                                })
                                                                : []
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>PO Classification</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Words to classify as PO in Email:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isMulti
                                                            isClearable
                                                            options={[]}
                                                            placeholder="Write additional Words to classify as PO in Email here and then press Enter."
                                                            onChange={handleChangePoClassification("allowed_body_words")}
                                                            style={{ width: '100%' }}
                                                            value={!_.isEmpty(poClassification["allowed_body_words"])
                                                                ? poClassification["allowed_body_words"].map((element) => {
                                                                    return { label: element, value: element }
                                                                })
                                                                : []
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Words to classify as NOT PO in Email:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isMulti
                                                            isClearable
                                                            options={[]}
                                                            placeholder="Write additional Words to classify as NOT PO in Email here and then press Enter."
                                                            onChange={handleChangePoClassification("stopped_body_words")}
                                                            style={{ width: '100%' }}
                                                            value={!_.isEmpty(poClassification["stopped_body_words"])
                                                                ? poClassification["stopped_body_words"].map((element) => {
                                                                    return { label: element, value: element }
                                                                })
                                                                : []
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Words to classify as PO in Subject:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isMulti
                                                            isClearable
                                                            options={[]}
                                                            placeholder="Write additional Words to classify as PO in Subject here and then press Enter."
                                                            onChange={handleChangePoClassification("allowed_subject_words")}
                                                            style={{ width: '100%' }}
                                                            value={!_.isEmpty(poClassification["allowed_subject_words"])
                                                                ? poClassification["allowed_subject_words"].map((element) => {
                                                                    return { label: element, value: element }
                                                                })
                                                                : []
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Stopped subject words:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isMulti
                                                            isClearable
                                                            options={[]}
                                                            placeholder="Write additional Words to classify as NOT PO in Subject here and then press Enter."
                                                            onChange={handleChangePoClassification("stopped_subject_words")}
                                                            style={{ width: '100%' }}
                                                            value={!_.isEmpty(poClassification["stopped_subject_words"])
                                                                ? poClassification["stopped_subject_words"].map((element) => {
                                                                    return { label: element, value: element }
                                                                })
                                                                : []
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Classification Model Name:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangePoClassificationTextField("classification_model_name")}
                                                            variant="outlined"
                                                            value={poClassification["classification_model_name"]}
                                                            placeholder='Fill the Classification Model Name value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>PO Extraction</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Ensemble PDF Model:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isMulti
                                                            isClearable
                                                            isDisabled={usernameValidationVAODisabled()}
                                                            options={[]}
                                                            placeholder="Write additional Ensamble PDF Model to add here and then press Enter."
                                                            onChange={handleChangePoExtraction("ensamble_pdf_model")}
                                                            style={{ width: '100%' }}
                                                            value={!_.isEmpty(poExtraction["ensamble_pdf_model"])
                                                                ? poExtraction["ensamble_pdf_model"].map((element) => {
                                                                    return { label: element, value: element }
                                                                })
                                                                : []
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>PDF model type:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            disabled={usernameValidationVAODisabled()}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangePoExtractionTextField("pdf_model_type")}
                                                            //label=""
                                                            variant="outlined"
                                                            value={poExtraction["pdf_model_type"]}
                                                            placeholder='Please fill the PDF Model Type value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>QA Model Type:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            disabled={usernameValidationVAODisabled()}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangePoExtractionTextField("qa_model_type")}
                                                            variant="outlined"
                                                            value={poExtraction["qa_model_type"]}
                                                            placeholder='Please fill the QA Model type value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Mark read in Outlook?</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isDisabled={usernameValidationVAODisabled()}
                                                            options={[{ label: "enabled", value: true }, { label: "disabled", value: false }]}
                                                            placeholder="Select an option for Marking Read In Outlook."
                                                            onChange={handleChangePOExtractionSingle("marking_read_in_outlook")}
                                                            style={{ width: '100%' }}
                                                            value={{ label: poExtraction["marking_read_in_outlook"] === true ? 'enabled' : 'disabled', value: poExtraction["marking_read_in_outlook"] }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Number Cleaning:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            disabled={usernameValidationVAODisabled()}
                                                            onInput={handleChangePoExtractionTextField("number_cleaning")}
                                                            //label=""
                                                            variant="outlined"
                                                            value={poExtraction?.post_processing?.number_cleaning || ""}
                                                            placeholder='Please fill the Number Cleaning value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Fetch Data</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Matching Logic Options:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeFecthDataTextField("matching_logic_rule")}
                                                            //label=""
                                                            value={fetchData["matching_logic_rule"]}
                                                            placeholder='Please fill the Matching Logic Options value'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                <h2>OUTLOOK SETTINGS</h2>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Client ID:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeOutlookTextField("client_id")}
                                                            //label=""
                                                            variant="outlined"
                                                            value={outlook["client_id"]}
                                                            placeholder='Please fill the Client Id value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Client Secret:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeOutlookTextField("client_secret")}
                                                            //label=""
                                                            variant="outlined"
                                                            value={outlook["client_secret"]}
                                                            placeholder='Please fill the Client Secret value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Block Sender:</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isMulti
                                                            isClearable
                                                            options={[]}
                                                            placeholder="Write additional Block Senders to add here and then press Enter."
                                                            onChange={handleChangeOutlook("block_sender")}
                                                            style={{ width: '100%' }}
                                                            value={!_.isEmpty(outlook["block_sender"])
                                                                ? outlook["block_sender"].map((element) => {
                                                                    return { label: element, value: element }
                                                                })
                                                                : []
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Only Allowed Sender:</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isMulti
                                                            isClearable
                                                            options={[]}
                                                            placeholder="Write additional Only Allowed Senders to add here and then press Enter."
                                                            onChange={handleChangeOutlook("only_allowed_sender")}
                                                            style={{ width: '100%' }}
                                                            value={!_.isEmpty(outlook["only_allowed_sender"])
                                                                ? outlook["block_sender"].map((element) => {
                                                                    return { label: element, value: element }
                                                                })
                                                                : []
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Move to folder once approved</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeOutlookTextField("move_folder_on_approve")}
                                                            //label=""
                                                            variant="outlined"
                                                            value={outlook["move_folder_on_approve"]}
                                                            placeholder='Please fill the Move to folder once approved value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Tag as PO</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeOutlookTextField("tag_on_order_created")}
                                                            //label=""
                                                            variant="outlined"
                                                            value={outlook["tag_on_order_created"]}
                                                            placeholder='Please fill the Tag as PO value'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Tag as Not PO</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeOutlookTextField("tag_on_not_po")}
                                                            //label=""
                                                            variant="outlined"
                                                            value={outlook["tag_on_not_po"]}
                                                            placeholder='Please fill the Tag as Not PO value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                <h2>APPSERVICE SETTINGS</h2>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Template</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Fields for Order Creation:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isMulti
                                                            isClearable
                                                            options={[]}
                                                            placeholder="Write additional Fields for Order Creation then press Enter."
                                                            onChange={handleChangeTemplate("allow_fields")}
                                                            style={{ width: '100%' }}
                                                            value={!_.isEmpty(templateAppService["allow_fields"])
                                                                ? templateAppService["allow_fields"].map((element) => {
                                                                    return { label: element, value: element }
                                                                })
                                                                : []
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                <h2>UI SETTINGS</h2>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Custom dashboard fields</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isMulti
                                                            isClearable
                                                            options={[]}
                                                            placeholder="Write additional Custom Dashboard Fields to add here and then press Enter."
                                                            onChange={handleChangeUI("custom_dashboard_fields")}
                                                            style={{ width: '100%' }}
                                                            value={!_.isEmpty(uiSettings["custom_dashboard_fields"])
                                                                ? uiSettings["custom_dashboard_fields"].map((element) => {
                                                                    return { label: element, value: element }
                                                                })
                                                                : []
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Custom default view details</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isClearable
                                                            options={[{ label: 'spreadsheet', value: "spreadsheet" }, { label: 'table', value: 'table' }]}
                                                            placeholder="Select a view for the default details page view."
                                                            onChange={handleChangeUI("custom_default_view_details")}
                                                            style={{ width: '100%' }}
                                                            value={!_.isEmpty(uiSettings["custom_default_view_details"])
                                                                ? uiSettings["custom_default_view_details"].map((element) => {
                                                                    return { label: element, value: element }
                                                                })
                                                                : []
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Pre-made filters</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isMulti
                                                            isClearable
                                                            options={[]}
                                                            placeholder="Write additional Premade filters ID to add here and then press Enter."
                                                            onChange={handleChangeUI("premade_filters")}
                                                            style={{ width: '100%' }}
                                                            value={!_.isEmpty(uiSettings["premade_filters"])
                                                                ? uiSettings["premade_filters"].map((element) => {
                                                                    return { label: element, value: element }
                                                                })
                                                                : []
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Mass approval enabled?</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            options={[{ label: "enabled", value: true }, { label: "disabled", value: false }]}
                                                            placeholder="Select an option for displaying mass approval."
                                                            onChange={handleChangeUISingle("enabled_mass_approval")}
                                                            style={{ width: '100%' }}
                                                            value={{ label: uiSettings["enabled_mass_approval"] === true ? 'enabled' : 'disabled', value: uiSettings["enabled_mass_approval"] }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Dashboard components</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isMulti
                                                            isClearable
                                                            options={[]}
                                                            placeholder="Write additional Dashboard Components to add here and then press Enter."
                                                            onChange={handleChangeUIDashboardSettings("dashboard_components")}
                                                            style={{ width: '100%' }}
                                                            value={!_.isEmpty(uiSettings["dashboard_components"])
                                                                ? Object.keys(uiSettings["dashboard_components"]).map((element) => {
                                                                    return { label: element, value: element }
                                                                })
                                                                : []
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Default Fields Details Page</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isMulti
                                                            isClearable
                                                            options={[]}
                                                            placeholder="Write additional Default Fields for Details Page here and then press Enter."
                                                            onChange={handleChangeUI("default_fields_details_page")}
                                                            style={{ width: '100%' }}
                                                            value={!_.isEmpty(uiSettings["default_fields_details_page"])
                                                                ? uiSettings["default_fields_details_page"].map((element) => {
                                                                    return { label: element, value: element }
                                                                })
                                                                : []
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Notify To Enabled?</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isClearable
                                                            options={[{ label: "enabled", value: true }, { label: "disabled", value: false }]}
                                                            placeholder="Please select if you want to enable or disable notify to."
                                                            onChange={handleChangeUISingle("notify_to_enabled")}
                                                            style={{ width: '100%' }}
                                                            value={{ label: uiSettings["notify_to_enabled"] === true ? 'enabled' : 'disabled', value: uiSettings["notify_to_enabled"] }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Enabled stats graphs</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isMulti
                                                            isClearable
                                                            options={[]}
                                                            placeholder="Write additional Stats Graphs to Enable here and then press Enter."
                                                            onChange={handleChangeUI("enabled_stats_graphs")}
                                                            style={{ width: '100%' }}
                                                            value={!_.isEmpty(uiSettings["enabled_stats_graphs"])
                                                                ? uiSettings["enabled_stats_graphs"].map((element) => {
                                                                    return { label: element, value: element }
                                                                })
                                                                : []
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                <h2>DATA SERVICE SETTINGS</h2>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Microsoft Sharepoint Upload Worksoft File</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Location of approved files:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeSharepointTextField("location_on_approve")}
                                                            //label=""
                                                            variant="outlined"
                                                            value={sharepointUploadFile["location_on_approve"]}
                                                            placeholder='Please fill the Location of approved files value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Naming Convention:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeSharepointTextField("naming_convention")}
                                                            //label=""
                                                            variant="outlined"
                                                            value={sharepointUploadFile["naming_convention"]}
                                                            placeholder='Please fill the Naming Convention value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Client ID:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeSharepointTextField("client_id")}
                                                            //label=""
                                                            variant="outlined"
                                                            value={sharepointUploadFile["cliend_id"]}
                                                            placeholder='Please fill the Client ID value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Client Secret:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeSharepointTextField("client_secret")}
                                                            //label=""
                                                            variant="outlined"
                                                            value={sharepointUploadFile["client_secret"]}
                                                            placeholder='Please fill the Client Secret value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Dropping Folder Path:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeSharepointTextField("dropping_folder_path")}
                                                            //label=""
                                                            variant="outlined"
                                                            value={makeApiCall["dropping_folder_path"]}
                                                            placeholder='Please fill the Dropping Folder Path value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Make API Call</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Location:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeMakeApiCallTextField("location")}
                                                            //label=""
                                                            variant="outlined"
                                                            value={makeApiCall["location"]}
                                                            placeholder='Please fill the Location value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Naming Convention:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeMakeApiCallTextField("naming_convention")}
                                                            //label=""
                                                            variant="outlined"
                                                            value={makeApiCall["naming_convention"]}
                                                            placeholder='Please fill the Naming Convention value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Method:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeMakeApiCallTextField("method")}
                                                            //label=""
                                                            variant="outlined"
                                                            value={makeApiCall["method"]}
                                                            placeholder='Please fill the Method value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>MS Team</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Client ID:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeMSTextField("client_id")}
                                                            //label=""
                                                            variant="outlined"
                                                            value={msTeam["client_id"]}
                                                            placeholder='Please fill the Client ID value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                        <h4>Client Secret:</h4>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <AlternateEmailIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            onInput={handleChangeMSTextField("client_secret")}
                                                            //label=""
                                                            variant="outlined"
                                                            value={msTeam["client_secret"]}
                                                            placeholder='Please fill the Client Secret value.'
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                <h2>API LAYER SETTINGS</h2>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <h3>Required Fields</h3>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <CreatableSelect
                                                            fullWidth
                                                            isMulti
                                                            isClearable
                                                            options={[]}
                                                            placeholder="Write additional Required Fields to add here and then press Enter."
                                                            onChange={handleChangeAPILayer("allowed_fields")}
                                                            style={{ width: '100%' }}
                                                            value={!_.isEmpty(apiLayer["allowed_fields"])
                                                                ? apiLayer["allowed_fields"].map((element) => {
                                                                    return { label: element, value: element }
                                                                })
                                                                : []
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid >
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2} paddingTop={5} paddingBottom={5}>
                                    {succesfully && (
                                        <Grid item xs={12}>
                                            <h5 style={{
                                                color: "green"
                                            }}>
                                                Updated succesfully!
                                            </h5>
                                        </Grid>
                                    )}
                                    <Grid item>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid >
                    </CardContent>
                    <CardActions
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            size="medium"
                            variant="contained"
                            color='error'
                            onClick={handleCloseModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="medium"
                            variant="contained"
                            color='success'
                            onClick={handleNextStep}
                        >
                            {isCreate
                                ? 'CREATE'
                                : 'SAVE'}
                        </Button>
                    </CardActions>
                </Card>
            </Grid >
        </Modal>
    )
}

export default CustomizableConfiguration;