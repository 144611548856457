
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import React, { useEffect, useState } from 'react'
import {
    Grid, Drawer, TextField, InputAdornment, IconButton, Button,
    FormGroup, FormControlLabel, Checkbox
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import { FileUploader } from "react-drag-drop-files";

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { createOrganization, getOrganizations, updateOrganization, getLogoAPI } from '../../../../../APICalls';
import { STORAGE_KEYS } from '../../../../../helpers/helpers';

const FILE_TYPES = ["JPG", "JPEG", "PNG", "SVG"];

const OrganizationSettings = () => {
    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [image, setImage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [idConfig, setIdConfig] = useState(null);
    const [isNewConfig, setIsNewConfig] = useState(false);
    const [succesfully, setSuccesfully] = useState(false);
    const [organizationData, setOrganizationData] = useState(null);
    const [orgLogo, setOrgLogo] = useState(null);

    const getSOPConfigurationsCall = async () => {
        const { status, data } = await getOrganizations(token);
        if (status, data) {
            if (status === 200) {
                if (_.isEmpty(data)) {
                    setIsNewConfig(true);
                    setOrganizationData(null);
                } else {
                    setIsNewConfig(false);
                    const { name, metadata, id } = data[0];
                    setOrganizationData(name);
                    setIdConfig(id);
                }
            }
        }
    }

    const getLogoOrg = async () => {
        const imagRes = await getLogoAPI(localStorage.getItem(STORAGE_KEYS.TOKEN))
        if (!imagRes) {
            setOrgLogo(null);
            return;
        }
        const imageObjectURL = URL.createObjectURL(imagRes);
        setOrgLogo(imageObjectURL);
    }

    const callCreateOrganizationAPI = async () => {

        const { status } = await createOrganization(token, organizationData)
        if (status === 200) {
            setSuccesfully(true);
        } else {
            setSuccesfully(false);
            setErrorMessage('An error happened when creating the organization.')
        }
    }

    const callUpdateOrganizationAPI = async () => {

        const { status } = await updateOrganization(token, organizationData, image, idConfig)
        if (status === 200) {
            setSuccesfully(true);
            getLogoOrg();
        } else {
            setSuccesfully(false);
            setErrorMessage('An error happened when updating the organization.')
        }
    }

    const handleNextStep = () => {
        setSuccesfully(false);
        setErrorMessage(null);
        validateCallData();
    }

    const validateCallData = () => {
        if (isNewConfig) {
            callCreateOrganizationAPI();
        } else {
            callUpdateOrganizationAPI();
        }
    }

    const handleFillData = (event) => {
        setOrganizationData(event.target.value);
    }

    const handleChangeFile = (file) => {
        setImage(file);
    };

    useEffect(() => {
        setToken(localStorage.getItem(STORAGE_KEYS.TOKEN));
    }, []);

    useEffect(() => {
        if (token) {
            getSOPConfigurationsCall();
            getLogoOrg();
        }
    }, [token])

    return (
        <Grid container spacing={3} paddingRight={5}>
            <Grid item xs={12}>
                <h2>Organization Settings Page</h2>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <h3>Organization Name:</h3>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <p>You create / update your organization info here.</p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AlternateEmailIcon />
                            </InputAdornment>
                        ),
                    }}
                    onInput={handleFillData}
                    //label=""
                    variant="outlined"
                    value={organizationData}
                    //error={registerData['email'] === ''}
                    //helperText={fieldErrors && registerData['email'] === '' ? "Missing field." : ""}
                    placeholder='Set the name of the organization here.'
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <h3>Company Logo Upload:</h3>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <p>You can upload the logo of your company to visualize it in the toolbar.</p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <FileUploader
                    handleChange={handleChangeFile}
                    name="file"
                    types={FILE_TYPES}
                    hoverTitle="Select or drop a image for the logo here. (JPG, JPEG, PNG, SVG)."
                //onTypeError={() => setIsTypeError(true)}
                />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
                <p>For better visualization, use a 200px x 200px resolution.</p>
            </Grid>
            {orgLogo && (
                <Grid item xs={12} style={{ textAlign: 'left' }}>
                    <img
                        src={orgLogo}
                        style={{
                            maxWidth: "250px",
                            height: "auto"
                        }}
                        alt="organization Logo"
                    >
                    </img>
                </Grid>
            )}
            <Grid item xs={12}>
                <Grid container spacing={2} paddingTop={5} paddingBottom={5}>
                    {succesfully && (
                        <Grid item xs={12}>
                            <h5 style={{
                                color: "green"
                            }}>
                                Updated succesfully!
                            </h5>
                        </Grid>
                    )}
                    <Grid item>
                        <Button
                            size="medium"
                            variant="contained"
                            color='success'
                            onClick={handleNextStep}
                        >
                            SAVE
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default OrganizationSettings;