
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

import React, { useEffect, useState } from 'react'
import {
    Grid, Drawer, TextField, InputAdornment, IconButton, Button,
    FormGroup, FormControlLabel, Checkbox
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ModalOutlook from './ModalOutlook';

import { createSOPConfigurationAPI, createNewWorkflow } from '../../../../APICalls';
import { STORAGE_KEYS } from '../../../../helpers/helpers';

const OPTIONS_ATTATCHMENTS = [
    "EML", "PDF", "HTML", "DOC", "DOCX", "XLS", "XLSX"
]

const FormMailboxComponent = ({ currentStep, setCurrentStep }) => {
    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const [extraMailboxes, setExtraMailboxes] = useState([]);
    const [attatchmentOptions, setAttatchmentOptions] = useState([]);
    const [registerData, setRegisterData] = useState({
        "email": "",
        "block_sender": [],
        "accept_sender": [],
        "body_keywords_block": [],
        "body_keywords_accept": [],
        "subject_keywords_block": [],
        "subject_keywords_accept": [],
        "attachment_accept": ["EML", "PDF", "HTML", "DOC", "DOCX", "XLS", "XLSX"],
        "attachment_reject": [],
        "ignore_fields": [],
        "quantity_thousand_separator": ["."]
    });
    const [fieldErrors, setFieldErrors] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null);
    const [openModalOutlook, setOpenModalOutlook] = useState(false);

    const handleOptionsAttatchments = () => {
        const parsedArray = OPTIONS_ATTATCHMENTS.map((element) => {
            return { label: element, value: element }
        })
        setAttatchmentOptions(parsedArray);
    }

    const handleChangeMailboxes = (value) => {
        setExtraMailboxes(value);
    }

    const handleCloseOutlookModal = () => {
        setOpenModalOutlook(false);
    }

    const navigateNextPage = () => {
        if (currentStep === 3) {
            navigate("/home");
        }
        setCurrentStep(3);
        navigate("/register/:3")
    }

    const callCreateConfigAPI = async () => {
        if (!registerData.email) {
            setFieldErrors(true);
            return
        }
        const extractionEmail = {
            "workflow_name": "workflow_name",
            "workflow_version": "1.0.0",
            payload: {
                "username": registerData["email"],
                "mailboxes": [registerData["email"]]
            }
        }

        const extractionConfiguration = {
            "block_sender": registerData["block_sender"],
            "accept_sender": registerData["accept_sender"],
            "body_keywords_block": registerData["body_keywords_block"],
            "body_keywords_accept": registerData["body_keywords_accept"],
            "subject_keywords_block": registerData["subject_keywords_block"],
            "subject_keywords_accept": registerData["subject_keywords_accept"],
            "attachment_accept": registerData["attachment_accept"],
            "attachment_reject": registerData["attachment_reject"]
        }

        const classificationConfiguration = {
            "ignore_fields": registerData["ignore_fields"],
            "quantity_thousand_separator": registerData["quantity_thousand_separator"][0]
        }

        const response1 = await createSOPConfigurationAPI(token, extractionConfiguration, classificationConfiguration, {});
        const response2 = await createNewWorkflow(token, extractionEmail);
        if (response1 === "Successful" && response2 === "Successful") {
            setOpenModalOutlook(true);
        } else {
            if (response1.message) {
                setErrorMessage(response1.message)
                return;
            } else if (response2.message) {
                setErrorMessage(response2.message)
                return;
            }
        }
    }

    const handleGoBack = () => {
        navigate(`/register/:${currentStep - 1}`)
    }

    const handleNextStep = () => {
        setErrorMessage(null);
        callCreateConfigAPI();
    }

    const handleSkipNextStep = () => {
        navigate("/register/:3");
    }

    const handleFillData = (field) => (event) => {
        setRegisterData({
            ...registerData,
            [field]: event.target.value.trim()
        })
    }

    const handleChangeSelectValue = (fieldName) => (value) => {
        const parsedValue = value.map((element) => {
            return element.value;
        })

        setRegisterData({
            ...registerData,
            [fieldName]: parsedValue
        })
    }

    const handleChangeConfigSeparator = (fieldName) => (value) => {
        setRegisterData({
            ...registerData,
            [fieldName]: [value.value]
        })
    }

    useEffect(() => {
        handleOptionsAttatchments();
        setToken(localStorage.getItem(STORAGE_KEYS.TOKEN))
    }, []);

    return (
        <Grid container spacing={1} paddingTop={5} paddingBottom={10} paddingRight={6}>
            <Grid item xs={12} style={{ textAlign: 'left' }}>
                <h4>Email to be hooked / monitored</h4>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AlternateEmailIcon />
                            </InputAdornment>
                        ),
                    }}
                    onInput={handleFillData("email")}
                    label="Email to be hooked / monitored"
                    variant="outlined"
                    value={registerData["email"]}
                    error={fieldErrors && registerData['email'] === ''}
                    helperText={fieldErrors && registerData['email'] === '' ? "Missing field." : ""}
                    placeholder='Mail to be monitored for picking emails'
                >
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <h4>Attatch additional mailboxes?
                            &nbsp;
                            &nbsp;
                            <a
                                href="https://www.vao.world"
                            >
                                More information
                            </a>
                        </h4>
                    </Grid>
                    <Grid item xs={12}>
                        <CreatableSelect
                            fullWidth
                            isMulti
                            isClearable
                            options={[]}
                            placeholder="Write additional mailboxes to add here and then press Enter."
                            onChange={handleChangeMailboxes}
                            style={{ width: '100%' }}
                            value={extraMailboxes}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <h2>CLASSIFICATION CONFIGURATION:</h2>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <h4>Block sender</h4>
                    </Grid>
                    <Grid item xs={12}>
                        <CreatableSelect
                            fullWidth
                            isMulti
                            isClearable
                            options={[]}
                            placeholder="Blocked Senders:"
                            onChange={handleChangeSelectValue("block_sender")}
                            style={{ width: '100%' }}
                            value={!_.isEmpty(registerData["block_sender"])
                                ? registerData["block_sender"].map((element) => {
                                    return { label: element, value: element }
                                })
                                : []
                            }
                        />
                    </Grid>
                </Grid>
            </Grid >
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <h4>Accept sender</h4>
                    </Grid>
                    <Grid item xs={12}>
                        <CreatableSelect
                            fullWidth
                            isMulti
                            isClearable
                            options={[]}
                            placeholder="Accepted Senders:"
                            onChange={handleChangeSelectValue("accept_sender")}
                            style={{ width: '100%' }}
                            value={!_.isEmpty(registerData["accept_sender"])
                                ? registerData["block_sender"].map((element) => {
                                    return { label: element, value: element }
                                })
                                : []
                            }
                        />
                    </Grid>
                </Grid>
            </Grid >
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <h4>Body Keywords Block</h4>
                    </Grid>
                    <Grid item xs={12}>
                        <CreatableSelect
                            fullWidth
                            isMulti
                            isClearable
                            options={[]}
                            placeholder="Body keywords to block:"
                            onChange={handleChangeSelectValue("body_keywords_block")}
                            style={{ width: '100%' }}
                            value={!_.isEmpty(registerData["body_keywords_block"])
                                ? registerData["body_keywords_block"].map((element) => {
                                    return { label: element, value: element }
                                })
                                : []
                            }
                        />
                    </Grid>
                </Grid>
            </Grid >
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <h4>Body Keywords Accept</h4>
                    </Grid>
                    <Grid item xs={12}>
                        <CreatableSelect
                            fullWidth
                            isMulti
                            isClearable
                            options={[]}
                            placeholder="Accepted body keywords:"
                            onChange={handleChangeSelectValue("body_keywords_accept")}
                            style={{ width: '100%' }}
                            value={!_.isEmpty(registerData["body_keywords_accept"])
                                ? registerData["body_keywords_accept"].map((element) => {
                                    return { label: element, value: element }
                                })
                                : []
                            }
                        />
                    </Grid>
                </Grid>
            </Grid >
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <h4>Subject Keywords Block</h4>
                    </Grid>
                    <Grid item xs={12}>
                        <CreatableSelect
                            fullWidth
                            isMulti
                            isClearable
                            options={[]}
                            placeholder="Subject Keywords to block:"
                            onChange={handleChangeSelectValue("subject_keywords_block")}
                            style={{ width: '100%' }}
                            value={!_.isEmpty(registerData["subject_keywords_block"])
                                ? registerData["subject_keywords_block"].map((element) => {
                                    return { label: element, value: element }
                                })
                                : []
                            }
                        />
                    </Grid>
                </Grid>
            </Grid >
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <h4>Subject Keywords Accept</h4>
                    </Grid>
                    <Grid item xs={12}>
                        <CreatableSelect
                            fullWidth
                            isMulti
                            isClearable
                            options={[]}
                            placeholder="Acepted keywords for subject:"
                            onChange={handleChangeSelectValue("subject_keywords_accept")}
                            style={{ width: '100%' }}
                            value={!_.isEmpty(registerData["subject_keywords_accept"])
                                ? registerData["subject_keywords_accept"].map((element) => {
                                    return { label: element, value: element }
                                })
                                : []
                            }
                        />
                    </Grid>
                </Grid>
            </Grid >
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <h4>Attatchment Accept</h4>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            isMulti
                            menuPlacement="bottom"
                            options={attatchmentOptions}
                            placeholder="Attatchment Type:"
                            style={{ width: '100%' }}
                            onChange={handleChangeSelectValue("attachment_accept")}
                            value={!_.isEmpty(registerData["attachment_accept"])
                                ? registerData["attachment_accept"].map((element) => {
                                    return { label: element, value: element }
                                })
                                : []
                            }
                        />
                        {(fieldErrors && _.isEmpty(registerData["attachment_accept"])) && (
                            <p style={{
                                color: "#db5757"
                            }}>
                                Missing field.
                            </p>
                        )}
                    </Grid>
                </Grid>
            </Grid >
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <h4>Attatchment Reject</h4>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            isMulti
                            menuPlacement="bottom"
                            options={attatchmentOptions}
                            placeholder="Attatchment Type:"
                            style={{ width: '100%' }}
                            onChange={handleChangeSelectValue("attachment_reject")}
                            value={!_.isEmpty(registerData["attachment_reject"])
                                ? registerData["attachment_reject"].map((element) => {
                                    return { label: element, value: element }
                                })
                                : []
                            }
                        />
                    </Grid>
                </Grid>
            </Grid >
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <h2>EXTRACTION CONFIGURATION:</h2>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <h4>Ignore fields</h4>
                    </Grid>
                    <Grid item xs={12}>
                        <CreatableSelect
                            fullWidth
                            isMulti
                            isClearable
                            options={[]}
                            placeholder="Fields to ignore:"
                            onChange={handleChangeSelectValue("ignore_fields")}
                            style={{ width: '100%' }}
                            value={!_.isEmpty(registerData["ignore_fields"])
                                ? registerData["ignore_fields"].map((element) => {
                                    return { label: element, value: element }
                                })
                                : []
                            }
                        />
                    </Grid>
                </Grid>
            </Grid >
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>
                        <h4>Quantity Thousand Separator</h4>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            menuPlacement="bottom"
                            options={[{ label: ",", value: "," }, { label: ".", value: "." }]}
                            placeholder="Separator Type:"
                            style={{ width: '100%' }}
                            onChange={handleChangeConfigSeparator("quantity_thousand_separator")}
                            value={!_.isEmpty(registerData["quantity_thousand_separator"])
                                ? registerData["quantity_thousand_separator"].map((element) => {
                                    return { label: element, value: element }
                                })
                                : []
                            }
                        />
                    </Grid>
                </Grid>
            </Grid >
            <Grid item xs={12} alignSelf='flex-end' paddingTop={15}>
                <Grid container spacing={4}>
                    {errorMessage && (
                        <Grid item xs={12}>
                            <h5 style={{
                                color: "#db5757"
                            }}>
                                Error when updating configuration: {errorMessage}
                            </h5>
                        </Grid>
                    )}
                    {currentStep > 2 && (
                        <Grid item>
                            <Button
                                size="medium"
                                variant="contained"
                                color='error'
                                onClick={handleGoBack}
                            >
                                Go Back
                            </Button>
                        </Grid>
                    )}
                    <Grid item>
                        <Button
                            size="medium"
                            variant="contained"
                            color='info'
                            onClick={handleNextStep}
                        >
                            CONTINUE
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            size="medium"
                            variant="contained"
                            color='error'
                            onClick={handleSkipNextStep}
                        >
                            SKIP
                        </Button>
                    </Grid>
                </Grid>
                <ModalOutlook
                    openModal={openModalOutlook}
                    handleCloseModal={handleCloseOutlookModal}
                    navigateNextPage={navigateNextPage}
                />
            </Grid>
        </Grid >
    )
}

export default FormMailboxComponent;