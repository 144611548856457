import React from 'react';
import { Button, Modal, Typography, Link } from '@mui/material';

const modalStyle = {
  position: 'absolute',
  width: 600,
  backgroundColor: 'white',
  border: '2px solid #000',
  boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
  padding: '16px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
};

const VerificationEmailModal = ({ open, onClose, email }) => {

  const openMailbox = () => {
    const domain = email.split('@')[1];
    let mailboxURL;

    const emailProviders = {
      'gmail.com': 'https://mail.google.com/mail/u/0/#inbox',
      'outlook.com': 'https://outlook.live.com/owa/',
      'hotmail.com': 'https://outlook.live.com/owa/',
      'yahoo.com': 'https://mail.yahoo.com/',
      'aol.com': 'https://mail.aol.com/',
      'icloud.com': 'https://www.icloud.com/mail',
      'protonmail.com': 'https://mail.protonmail.com/',
      'zoho.com': 'https://mail.zoho.com/',
      'yandex.com': 'https://mail.yandex.com/',
      'mail.com': 'https://www.mail.com/int/',
      'gmx.com': 'https://www.gmx.com/',
      'mail.ru': 'https://e.mail.ru/',
      'inbox.com': 'https://www.inbox.com/login.aspx',
      'fastmail.com': 'https://www.fastmail.com/',
      'tutanota.com': 'https://mail.tutanota.com/',
      'rediff.com': 'https://mail.rediff.com/',
      'lycos.com': 'http://www.lycos.com/mail/',
      'office365.com': 'https://outlook.office.com/owa/',
      'msn.com': 'https://outlook.live.com/owa/',
      'live.com': 'https://outlook.live.com/owa/',
      'yahoo.co.jp': 'https://mail.yahoo.co.jp/',
      'naver.com': 'https://mail.naver.com/',
      'daum.net': 'https://mail.daum.net/',
      'hanmail.net': 'https://mail.naver.com/',
      'nate.com': 'https://mail.nate.com/',
      'yahoo.co.uk': 'https://mail.yahoo.co.uk/',
      'yahoo.ca': 'https://mail.yahoo.ca/',
      'ymail.com': 'https://mail.yahoo.com/',
      'rocketmail.com': 'https://mail.yahoo.com/',
      'aol.co.uk': 'https://mail.aol.co.uk/',
      'aol.de': 'https://mail.aol.de/',
      'aol.fr': 'https://mail.aol.fr/',
      'aol.jp': 'https://mail.aol.jp/',
      'me.com': 'https://www.icloud.com/mail/',
      'mac.com': 'https://www.icloud.com/mail/',
      'hushmail.com': 'https://www.hushmail.com/',
      'lavabit.com': 'https://lavabit.com/',
      'runbox.com': 'https://www.runbox.com/',
      'earthlink.net': 'https://webmail.earthlink.net/',
      'att.net': 'https://mail.yahoo.com/',
      'cox.net': 'https://webmail.cox.net/',
      'roadrunner.com': 'https://www.spectrum.net/webmail/',
      'optonline.net': 'https://www.optimum.net/webmail/',
      'juno.com': 'https://webmail.juno.com/',
      'netzero.com': 'https://webmail.netzero.net/',
      'charter.net': 'https://www.spectrum.net/webmail/',
      'windstream.net': 'https://www.windstream.net/',
      'frontier.com': 'https://webmail.frontier.com/',
    };

    if (emailProviders[domain]) {
      mailboxURL = emailProviders[domain];
      window.open(mailboxURL, '_blank');
    } else {
      onClose()
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div style={modalStyle}>
        <Typography variant="h5" gutterBottom>
          Verification Email
        </Typography>
        <Typography variant="body1">
          Please check your email for a verification link. If you don't find it in your inbox,
          please check your spam folder
        </Typography>
        <div style={{ marginTop: '16px' }}>
          <Button onClick={onClose} color="primary" variant="contained" style={{ marginRight: '8px' }}>
            Close
          </Button>
          <Button onClick={() => openMailbox()} color="primary" variant="contained">
            Go to Mailbox
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default VerificationEmailModal;