import ReCAPTCHA from "react-google-recaptcha";
import _ from 'lodash';
import Select from 'react-select';

import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Typography, Accordion, AccordionDetails, AccordionSummary, Grid,
    FormControlLabel, InputAdornment, TextField, FormControl, Checkbox,
    FormGroup, Button, Autocomplete, Box,
} from '@mui/material';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import BusinessIcon from '@mui/icons-material/Business';
import FactoryIcon from '@mui/icons-material/Factory';
import WorkIcon from '@mui/icons-material/Work';


import { STORAGE_KEYS } from '../../helpers/helpers';
import { createContactPageWithDocument, searchCountry } from '../../APICalls'
import { CAPTCHA_ID, REGEX_UTILS } from '../../utils/utils';
import { BANNED_UTILS_MAILS } from '../../utils/utils';
import TagManager from 'react-gtm-module';

const SELECT_OPTIONS = [
    { label: "1-10 employees", value: "1-10 employees" },
    { label: "11-100 employees", value: "11-100 employees" },
    { label: "101-500 employees", value: "101-500 employees" },
    { label: "501-1000 employees", value: "501-1000 employees" },
    { label: "1000 or more employees", value: "1000 or more employees" },
]

const generateRandomToken = () => {
    const getRandomHex = () => Math.floor(Math.random() * 16 ** 2).toString(16).padStart(2, '0');
    const token = Array.from({ length: 12 }, getRandomHex).join('');
    return token;
}

const ContactUsFillPageTranslucent = ({ setSuccess }) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [email, setEmail] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [countryList, setCountryList] = useState([]);
    const [error, setError] = useState(false);
    const [checked, setChecked] = useState(false);
    const [token, setToken] = useState(null);
    const [isEmailError, setIsEmailError] = useState(false);
    const [isFirstNameError, setIsFirstNameError] = useState(false);
    const [isLastNameError, setIsLastNameError] = useState(false);
    const [isCompanyError, setIsCompanyError] = useState(false);
    const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [companySize, setCompanySize] = useState(null);
    const [industry, setIndustry] = useState(null);
    const [jobTitle, setJobTitle] = useState(null);
    const [captchaId, setCaptchaId] = useState(null);

    const onChangeCaptcha = (value) => {
        setCaptchaId(value);
    }

    const handleChangeCheckbox = (event) => {
        setChecked(event.target.checked)
    }

    const handleSave = async () => {
        const parsedNames = `${firstName} ${lastName}`
        const countryCodePhone = selectedCountry.phone_code;
        const validationCountryCodePhone = countryCodePhone.includes('+') ?
            `${countryCodePhone}${phoneNumber}`
            : `+${countryCodePhone}${phoneNumber}`
        const { status, data } = await createContactPageWithDocument(token, firstName, lastName, email, companyName, phoneNumber, jobTitle, industry, companySize, pathname)
        if (status === 200) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'success_click',
                    category: 'Button',
                    action: 'Click',
                    label: 'Trigger Success button clicked',
                    'user_email': email,
                    'phone_number': validationCountryCodePhone
                }
            });
            const transactionIdSave = data?.transaction_id
            localStorage.setItem('tidContact', transactionIdSave)

            navigate(`${pathname}/success`)
        } else {
            setErrorMessage(data?.message)
        }
    }

    const handleSaveChangesCall = () => {
        setError(false);
        setIsCompanyError(false);
        setIsFirstNameError(false);
        setIsLastNameError(false);
        setIsCompanyError(false);
        setIsPhoneNumberError(false);

        if (firstName && lastName && companyName && phoneNumber && email && checked && companySize && industry && jobTitle && selectedCountry && captchaId) {
            const emailRegex = new RegExp(REGEX_UTILS.email, 'g');
            setIsEmailError(false);
            if (firstName.length < 1) {
                setIsFirstNameError(true)
                return
            }

            if (lastName.length < 1) {
                setIsLastNameError(true)
                return
            }

            if (companyName.length < 1) {
                setIsCompanyError(true)
                return
            }

            if (!(emailRegex.test(email)) || BANNED_UTILS_MAILS.some((substring) => email.includes(substring))) {
                setIsEmailError(true);
                return;
            }

            if (phoneNumber.length < 9) {
                setIsPhoneNumberError(true)
                return
            }

            handleSave(firstName, lastName, email, companyName, phoneNumber, selectedCountry);
        } else {
            setError(true);
        }
    }

    const handleOpenPrivacyPolicy = () => {
        navigate('/privacy');
    }

    const handleOnChangeSelect = (value) => {
        setCompanySize(value.value)
    }

    const handleFirstName = (event) => {
        setFirstName(event.target.value);
    }

    const handleLastName = (event) => {
        setLastName(event.target.value);
    }

    const handleEmail = (event) => {
        setEmail(event.target.value.trim());
    }

    const handleCompany = (event) => {
        setCompanyName(event.target.value)
    }

    const handlePhoneNumber = (event) => {
        setPhoneNumber(event.target.value)
    }
    const handleGetCountry = (country) => {
        handleSearchCountry(country);
    }

    const handleIndustry = (event) => {
        setIndustry(event.target.value)
    }

    const handleJobTitle = (event) => {
        setJobTitle(event.target.value)
    }

    const handleSearchCountry = async (countryValue) => {
        const { status, data } = await searchCountry(countryValue)
        if (status === 200) {
            setCountryList(data);
        }
    }

    const setSelectedCountryValue = (country) => {
        setSelectedCountry(country);
    }

    useEffect(() => {
        const tokenData = sessionStorage.getItem(STORAGE_KEYS.TOKEN)
        const validatedToken = tokenData && (tokenData !== "null" && _.isNil(tokenData)) ? tokenData : generateRandomToken();
        setToken(validatedToken)
    }, []);

    return (
        <Accordion
            expanded={true}
            style={{
                height: "100%",
                borderRadius: "20px",
                background: "rgba(255,255,255,0)",
            }}
        >
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                    borderTopLeftRadius: "20px",
                    borderTopRightRadius: "20px",
                    height: "20%",
                }}
            >
                <Grid
                    container
                    alignItems='center'
                    justifyContent="center"
                >
                    <Typography
                        variant="h4"
                        color="#04dbd5"
                        style={{
                            fontFamily: 'Montserrat, sans-serif',
                        }}
                    >
                        <b>See how VAO's sales software works</b>
                    </Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid
                    container
                >
                    <Grid item xs={12} paddingTop={2}>
                        <Grid container justifyContent="center" spacing={3} alignItems="center">
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Grid
                                        item
                                        md={10}
                                    >
                                        <TextField
                                            fullWidth
                                            id="firstname"
                                            name="firstname"
                                            size="small"
                                            style={{ background: "rgb(232, 241, 250)" }}
                                            placeholder='First Name'
                                            onChange={handleFirstName}
                                            value={firstName}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        className="text-primary"
                                                    >
                                                        {<AccountCircleIcon />}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems="center">

                                    <Grid
                                        item
                                        md={10}
                                    >
                                        <TextField
                                            fullWidth
                                            id="lastname"
                                            name="lastname"
                                            style={{ background: "rgb(232, 241, 250)" }}
                                            placeholder='Last Name'
                                            size="small"
                                            onChange={handleLastName}
                                            value={lastName}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        className="text-primary"
                                                    >
                                                        {<AccountCircleIcon />}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Grid
                                        item
                                        md={10}
                                    >
                                        <TextField
                                            fullWidth
                                            id="company"
                                            name="company"
                                            style={{ background: "rgb(232, 241, 250)" }}
                                            size="small"
                                            placeholder='Company Name'
                                            onChange={handleCompany}
                                            value={companyName}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        className="text-primary"
                                                    >
                                                        {<ApartmentIcon />}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Grid
                                        item
                                        md={10}
                                    >
                                        <TextField
                                            fullWidth
                                            id="email"
                                            name="email"
                                            size="small"
                                            type="email"
                                            style={{ background: "rgb(232, 241, 250)" }}
                                            placeholder='Work Email'
                                            onChange={handleEmail}
                                            value={email}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        className="text-primary"
                                                    >
                                                        {<AlternateEmailIcon />}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} justifyContent="center" alignItems="center">
                                    <Grid
                                        item
                                        xs={10}
                                        md={4}
                                    >
                                        <Autocomplete
                                            id="country-select"
                                            fullWidth
                                            autoHighlight
                                            style={{ background: "rgb(232, 241, 250)" }}
                                            value={selectedCountry}
                                            size='small'
                                            onChange={(event, newValue) => {
                                                setSelectedCountryValue(newValue);
                                            }}
                                            onInputChange={(_event, value) => handleGetCountry(value)}
                                            options={countryList}
                                            getOptionLabel={(option) => {
                                                // Check if option has an orderNumber
                                                const emojiLabel = option.emoji || "";

                                                // Check if option has customer email
                                                const nameLabel = option.name || "";

                                                // Check if option has an soNumbers array
                                                const phoneLabel = option.phone_code || ""
                                                // Concatenate orderNumber and soNumbers labels
                                                return `${emojiLabel} ${nameLabel} ${phoneLabel}`;
                                            }}
                                            renderOption={(propsSelect, option) => {
                                                // Check if option has an orderNumber
                                                const emojiLabel = option.emoji || "";

                                                // Check if option has customer email
                                                const nameLabel = option.name || "";

                                                // Check if option has an soNumbers array
                                                const phoneLabel = option.phone_code || ""
                                                return (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...propsSelect}>
                                                        {`${emojiLabel} ${nameLabel} ${phoneLabel}`}
                                                    </Box>
                                                )
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder='Phone Country Code'
                                                    inputProps={{
                                                        ...params.inputProps,
                                                    }}
                                                    value={selectedCountry}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={10}
                                        md={6}
                                    >
                                        <TextField
                                            fullWidth
                                            id="phone"
                                            name="phone"
                                            size="small"
                                            style={{ background: "rgb(232, 241, 250)" }}
                                            type="number"
                                            onChange={handlePhoneNumber}
                                            placeholder="Phone Number"
                                            value={phoneNumber}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        className="text-primary"
                                                    >
                                                        {<SmartphoneIcon />}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Grid
                                        item
                                        xs={12}
                                        md={10}
                                    >
                                        <TextField
                                            fullWidth
                                            id="job title"
                                            name="job title"
                                            size="small"
                                            style={{ background: "rgb(232, 241, 250)" }}
                                            onChange={handleJobTitle}
                                            placeholder="Job Title"
                                            value={jobTitle}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        className="text-primary"
                                                    >
                                                        {<WorkIcon />}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Grid
                                        item
                                        xs={12}
                                        md={10}
                                    >
                                        <Select
                                            menuPlacement="bottom"
                                            options={SELECT_OPTIONS}
                                            placeholder={
                                                <Grid container spacing={1} alignItems="center" style={{ textAlign: "left" }}>
                                                    <Grid item >
                                                        <BusinessIcon />
                                                    </Grid>
                                                    <Grid item >
                                                        Company Size:
                                                    </Grid>
                                                </Grid>
                                            }
                                            onChange={handleOnChangeSelect}
                                            style={{ width: '100%', textAlign: 'left' }}
                                            value={companySize
                                                ? { label: companySize, value: companySize }
                                                : []}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Grid
                                        item
                                        xs={12}
                                        md={10}
                                    >
                                        <TextField
                                            fullWidth
                                            id="industry"
                                            name="industry"
                                            size="small"
                                            style={{ background: "rgb(232, 241, 250)" }}
                                            onChange={handleIndustry}
                                            placeholder="Industry"
                                            value={industry}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        className="text-primary"
                                                    >
                                                        {<FactoryIcon />}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            container
                            paddingTop={2}
                        >
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <FormGroup aria-label="position" column>
                                        <FormControlLabel
                                            value="end"
                                            style={{ textAlign: "left" }}
                                            control={
                                                <Checkbox
                                                    size="large"
                                                    checked={checked}
                                                    color="primary"
                                                    onChange={handleChangeCheckbox}
                                                />
                                            }
                                            label={
                                                <p style={{ fontSize: "15px", color: "white" }}>
                                                    I consent to VAO using the contact information provided to contact me about their products and services.
                                                </p>
                                            }
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <ReCAPTCHA
                                    sitekey={CAPTCHA_ID}
                                    onChange={onChangeCaptcha}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Button
                                            size="medium"
                                            variant="text"
                                            color='info'
                                            onClick={handleOpenPrivacyPolicy}
                                            style={{
                                                textDecoration: "underline"
                                            }}
                                        >
                                            Privacy Policy Terms
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Button
                                                    className='gtag-trigger'
                                                    size="medium"
                                                    variant="contained"
                                                    style={{ backgroundColor: "#04dbd5", color: "black" }}
                                                    onClick={handleSaveChangesCall}
                                                >
                                                    <b>Get Started</b>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {error && (
                                <Grid container paddingTop={5} textAlign="center">
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h6"
                                            color="#d32f2f"
                                        >
                                            <b>There are some missing fields, please check. </b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {errorMessage && (
                                <Grid container paddingTop={5} textAlign="center">
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h6"
                                            color="#d32f2f"
                                        >
                                            <b>{errorMessage}</b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {isEmailError && (
                                <Grid container paddingTop={5} textAlign="center">
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h6"
                                            color="#d32f2f"
                                        >
                                            <b>Invalid Work Email structure, please check. </b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {isCompanyError && (
                                <Grid container paddingTop={5} textAlign="center">
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h6"
                                            color="#d32f2f"
                                        >
                                            <b>Company field is too short. please check </b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {isFirstNameError && (
                                <Grid container paddingTop={5} textAlign="center">
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h6"
                                            color="#d32f2f"
                                        >
                                            <b>First Name field is too short, please check. </b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {isLastNameError && (
                                <Grid container paddingTop={5} textAlign="center">
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h6"
                                            color="#d32f2f"
                                        >
                                            <b>Last Name field is too short, please check. </b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {isPhoneNumberError && (
                                <Grid container paddingTop={5} textAlign="center">
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h6"
                                            color="#d32f2f"
                                        >
                                            <b>Phone number field is too short, please check. </b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default ContactUsFillPageTranslucent;
