import _ from 'lodash';

import { Button, Card, CardActions, CardContent, Grid, Modal, Typography } from '@mui/material'
import React from 'react'


export const GenericModalCurrPrevValues = ({
  openModal, handleCloseModal, currValue, data
}) => {
  const currentValueText = () => currValue
    ? "Current Value"
    : "Previous Value";

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'auto',
      }}
    >
      <Grid
        container
        justifyContent="center"
        flexDirection="row"
      >
        <Card
          style={{
            width: "70%",
            height: '100%'
          }}
        >
          <CardActions
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              size="medium"
              variant="contained"
              color='error'
              onClick={handleCloseModal}
            >
              x
            </Button>
          </CardActions>
          <CardContent>
            <Typography
              variant="h4"
              textAlign="center"
            >
              {currentValueText()} Visualization
            </Typography>
            <Grid
              container
              justifyContent="center"
              paddingTop={5}
            >
              {(!_.isEmpty(data)) && (
                <h3>
                  {data}
                </h3>
              )}
            </Grid>
          </CardContent>
        </Card >
      </Grid>
    </Modal >
  )
}
