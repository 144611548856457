import React from 'react'

import { styled } from '@mui/material/styles';

import EditNoteIcon from '@mui/icons-material/EditNote';
import SettingsIcon from '@mui/icons-material/Settings';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DoneIcon from '@mui/icons-material/Done';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import { Step, Stepper, StepLabel } from '@mui/material';

const STEPS_LABEL = ['SIGNUP', 'CONFIGURATION', 'LOAD ORDER DATA'];

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 38,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(90deg, rgba(75,75,75,1) 0%, rgba(154,154,154,1) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient(90deg, rgba(75,75,75,1) 0%, rgba(154,154,154,1) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 80,
    height: 80,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'radial-gradient(circle, rgba(29,59,91,1) 0%, rgba(133,184,253,1) 83%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'radial-gradient(circle, rgba(29,59,91,1) 0%, rgba(133,184,253,1) 83%)',
    }),
}));

const StepperColor = (props) => {
    const { active, completed, className } = props;

    const icons = {
        1: <EditNoteIcon />,
        2: <SettingsIcon />,
        3: <CloudUploadIcon />,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {completed
                ? <DoneIcon />
                : icons[String(props.icon)]
            }
        </ColorlibStepIconRoot>
    );
}

const StepperRenderer = ({ activeStep }) => {
    return <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {STEPS_LABEL.map((label) => (
            <Step key={label}>
                <StepLabel StepIconComponent={StepperColor}><b>{label}</b></StepLabel>
            </Step>
        ))}
    </Stepper>
}


export default StepperRenderer;