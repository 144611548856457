import _ from "lodash";

import {
    Button, Grid, TableContainer, TableRow, TableBody, TableHead, TableCell,
    Paper, Table, tableCellClasses, Tabs, Tab, CircularProgress, IconButton,
    Card
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import MasterDataConfig from "../../SOPHome/Settings/MasterData/MasterDataConfig";
import { getRecordsList } from "../../../../APICalls";

const HOME_COLUMN_HEADERS = [
    'Name', 'NPI', 'ACID'
]

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#939393',
        color: theme.palette.common.black,
        justifyContent: "center"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        justifyContent: "center"
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const TOTAL_KEYS = [
    'SO_CUSTOMER',
    'MATERIAL_PLANTS'
]

const LoadOrderDataTable = ({ currentStep, token }) => {
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    const checkEmptyList = async (token) => {
        const responseData = await getRecordsList(token);
        const { items } = responseData;
        return !_.isEmpty(items);
    }

    const handleNextStep = () => {
        setError(false);
        if (checkEmptyList(token)) {
            navigate("/middleDashboard");
        } else {
            setError(true);
        }
    }

    return (
        <Grid container paddingTop={10} paddingBottom={10}>
            <Grid item xs={12}>
                <Grid container style={{
                    width: "70vw"
                }}>
                    <MasterDataConfig homePage={true} />
                </Grid>
            </Grid>
            {error && (
                <Grid item xs={12}>
                    <h4 style={{
                        color: "#db5757"
                    }}>
                        At least one record should be created to proceed.
                    </h4>
                </Grid>
            )}            <Grid item xs={12} alignSelf='flex-end' paddingTop={5}>
                <Grid container spacing={4}>
                    <Grid item>
                        <Button
                            size="medium"
                            variant="contained"
                            color='info'
                            onClick={handleNextStep}
                        >
                            FINISH
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
};

export default LoadOrderDataTable;
