import React, { useEffect, useState } from 'react';
import { Button, Modal, Typography } from '@mui/material'
import Joyride from 'react-joyride';
import VerificationEmailModal from '../../StepperComponents/Signup/VerificationEmailModal';

const joyrideStyles = {
    options: {
        backgroundColor: '#f5f5f5',
        primaryColor: '#1976D2'
    },
    tooltipFooter: {
        marginTop: '8px', // Adjust the margin as needed to reduce space between text and buttons
    },
    tooltipContent: {
        padding: '5px 5px',
    },
};

const modalStyle = {
    position: 'absolute',
    width: 600,
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
    padding: '16px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  };

const SignUpGuidedTourComponent = () => {

    const [guidedTourFlag, setGuidedTourFlag] = useState(false)
    const [stepIndex, setStepIndex] = useState(0)
    const [openModalVerify, setopenModalVerify] = useState(false);

    const org_steps = [
        {
            content: <p style={{ fontSize: '15px' }}>Welcome to VAO LABS, We want to welcome you to our Auto Management platform</p>,
            locale: { skip: <strong onClick={() => { setState({ run: false }) }}>SKIP</strong> },
            placement: "center",
            target: "body",
            focusTrap: true,
            styles: {
                options: {
                    width: 300,
                    zIndex: 100000
                },
            },
        },
        {
            content: <p style={{ fontSize: '15px' }}>Registering on our platform provides you with benefits such as saving processed orders, monitoring and automatic processing of orders that arrive in your mailbox and the ability to edit them</p>,
            locale: { skip: <strong onClick={() => { setState({ run: false }) }}>SKIP</strong> },
            placement: "center",
            target: "body",
            focusTrap: true,
            styles: {
                options: {
                    width: 300,
                    zIndex: 100000
                },
            },
        },
        {
            content: <p style={{ fontSize: '15px' }}>Creating an account on our platform can be done briefly by filling out this form</p>,
            locale: { skip: <strong onClick={() => { setState({ run: false }) }}>SKIP</strong> },
            placement: "auto",
            target: ".signup-form",
            focusTrap: true,
            styles: {
                options: {
                    width: 300,
                    zIndex: 100000
                },
            },
        },
        {
            content: <p style={{ fontSize: '15px' }}>Once the fields are filled, please accept our terms and conditions, and press the "Continue" button</p>,
            locale: { skip: <strong onClick={() => { setState({ run: false }) }}>SKIP</strong> },
            placement: "auto",
            target: ".signup-last-tep",
            focusTrap: true,
            styles: {
                options: {
                    width: 300,
                    zIndex: 100000
                },
            },
        },
        {
            content: <p style={{ fontSize: '15px' }}>If the information you have entered is valid, we will send a confirmation email to your email inbox.</p>,
            locale: { skip: <strong onClick={() => { setState({ run: false }) }}>SKIP</strong> },
            placement: "center",
            target: "body",
            focusTrap: true,
            styles: {
                options: {
                    width: 300,
                    zIndex: 100000
                },
            },
        },
        {
            content: <p style={{ fontSize: '15px' }}> Please enter your email inbox and verify your account to complete the registration process. If you do not find the email in your inbox, please check the Spam folder</p>,
            locale: { skip: <strong onClick={() => { setState({ run: false }) }}>SKIP</strong> },
            placement: "auto",
            target: "#verificationModal",
            focusTrap: true,
            styles: {
                options: {
                    width: 300,
                    zIndex: 100000,
                },
            },
        },
        {
            content: <p style={{ fontSize: '15px' }}> Once you have verified your email, you can log in to our platform.</p>,
            locale: { skip: <strong onClick={() => { setState({ run: false }) }}>SKIP</strong> },
            placement: "center",
            target: "body",
            focusTrap: true,
            styles: {
                options: {
                    width: 300,
                    zIndex: 100000
                },
            },
        },
        {
            content: <p style={{ fontSize: '15px' }}> We wish you the best of experiences using our platform, in case you have problems within it, do not hesitate to contact us</p>,
            locale: { skip: <strong onClick={() => { setState({ run: false }) }}>SKIP</strong> },
            placement: "center",
            target: "body",
            focusTrap: true,
            styles: {
                options: {
                    width: 300,
                    zIndex: 100000
                },
            },
        },
    ]

    const [{ run, steps }, setState] = useState({
        run: guidedTourFlag,
        steps: org_steps
    });

    const handleOnCloseModal = () => {
        setopenModalVerify(false)
    }

    const handleJoyrideCallback = (data) => {
        const { action, index, type } = data;
        if (action === "reset" || type === "tour:end") {
            setState({ run: false })
            setStepIndex(0)
            document.querySelector("#firstname").value = ""
            document.querySelector("#lastname").value = ""
            document.querySelector("#email").value = ""
            // document.querySelector("#country").value = ""
            // document.querySelector("#state").value = ""
            // document.querySelector("#city").value = ""
            document.querySelector("#password").value = ""
            document.querySelector("#confirmPassword").value = ""
            document.querySelector("#company").value = ""
            document.querySelector("#zip").value = ""
            document.querySelector("#check").checked = false
        }
        if (index === 2 && action === "prev") {
            document.querySelector("#firstname").value = ""
            document.querySelector("#lastname").value = ""
            document.querySelector("#email").value = ""
            // document.querySelector("#country").value = ""
            // document.querySelector("#state").value = ""
            // document.querySelector("#city").value = ""
            document.querySelector("#password").value = ""
            document.querySelector("#confirmPassword").value = ""
            document.querySelector("#company").value = ""
            document.querySelector("#zip").value = ""
            document.querySelector("#check").checked = false
        }
        if (index === 2 && type === "step:before") {
            document.querySelector("#firstname").value = "John"
            document.querySelector("#lastname").value = "Doe"
            document.querySelector("#email").value = "John.Doe@vao.world"
            // document.querySelector("#country").value = "United States"
            // document.querySelector("#state").value = "California"
            // document.querySelector("#city").value = "San Francisco"
            document.querySelector("#password").value = "BzU5x6GFxBrk"
            document.querySelector("#confirmPassword").value = "BzU5x6GFxBrk"
            document.querySelector("#company").value = "VAO LABS"
            document.querySelector("#zip").value = "94188"
            document.querySelector("#check").checked = true
        }
        if (index === 3 && action === "prev") {
            setopenModalVerify(false)
        }
        if (index === 4 && type === "step:before") {
            setopenModalVerify(true)
        }
        if (index === 7 && action === "prev") {
            setopenModalVerify(true)
        }
        if (index === 6 && action === "next") {
            setopenModalVerify(false)
        }
        if (action === "next" && type === "step:after") {
            setStepIndex(stepIndex + 1)
        }
        if (action === "prev" && type === "step:after") {
            setStepIndex(stepIndex - 1)
        }
    }

    useEffect(() => {
        let flag = localStorage.getItem("guidedTourFlag")
        if (flag === null) {
            setState({ run: true })
            setGuidedTourFlag(true)
        } else {
            setState({ run: false })
            setGuidedTourFlag(false)
        }
    }, [])

    useEffect(() => {
        if (run) {
            setState({
                steps: org_steps
            })
        }
    }, [run]);

    return (
        <div>
            <Button
                variant="text"
                color="info"
                onClick={() => {
                    setState({ run: true })
                }}
            >
                <b>
                    Support
                </b>
            </Button>
            <Joyride
                continuous
                callback={handleJoyrideCallback}
                run={run}
                steps={steps}
                hideCloseButton
                scrollToFirstStep
                showSkipButton
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                spotlightClicks={true}
                styles={joyrideStyles}
                stepIndex={stepIndex}
            />
            <Modal open={openModalVerify} onClose={handleOnCloseModal}>
                <div style={modalStyle} id='verificationModal'>
                    <Typography variant="h5" gutterBottom>
                        Verification Email
                    </Typography>
                    <Typography variant="body1">
                        Please check your email for a verification link. If you don't find it in your inbox,
                        please check your spam folder
                    </Typography>
                    <div style={{ marginTop: '16px' }}>
                        <Button  color="primary" variant="contained" style={{ marginRight: '8px' }}>
                            Close
                        </Button>
                        <Button  color="primary" variant="contained">
                            Go to Mailbox
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default SignUpGuidedTourComponent;
