import _ from "lodash";
import Select from 'react-select';

import {
    Button, Grid, TableContainer, TableRow, TableBody, TableHead, TableCell,
    Paper, Table, tableCellClasses, Tooltip, Tab, CircularProgress, IconButton,
    Card, Pagination, Menu, MenuItem, TextField, InputAdornment
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import {
    getRecordsList, deleteRecord, uploadCSVMasterData, downloadCSVMasterData,
    getMasterDataLists
} from "../../../../../APICalls";
import { STORAGE_KEYS } from "../../../../../helpers/helpers";

import UploadMasterDataConfig from "./UploadMasterDataConfig";
import EditMasterDataConfig from "./EditMasterDataConfig";
import UploadCSVModal from "./UploadCSVModal";

const HOME_COLUMN_HEADERS = [
    'shiptoaddress', 'shiptoparty', "soldtoaddress", 'soldtoparty', 'materialdescription', 'materialnumber', 'salesorganization', 'distributionchannel', 'division', "salesdistrict",
    "salesoffice", "salesgroup", "paymentterms", "equipmenttype", "ordertype", "orderreason", "partnerfunction", "partnernumber", "shippingcondition",
    "suom", 'plant', 'countryofdeliveringplant', 'incoterms', 'incotermsdescription'
]

const DEFAULT_APPLIED_FILTERS_VALUES = {
    "shiptoaddress": "ALL", "shiptoparty": "ALL", "soldtoaddress": "ALL", "soldtoparty": "ALL",
    'materialdescription': "ALL", 'materialnumber': "ALL", "salesorganization": "ALL", "distributionchannel": "ALL", "division": "ALL",
    "salesdistrict": "ALL", "salesoffice": "ALL", "salesgroup": "ALL", "paymentterms": "ALL", "equipmenttype": "ALL", "ordertype": "ALL", "orderreason": "ALL",
    "partnerfunction": "ALL", "partnernumber": "ALL", "shippingcondition": "ALL", "suom": "ALL", "plant": "ALL", "countryofdeliveringplant": "ALL",
    "incoterms": "ALL", "incotermsdescription": "ALL",
};

const DEFAULT_APPLIED_FILTERS = {
    "shiptoaddress": false, "shiptoparty": false, "soldtoaddress": false, "soldtoparty": false,
    'materialdescription': false, 'materialnumber': false, "salesorganization": false, "distributionchannel": false, "division": false,
    "salesdistrict": false, "salesoffice": false, "salesgroup": false, "paymentterms": false, "equipmenttype": false, "ordertype": false, "orderreason": false,
    "partnerfunction": false, "partnernumber": false, "shippingcondition": false, "suom": false, "plant": false, "countryofdeliveringplant": false,
    "incoterms": false, "incotermsdescription": false,
};
const ADDED_FIELDS = [
    'salesdistrict',
    'salesoffice',
    'salesgroup',
    'paymentterms',
    'equipmenttype',
    'ordertype',
    'orderreason',
    'partnerfunction',
    'partnernumber',
    'shippingcondition',
]

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const headerParser = (header) => {
    switch (header) {
        case ('shiptoaddress'):
            return "Ship To Address";
        case ('shiptoparty'):
            return "Ship To Party";
        case ('soldtoparty'):
            return "Sold To Party";
        case ('salesorganization'):
            return "Sales Organization";
        case ('distributionchannel'):
            return "Distribution Channel";
        case ('materialdescription'):
            return "Material Description";
        case ('materialnumber'):
            return "Material Number";
        case ('countryofdeliveringplant'):
            return "Country of delivering plant";
        case ('incotermsdescription'):
            return "Incoterms Description";
        case 'soldtoaddress':
            return 'sold to address';
        case 'salesdistrict':
            return 'Sales district';
        case 'salesoffice':
            return 'Sales office';
        case 'salesgroup':
            return 'Sales group';
        case 'paymentterms':
            return 'Payment terms';
        case 'equipmenttype':
            return 'Equipment type';
        case 'ordertype':
            return 'Order type';
        case 'orderreason':
            return 'Order reason';
        case 'partnerfunction':
            return 'Partner function';
        case 'partnernumber':
            return 'Partner number';
        case 'shippingcondition':
            return 'Shipping condition';
        default:
            return header
    }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#939393',
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const TOTAL_KEYS = [
    'SO_CUSTOMER',
    'MATERIAL_PLANTS'
]

const MasterDataConfig = ({ homePage }) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const navigate = useNavigate();
    const [currentElementKeys, setCurrentElementKeys] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [leftPanelTabValue, setLeftPanelTabValue] = useState(0);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [openUploadCSV, setOpenUploadCSV] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(50);
    const [totalPages, setTotalPages] = useState(1);
    const [appliedFilters, setAppliedFilters] = useState(DEFAULT_APPLIED_FILTERS);
    const [appliedFiltersValues, setAppliedFiltersValues] = useState(DEFAULT_APPLIED_FILTERS_VALUES);

    const [shipToPartyAnchorElement, setShipToPartyAnchorElement] = useState(null);
    const [soldToAnchorElement, setSoldToAnchorElement] = useState(null);
    const [salesOrgAnchorElement, setSalesOrgAnchorElement] = useState(null);
    const [distrChannelAnchorElement, setDistrChannelAnchorElement] = useState(null);
    const [divisionAnchorElement, setDivisionAnchorElement] = useState(null);
    const [materialAnchorElement, setMaterialAnchorElement] = useState(null);
    const [materialNumberAnchorElement, setMaterialNumberAnchorElement] = useState(null);
    const [plantAnchorElement, setPlantAnchorElement] = useState(null);
    const [shipToAnchorElement, setShipToAnchorElement] = useState(null);
    const [countryOfPlantAnchorElement, setCountryOfPlantAnchorElement] = useState(null);
    const [incotermsAnchorElement, setIncotermsAnchorElement] = useState(null);
    const [incotermsDescAnchorElement, setIncotermsDescAnchorElement] = useState(null);
    const [valuesAnchorElement, setValuesAnchorElement] = useState({
        'salesdistrict': false,
        'salesoffice': false,
        'salesgroup': false,
        'paymentterms': false,
        'equipmenttype': false,
        'ordertype': false,
        'orderreason': false,
        'partnerfunction': false,
        'partnernumber': false,
        'shippingcondition': false,
    })

    const [openShipToFilter, setOpenShipToFilter] = useState(false);
    const [openShipToPartyFilter, setOpenShipToPartyFilter] = useState(false);
    const [openSoldToFilter, setOpenSoldToFilter] = useState(false);
    const [opensalesOrgFilter, setOpenSalesOrgFilter] = useState(false);
    const [openDistrChannelFilter, setOpenDistrChannelFilter] = useState(false);
    const [openDivisionFilter, setOpenDivisionFilter] = useState(false);
    const [openMaterialFilter, setOpenMaterialFilter] = useState(false);
    const [openMaterialNumberFilter, setOpenMaterialNumberFilter] = useState(false);
    const [openPlantFilter, setOpenPlantFilter] = useState(false);
    const [openCountryOfPlantFilter, setOpenCountryOfPlantFilter] = useState(false);
    const [openIncotermsFilter, setOpenIncotermsFilter] = useState(false);
    const [openIncotermsDescFilter, setOpenIncotermsDescFilter] = useState(false);
    const [masterDataLists, setMasterDataLists] = useState(null);
    const [selectedMasterDataCollection, setSelectedMasterDataCollection] = useState(null);
    const [valuesFilter, setValuesFilter] = useState({
        'salesdistrict': false,
        'salesoffice': false,
        'salesgroup': false,
        'paymentterms': false,
        'equipmenttype': false,
        'ordertype': false,
        'orderreason': false,
        'partnerfunction': false,
        'partnernumber': false,
        'shippingcondition': false,
    })

    const handleAbortListApi = () => {
        controller.abort(); // Cancel the request
    }

    const handleOpenShipToFilter = (event) => {
        setShipToAnchorElement(event.currentTarget);
        setOpenShipToFilter(true);
    };

    const handleCloseShipToFilter = () => {
        setShipToAnchorElement(null);
        setOpenShipToFilter(false);
    };

    const handleOpenShipToPartyFilter = (event) => {
        setShipToPartyAnchorElement(event.currentTarget);
        setOpenShipToPartyFilter(true);
    };

    const handleOpenFilterGeneral = (key) => (event) => {
        setValuesAnchorElement({
            ...valuesAnchorElement,
            [key]: event.currentTarget
        })
        setValuesFilter({
            ...valuesFilter,
            [key]: true
        })
    };

    const checkEmptyListMasterData = async (token) => {
        const responseData = await getMasterDataLists(token);
        setMasterDataLists(responseData);
        return responseData
    }

    const handleOnChangeSelect = (value) => {
        setSelectedMasterDataCollection(value.value)
    }


    const handleCloseFilterGeneral = (key) => (event) => {
        setValuesAnchorElement({
            ...valuesAnchorElement,
            [key]: null
        })
        setValuesFilter({
            ...valuesFilter,
            [key]: false
        })
    };

    const handleCloseShipToPartyFilter = () => {
        setShipToPartyAnchorElement(null);
        setOpenShipToPartyFilter(false);
    };

    const handleOpenSoldToFilter = (event) => {
        setSoldToAnchorElement(event.currentTarget);
        setOpenSoldToFilter(true);
    };

    const handleCloseSoldToFilter = () => {
        setSoldToAnchorElement(null);
        setOpenSoldToFilter(false);
    };

    const handleOpenSalesOrgFilter = (event) => {
        setShipToAnchorElement(event.currentTarget);
        setOpenShipToFilter(true);
    };

    const handleCloseSalesOrgFilter = () => {
        setSalesOrgAnchorElement(null);
        setOpenSalesOrgFilter(false);
    };
    const handleOpenDistrChannelFilter = (event) => {
        setDistrChannelAnchorElement(event.currentTarget);
        setOpenDistrChannelFilter(true);
    };

    const handleCloseDistrChannelFilter = () => {
        setDistrChannelAnchorElement(null);
        setOpenDistrChannelFilter(false);
    };

    const handleOpenDivisionFilter = (event) => {
        setDivisionAnchorElement(event.currentTarget);
        setOpenDivisionFilter(true);
    };

    const handleCloseDivisionFilter = () => {
        setDivisionAnchorElement(null);
        setOpenDivisionFilter(false);
    };

    const handleOpenMaterialFilter = (event) => {
        setMaterialAnchorElement(event.currentTarget);
        setOpenMaterialFilter(true);
    };

    const handleCloseMaterialFilter = () => {
        setMaterialAnchorElement(null);
        setOpenMaterialFilter(false);
    };

    const handleOpenMaterialNumberFilter = (event) => {
        setMaterialNumberAnchorElement(event.currentTarget);
        setOpenMaterialNumberFilter(true);
    };

    const handleCloseMaterialNumberFilter = () => {
        setMaterialNumberAnchorElement(null);
        setOpenMaterialNumberFilter(false);
    };

    const handleOpenPlantFilter = (event) => {
        setPlantAnchorElement(event.currentTarget);
        setOpenPlantFilter(true);
    };

    const handleClosePlantFilter = () => {
        setPlantAnchorElement(null);
        setOpenPlantFilter(false);
    };

    const handleOpenCountryOfPlantFilter = (event) => {
        setCountryOfPlantAnchorElement(event.currentTarget);
        setOpenCountryOfPlantFilter(true);
    };

    const handleCloseCountryOfPlantFilter = () => {
        setCountryOfPlantAnchorElement(null);
        setOpenCountryOfPlantFilter(false);
    };

    const handleOpenIncotermsFilter = (event) => {
        setIncotermsAnchorElement(event.currentTarget);
        setOpenIncotermsFilter(true);
    };

    const handleCloseIncotermsFilter = () => {
        setIncotermsAnchorElement(null);
        setIncotermsAnchorElement(false);
    };

    const handleOpenIncotermsDescFilter = (event) => {
        setIncotermsDescAnchorElement(event.currentTarget);
        setOpenIncotermsDescFilter(true);
    };

    const handleCloseIncotermsDescFilter = () => {
        setIncotermsDescAnchorElement(null);
        setOpenIncotermsDescFilter(false);
    };

    const handleOpenCSVModal = () => {
        setOpenUploadCSV(true);
    }

    const handleCloseUploadCSV = () => {
        setOpenUploadCSV(false);
    }

    const handleOpenUploadModal = () => {
        setOpenUploadModal(true);
    }

    const handleCloseUploadModal = () => {
        setOpenUploadModal(false);
    }

    const downloadCSVFile = (excelFile) => {
        const href = URL.createObjectURL(new Blob([excelFile]));
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'ExcelFile.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    const handleAPICallDownloadCSV = async () => {
        setLoadingFile(true);
        const csvResult = await downloadCSVMasterData(token, selectedMasterDataCollection, page, size)
        if (csvResult) {
            setLoadingFile(false);
            downloadCSVFile(csvResult);
        }
    }

    const handleOpenEditModal = (id) => () => {
        const foundOrder = filteredData.find((element) => element.id === id);
        setSelectedOrder(foundOrder)
        setSelectedId(id);
        setOpenEditModal(true);
    }

    const handleCloseEditModal = () => {
        setOpenEditModal(false);
    }

    const uploadCSVApiCall = async (file) => {
        const response = await uploadCSVMasterData(file, token, selectedMasterDataCollection);
        handleApiCallList(token, page);
        return response
    }

    const checkIfLoading = () => {
        if (loading) {
            return (
                <Grid
                    container
                    justifyContent="center"
                    paddingTop="3%"
                >
                    <Grid item xs={12}>
                        <CircularProgress color="info" />
                    </Grid>
                    <Grid item xs={12}>
                        <h2>
                            Loading data, please wait...
                        </h2>
                    </Grid>
                </Grid>
            )
        } else if (_.isEmpty(filteredData)) {
            return (
                <Grid
                    justifyContent="center"
                    alignItems="stretch"
                    textAlign="center"
                >
                    <h2>
                        There's no data to show.
                    </h2>
                </Grid>
            )
        }
    }

    const getTableKeys = () => {
        setCurrentElementKeys(HOME_COLUMN_HEADERS);
    }

    const refreshData = () => {
        handleApiCallList(token);
    }

    const handleApiCallList = async (token, pageValue = 1) => {
        setLoading(true);
        const {
            shiptoaddress, shiptoparty, soldtoaddress, soldtoparty,
            materialdescription, materialnumber, salesorganization, distributionchannel, division,
            salesdistrict, salesoffice, salesgroup, paymentterms, equipmenttype, ordertype, orderreason,
            partnerfunction, partnernumber, shippingcondition, suom, plant, countryofdeliveringplant,
            incoterms, incotermsdescription
        } = appliedFiltersValues
        const responseData = await getRecordsList(token, pageValue, 25, shiptoaddress, shiptoparty, soldtoaddress, soldtoparty,
            materialdescription, materialnumber, salesorganization, distributionchannel, division,
            salesdistrict, salesoffice, salesgroup, paymentterms, equipmenttype, ordertype, orderreason,
            partnerfunction, partnernumber, shippingcondition, suom, plant, countryofdeliveringplant,
            incoterms, incotermsdescription, selectedMasterDataCollection, signal);
        const { items, page, size, total } = responseData;
        setTotalPages(Math.ceil(total / size));
        setPage(page);
        setSize(size);
        setLoading(false);
        setFilteredData(items);
    }

    const handlePageChange = (event, value) => {
        setPage(value);
        setLoading(true);
        handleApiCallList(token, value);
    }

    const handleDeleteAPICall = async (id) => {
        const responseDelete = await deleteRecord(token, [id]);
        if (responseDelete === "Successful") {
            refreshData(token);
        }
    }

    const handleDelete = (id) => () => {
        handleDeleteAPICall(id);
    }

    useEffect(() => {
        const tokenData = localStorage.getItem(STORAGE_KEYS.TOKEN);
        setToken(tokenData);
        setCurrentElementKeys([]);
        getTableKeys();
        checkEmptyListMasterData(tokenData);
    }, []);

    useEffect(() => {
        if (token && selectedMasterDataCollection) {
            handleApiCallList(token);
        }
    }, [token, selectedMasterDataCollection])

    useEffect(() => {
        if (page && token) {
            setLoading(true);
            handleApiCallList(token, page);
            return () => {
                // Cancel the request on unmount
                handleAbortListApi();
            };
        }
    }, [appliedFiltersValues]);

    const checkCurrentAppliedFilter = (key) => {
        return appliedFilters[key]
            ? "success"
            : "primary"
    }

    const checkFilterValue = (validationText) => {
        return (validationText === "ALL"
            ? ""
            : validationText
        )
    }

    const handleClearFilter = (keyFilter) => () => {
        setAppliedFilters({
            ...appliedFilters,
            [keyFilter]: false,
        });
        setAppliedFiltersValues({
            ...appliedFiltersValues,
            [keyFilter]: "ALL",
        });
    }

    const handleFilteringColumns = (keyValue) => (eventValue) => {
        const adjustedEventValue = eventValue.target.value
        if (_.isEmpty(adjustedEventValue)) {
            setAppliedFilters({
                ...appliedFilters,
                [keyValue]: false,
            });
            setAppliedFiltersValues({
                ...appliedFiltersValues,
                [keyValue]: "ALL",
            });
            setPage(1)
            return;
        }
        setAppliedFiltersValues({
            ...appliedFiltersValues,
            [keyValue]: adjustedEventValue,
        });

        setAppliedFilters({
            ...appliedFilters,
            [keyValue]: true,
        });
        setPage(1)
    };



    const checkFilters = (key) => {
        if (key === "shiptoaddress") {
            return (
                <>
                    <Grid xs={1}>
                        <IconButton
                            onClick={handleOpenShipToFilter}
                            edge="end"
                            color={checkCurrentAppliedFilter(key)}
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <Menu
                            anchorEl={shipToAnchorElement}
                            open={openShipToFilter}
                            onClose={handleCloseShipToFilter}
                        >
                            <MenuItem
                                onClick={() => false}
                            >
                                &#8288;
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onInput={((key) => (event) => {
                                        handleFilteringColumns(event.target.value)
                                    })}
                                    label="Ship To Address Filter"
                                    placeholder='Type and search Ship To Address'
                                    variant="outlined"
                                    value={checkFilterValue(appliedFiltersValues.shiptoaddress)}
                                >
                                </TextField>
                                <IconButton
                                    onClick={handleClearFilter(key)}
                                    edge="end"
                                    color="error"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )
        }
        if (ADDED_FIELDS.includes(key)) {
            return (
                <>
                    <Grid xs={1}>
                        <IconButton
                            onClick={handleOpenFilterGeneral(key)}
                            edge="end"
                            color={checkCurrentAppliedFilter(key)}
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <Menu
                            anchorEl={valuesAnchorElement[key]}
                            open={valuesFilter[key]}
                            onClose={handleCloseFilterGeneral(key)}
                        >
                            <MenuItem
                                onClick={() => false}
                            >
                                &#8288;
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onInput={handleFilteringColumns(key)}
                                    label="Ship To Party Filter"
                                    placeholder='Type and search Ship To Party'
                                    variant="outlined"
                                    value={checkFilterValue(appliedFiltersValues[key])}
                                >
                                </TextField>
                                <IconButton
                                    onClick={handleClearFilter(key)}
                                    edge="end"
                                    color="error"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )
        }
        if (key === "shiptoparty") {
            return (
                <>
                    <Grid xs={1}>
                        <IconButton
                            onClick={handleOpenShipToPartyFilter}
                            edge="end"
                            color={checkCurrentAppliedFilter(key)}
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <Menu
                            anchorEl={shipToPartyAnchorElement}
                            open={openShipToPartyFilter}
                            onClose={handleCloseShipToPartyFilter}
                        >
                            <MenuItem
                                onClick={() => false}
                            >
                                &#8288;
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onInput={handleFilteringColumns(key)}
                                    label="Ship To Party Filter"
                                    placeholder='Type and search Ship To Party'
                                    variant="outlined"
                                    value={checkFilterValue(appliedFiltersValues.shiptoparty)}
                                >
                                </TextField>
                                <IconButton
                                    onClick={handleClearFilter(key)}
                                    edge="end"
                                    color="error"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )
        }
        if (key === "soldtoparty") {
            return (
                <>
                    <Grid xs={1}>
                        <IconButton
                            onClick={handleOpenSoldToFilter}
                            edge="end"
                            color={checkCurrentAppliedFilter(key)}
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <Menu
                            anchorEl={soldToAnchorElement}
                            open={openSoldToFilter}
                            onClose={handleCloseSoldToFilter}
                        >
                            <MenuItem
                                onClick={() => false}
                            >
                                &#8288;
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onInput={handleFilteringColumns(key)}
                                    label="Sold To Party Filter"
                                    placeholder='Type and search Sold To Party'
                                    variant="outlined"
                                    value={checkFilterValue(appliedFiltersValues.soldtoparty)}
                                >
                                </TextField>
                                <IconButton
                                    onClick={handleClearFilter(key)}
                                    edge="end"
                                    color="error"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )
        }
        if (key === "salesorganization") {
            return (
                <>
                    <Grid xs={1}>
                        <IconButton
                            onClick={handleOpenSalesOrgFilter}
                            edge="end"
                            color={checkCurrentAppliedFilter(key)}
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <Menu
                            anchorEl={salesOrgAnchorElement}
                            open={opensalesOrgFilter}
                            onClose={handleCloseSalesOrgFilter}
                        >
                            <MenuItem
                                onClick={() => false}
                            >
                                &#8288;
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onInput={handleFilteringColumns(key)}
                                    label="Sales Organization Filter"
                                    placeholder='Type and search Sales Organization'
                                    variant="outlined"
                                    value={checkFilterValue(appliedFiltersValues.salesorganization)}
                                >
                                </TextField>
                                <IconButton
                                    onClick={handleClearFilter(key)}
                                    edge="end"
                                    color="error"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )
        }
        if (key === "distributionchannel") {
            return (
                <>
                    <Grid xs={1}>
                        <IconButton
                            onClick={handleOpenDistrChannelFilter}
                            edge="end"
                            color={checkCurrentAppliedFilter(key)}
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <Menu
                            anchorEl={distrChannelAnchorElement}
                            open={openDistrChannelFilter}
                            onClose={handleCloseDistrChannelFilter}
                        >
                            <MenuItem
                                onClick={() => false}
                            >
                                &#8288;
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onInput={handleFilteringColumns(key)}
                                    label="Distribution Channel Filter"
                                    placeholder='Type and search Distribution Channel'
                                    variant="outlined"
                                    value={checkFilterValue(appliedFiltersValues.distributionchannel)}
                                >
                                </TextField>
                                <IconButton
                                    onClick={handleClearFilter(key)}
                                    edge="end"
                                    color="error"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )
        }
        if (key === "division") {
            return (
                <>
                    <Grid xs={1}>
                        <IconButton
                            onClick={handleOpenDivisionFilter}
                            edge="end"
                            color={checkCurrentAppliedFilter(key)}
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <Menu
                            anchorEl={divisionAnchorElement}
                            open={openDivisionFilter}
                            onClose={handleCloseDivisionFilter}
                        >
                            <MenuItem
                                onClick={() => false}
                            >
                                &#8288;
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onInput={handleFilteringColumns(key)}
                                    label="Division Filter"
                                    placeholder='Type and search Division'
                                    variant="outlined"
                                    value={checkFilterValue(appliedFiltersValues.division)}
                                >
                                </TextField>
                                <IconButton
                                    onClick={handleClearFilter(key)}
                                    edge="end"
                                    color="error"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )
        }
        if (key === "materialdescription") {
            return (
                <>
                    <Grid xs={1}>
                        <IconButton
                            onClick={handleOpenMaterialFilter}
                            edge="end"
                            color={checkCurrentAppliedFilter(key)}
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <Menu
                            anchorEl={materialAnchorElement}
                            open={openMaterialFilter}
                            onClose={handleCloseMaterialFilter}
                        >
                            <MenuItem
                                onClick={() => false}
                            >
                                &#8288;
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onInput={handleFilteringColumns(key)}
                                    label="Material Description Filter"
                                    placeholder='Type and search Material Description'
                                    variant="outlined"
                                    value={checkFilterValue(appliedFiltersValues.materialdescription)}
                                >
                                </TextField>
                                <IconButton
                                    onClick={handleClearFilter(key)}
                                    edge="end"
                                    color="error"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )
        }
        if (key === "materialnumber") {
            return (
                <>
                    <Grid xs={1}>
                        <IconButton
                            onClick={handleOpenMaterialNumberFilter}
                            edge="end"
                            color={checkCurrentAppliedFilter(key)}
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <Menu
                            anchorEl={materialNumberAnchorElement}
                            open={openMaterialNumberFilter}
                            onClose={handleCloseMaterialNumberFilter}
                        >
                            <MenuItem
                                onClick={() => false}
                            >
                                &#8288;
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onInput={handleFilteringColumns(key)}
                                    label="Material Number Filter"
                                    placeholder='Type and search Material Number'
                                    variant="outlined"
                                    value={checkFilterValue(appliedFiltersValues.materialnumber)}
                                >
                                </TextField>
                                <IconButton
                                    onClick={handleClearFilter(key)}
                                    edge="end"
                                    color="error"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )
        }
        if (key === "plant") {
            return (
                <>
                    <Grid xs={1}>
                        <IconButton
                            onClick={handleOpenPlantFilter}
                            edge="end"
                            color={checkCurrentAppliedFilter(key)}
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <Menu
                            anchorEl={plantAnchorElement}
                            open={openPlantFilter}
                            onClose={handleClosePlantFilter}
                        >
                            <MenuItem
                                onClick={() => false}
                            >
                                &#8288;
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onInput={handleFilteringColumns(key)}
                                    label="Plant Filter"
                                    placeholder='Type and search Plant'
                                    variant="outlined"
                                    value={checkFilterValue(appliedFiltersValues.plant)}
                                >
                                </TextField>
                                <IconButton
                                    onClick={handleClearFilter(key)}
                                    edge="end"
                                    color="error"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )
        }
        if (key === "countryofdeliveringplant") {
            return (
                <>
                    <Grid xs={1}>
                        <IconButton
                            onClick={handleOpenCountryOfPlantFilter}
                            edge="end"
                            color={checkCurrentAppliedFilter(key)}
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <Menu
                            anchorEl={countryOfPlantAnchorElement}
                            open={openCountryOfPlantFilter}
                            onClose={handleCloseCountryOfPlantFilter}
                        >
                            <MenuItem
                                onClick={() => false}
                            >
                                &#8288;
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onInput={handleFilteringColumns(key)}
                                    label="Country of delivery Plant Filter"
                                    placeholder='Type and search Country of delivery plant'
                                    variant="outlined"
                                    value={checkFilterValue(appliedFiltersValues.countryofdeliveringplant)}
                                >
                                </TextField>
                                <IconButton
                                    onClick={handleClearFilter(key)}
                                    edge="end"
                                    color="error"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )
        }
        if (key === "incoterms") {
            return (
                <>
                    <Grid xs={1}>
                        <IconButton
                            onClick={handleOpenIncotermsFilter}
                            edge="end"
                            color={checkCurrentAppliedFilter(key)}
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <Menu
                            anchorEl={incotermsAnchorElement}
                            open={openIncotermsFilter}
                            onClose={handleCloseIncotermsFilter}
                        >
                            <MenuItem
                                onClick={() => false}
                            >
                                &#8288;
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onInput={handleFilteringColumns(key)}
                                    label="Incoterms Filter"
                                    placeholder='Type and search Incoterms'
                                    variant="outlined"
                                    value={checkFilterValue(appliedFiltersValues.incoterms)}
                                >
                                </TextField>
                                <IconButton
                                    onClick={handleClearFilter(key)}
                                    edge="end"
                                    color="error"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )
        }
        if (key === "incotermsdescription") {
            return (
                <>
                    <Grid xs={1}>
                        <IconButton
                            onClick={handleOpenIncotermsDescFilter}
                            edge="end"
                            color={checkCurrentAppliedFilter(key)}
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <Menu
                            anchorEl={incotermsDescAnchorElement}
                            open={openIncotermsDescFilter}
                            onClose={handleCloseIncotermsDescFilter}
                        >
                            <MenuItem
                                onClick={() => false}
                            >
                                &#8288;
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    onInput={handleFilteringColumns(key)}
                                    label="Incoterms Description Filter"
                                    placeholder='Type and search Incoterms Description'
                                    variant="outlined"
                                    value={checkFilterValue(appliedFiltersValues.incotermsdescription)}
                                >
                                </TextField>
                                <IconButton
                                    onClick={handleClearFilter(key)}
                                    edge="end"
                                    color="error"
                                >
                                    <ClearIcon />
                                </IconButton>
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )
        }
        return null;
    }

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12}>
                        <h4>Please select a master Data to fetch information to work with:</h4>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            maxMenuHeight={240}
                            menuPlacement="bottom"
                            options={masterDataLists}
                            placeholder="Master Data Select"
                            onChange={handleOnChangeSelect}
                            style={{ width: '100%' }}
                            value={selectedMasterDataCollection
                                ? { label: selectedMasterDataCollection, value: selectedMasterDataCollection }
                                : null}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    justifyContent="space-evenly"
                >
                    <Grid item>
                        <Tooltip
                            title={
                                <b>Refresh List</b>
                            }
                            arrow
                            placement="top"
                        >
                            <IconButton>

                                <RefreshIcon
                                    fontSize="large"
                                    onClick={refreshData}
                                    color='black'
                                />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip
                            title={
                                <b>Add Record</b>
                            }
                            arrow
                            placement="top"
                        >
                            <IconButton>

                                <AddCircleOutlineIcon
                                    fontSize="large"
                                    onClick={handleOpenUploadModal}
                                    color='black'
                                />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip
                            title={
                                <b>Upload CSV</b>
                            }
                            arrow
                            placement="top"
                        >
                            <IconButton>
                                <CloudUploadIcon
                                    fontSize="large"
                                    onClick={handleOpenCSVModal}
                                    color='black'
                                />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip
                            title={
                                <b>Download CSV</b>
                            }
                            arrow
                            placement="top"
                        >
                            <IconButton>
                                <CloudDownloadIcon
                                    fontSize="large"
                                    onClick={handleAPICallDownloadCSV}
                                    color='black'
                                />
                                {loadingFile && (
                                    <CircularProgress size={20} />
                                )}
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}
                    style={{
                        alignItems: "center"
                    }}>
                    <Table aria-label="customized table"
                        style={{
                            alignItems: "center"
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                {!_.isEmpty(currentElementKeys)
                                    ? currentElementKeys.map((key, index) => {
                                        return (
                                            <StyledTableCell index={key + index} style={{ textAlign: "center" }}>
                                                <Grid container alignItems="center" justifyContent="center">
                                                    <Grid item xs={11}>
                                                        <b >{headerParser(key)}</b>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        {checkFilters(key)}
                                                    </Grid>
                                                </Grid>
                                            </StyledTableCell>
                                        )
                                    })
                                    : null}
                                <StyledTableCell
                                    style={{
                                        justifyContent: "center",
                                        textAlign: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <b>Order Actions</b>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && !_.isEmpty(filteredData) && (
                                filteredData.map((excelValue, index) => {
                                    return (
                                        <StyledTableRow key={excelValue + index}>
                                            {currentElementKeys.map((currentKey, innerIndex) => {
                                                return (
                                                    <StyledTableCell
                                                        index={innerIndex}
                                                        style={{
                                                            justifyContent: "center",
                                                            textAlign: "center",
                                                            alignItems: "center"
                                                        }}
                                                    >
                                                        {excelValue[currentKey]}
                                                    </StyledTableCell>
                                                )
                                            })}
                                            <StyledTableCell

                                                style={{
                                                    justifyContent: "center",
                                                    textAlign: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <Grid container spacing={1} direction="row" flexWrap="nowrap">
                                                    <Grid item>
                                                        <Tooltip
                                                            title={
                                                                <b>View</b>
                                                            }
                                                            arrow
                                                            placement="top"
                                                        >
                                                            <IconButton
                                                                //onClick={handleOpenTIDValue(excelValue[currentKey])}
                                                                edge="end"
                                                                color="primary"
                                                            >
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip
                                                            title={
                                                                <b>Update</b>
                                                            }
                                                            arrow
                                                            placement="top"
                                                        >
                                                            <IconButton
                                                                onClick={handleOpenEditModal(excelValue["id"])}
                                                                edge="end"
                                                                color="primary"
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip
                                                            title={
                                                                <b>Delete</b>
                                                            }
                                                            arrow
                                                            placement="top"
                                                        >
                                                            <IconButton
                                                                onClick={handleDelete(excelValue["id"])}
                                                                edge="end"
                                                                color="error"
                                                            >
                                                                <ClearIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })
                            )}
                        </TableBody>
                    </Table>
                    {checkIfLoading()}
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                <Grid
                    container
                    justifyContent="center"
                    paddingTop={3}
                    paddingBottom={5}
                >
                    {<Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        showFirstButton
                        showLastButton
                        siblingCount={5}
                    />}
                </Grid>
            </Grid>
            {
                openUploadModal && (
                    <UploadMasterDataConfig
                        openModal={openUploadModal}
                        handleCloseModal={handleCloseUploadModal}
                        originalColumns={HOME_COLUMN_HEADERS}
                        refreshData={refreshData}
                        selectedMasterDataCollection={selectedMasterDataCollection}
                    />
                )
            }
            {
                (openEditModal && selectedId && selectedOrder) && (
                    <EditMasterDataConfig
                        openModal={openEditModal}
                        handleCloseModal={handleCloseEditModal}
                        originalColumns={HOME_COLUMN_HEADERS}
                        refreshData={refreshData}
                        selectedId={selectedId}
                        selectedOrder={selectedOrder}
                        selectedMasterDataCollection={selectedMasterDataCollection}
                    />
                )
            }
            {openUploadCSV && (
                <UploadCSVModal
                    openModal={openUploadCSV}
                    handleCloseModal={handleCloseUploadCSV}
                    handleAPICall={uploadCSVApiCall}
                    selectedMasterDataCollection={selectedMasterDataCollection}
                />
            )}
        </Grid >
    )
};

export default MasterDataConfig;
