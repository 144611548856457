import React, { useEffect } from 'react'
import WebFont from 'webfontloader';

import {
    Grid, Typography, Button, Card, CardContent, CardActions, Avatar
} from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';

const CALENDLY_URL = "https://calendly.com/gagan-zp4/30min"

const REVIEWS = [
    {
        "title": 'David W. - Supply Chain Director.',
        "message": '"VAO revolutionized our supply chain with unmatched speed and accuracy, seamless integration, and invaluable insights into customer ordering patterns."'
    },
    {
        "title": 'Emily T. - Customer Service Representative.',
        "message": '"VAO simplified order processing, empowering our team with control, real-time insights, and seamless multilingual support, making customer service a breeze."'
    },
    {
        "title": 'Alex M. - IT Manager.',
        "message": '"Integrating VAO into our systems was a breeze! Its seamless API made the process fast and effortless, saving us valuable IT resources."'
    },
]

const ContactUsSuccessPage = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const handleOnClick = () => {
        window.open(CALENDLY_URL, '_blank');
    }

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Montserrat:300,400,500,700', 'Saira:300,400,500,700', 'sans-serif'],
            },
        });
    }, []);

    return (
        <>
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={10}>
                    <Grid item xs={12}>
                        <Grid container
                            style={{
                                backgroundColor: "black",
                                //height: '13vh',
                                border: 1,
                                borderTopRightRadius: '60px',
                                borderBottomRightRadius: '60px'
                            }}
                            justifyContent="center"
                            alignItems="center"
                            padding={5}

                        >
                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h4"
                                            textAlign="center"
                                            style={{
                                                fontFamily: 'Montserrat, sans-serif',
                                                color: "white"
                                            }}
                                        >
                                            <b>
                                                Congratulations on claiming your free guide!
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h4"
                                            textAlign="center"
                                            style={{
                                                fontFamily: 'Montserrat, sans-serif',
                                                color: "white"
                                            }}
                                        >
                                            <b style={{
                                                fontFamily: 'Montserrat, sans-serif',
                                            }}>
                                                You'll recieve it in your inbox shortly.
                                            </b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    padding={5}
                    spacing={10}
                >
                    <Grid item xs={12} md={6}>
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item>
                                <Avatar style={{
                                    position: "relative",
                                    top: "40px",
                                    zIndex: 1000,
                                    border: "solid",
                                }} alt="Stephen Congo"
                                    src="https://vao-open-bucket.s3.amazonaws.com/landing-page/Stephen-Congo-pic.png"
                                    sx={{ width: 100, height: 100 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Card
                                    style={{
                                        width: "100%"
                                    }}
                                    sx={{ borderRadius: '30px' }}
                                >

                                    <CardContent
                                        style={{
                                            background: "#04a7eb"
                                        }}
                                    >
                                        <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            padding={isSmallScreen ? 2 : 4}
                                            paddingBottom={0}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                paddingBottom={5}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    textAlign="center"
                                                    style={{
                                                        fontFamily: 'Montserrat, sans-serif',
                                                        color: "white"
                                                    }}
                                                >
                                                    "We recieved a lot of orders via email... We needed a technology to help us gain some efficiency... VAO is the perfect tool for that!"
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h6"
                                                    textAlign="center"
                                                    style={{
                                                        fontFamily: 'Montserrat, sans-serif',
                                                        color: "white"
                                                    }}
                                                >
                                                    <b>Stephen Congo</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h6"
                                                    textAlign="center"
                                                    style={{
                                                        fontFamily: 'Montserrat, sans-serif',
                                                        color: "white"
                                                    }}
                                                >
                                                    <b>North America Customer Care Manager</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <img
                                                    src={'https://vao-open-bucket.s3.amazonaws.com/landing-page/Solenis-logo.png'}
                                                    style={{
                                                        maxWidth: isSmallScreen ? "50%" : "60%",
                                                        height: "auto"
                                                    }}
                                                    alt="VAO Logo"
                                                >
                                                </img>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions
                                        style={{
                                            background: "#2b4040",
                                            padding: 15,
                                            justifyContent: "center"
                                        }}
                                    >
                                        <img
                                            src={'https://vao-open-bucket.s3.amazonaws.com/landing-page/Stars.png'}
                                            style={{
                                                maxWidth: isSmallScreen ? "50%" : "60%",
                                                height: "auto"
                                            }}
                                            alt="VAO Logo"
                                        >
                                        </img>
                                    </CardActions>
                                </Card >
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    textAlign="center"
                                    style={{
                                        fontFamily: 'Montserrat, sans-serif',
                                        color: "black"
                                    }}
                                >
                                    Feel free to schedule a
                                    <br />
                                    <b>Free 30 minutes Consultation</b>
                                    <br />
                                    with an Order Management expert here:
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="info"
                                    size="large"
                                    onClick={handleOnClick}
                                >
                                    <b>
                                        Secure your spot NOW!
                                    </b>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} paddingTop={15}>
                <Grid
                    container
                    spacing={5}
                    padding={isSmallScreen ? 2 : 12}
                    paddingTop={0}
                    style={{
                        background: 'linear-gradient(180deg, rgba(2,225,226,1) 0%, rgba(0,121,255,1) 100%)'
                    }}
                >
                    <Grid item xs={12}>
                        <Typography
                            variant="h3"
                            textAlign="center"
                            style={{
                                fontFamily: 'Montserrat, sans-serif',
                                color: "white"
                            }}
                        >
                            <b>
                                Users love VAO
                            </b>
                        </Typography>
                    </Grid>
                    {REVIEWS.map((element => {
                        return (
                            <Grid item xs={12}>
                                <Card
                                    style={{
                                        width: "100%"
                                    }}
                                    sx={{
                                        borderTopRightRadius: '40px',
                                        borderTopLeftRadius: '40px',
                                        borderBottomRightRadius: '40px'
                                    }}
                                >
                                    <CardContent
                                        style={{
                                            background: "white"
                                        }}
                                    >
                                        <Grid
                                            container
                                            alignItems="center"
                                            padding={isSmallScreen ? 2 : 2}
                                            paddingBottom={0}
                                        >
                                            <Grid
                                                item
                                                textAlign="left"
                                                paddingBottom={2}
                                            >
                                                <img
                                                    src={'https://vao-open-bucket.s3.amazonaws.com/landing-page/Stars.png'}
                                                    style={{
                                                        maxWidth: isSmallScreen ? "50%" : "60%",
                                                        height: "auto"
                                                    }}
                                                    alt="VAO Logo"
                                                >
                                                </img>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h6"
                                                    textAlign="left"
                                                    style={{
                                                        fontFamily: 'Montserrat, sans-serif',
                                                        color: "black"
                                                    }}
                                                >
                                                    <b>{element.title}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h6"
                                                    textAlign="left"
                                                    style={{
                                                        fontFamily: 'Montserrat, sans-serif',
                                                        color: "black"
                                                    }}
                                                >
                                                    {element.message}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card >
                            </Grid>
                        )
                    }))}
                </Grid>
            </Grid>
        </>
    )
}
export default ContactUsSuccessPage;