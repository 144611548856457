import React, { useState, useEffect } from 'react'

import {
    Grid, Drawer, Divider
} from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useMediaQuery from '@mui/material/useMediaQuery';

import StepperRenderer from './Stepper';
import ToolbarTable from './Toolbar'
import FormSignupComponent from "./Signup/FormSignupComponent";
import FormMailboxComponent from "./Configuration/FormMailboxComponent";
import LoadOrderDataTable from "./LoadOrderData/LoadOrderDataTable";

const InnerMessages = [
    { step: 1, message: 'Process thousands of purchase orders' },
    { step: 1, message: 'Monitor multiple mailboxes' },
    { step: 1, message: 'High accuracy' },
    { step: 1, message: 'Powered by top tech companies' },
    { step: 2, message: 'Easy outlook login' },
    { step: 2, message: 'Grant VAO Access' },
    { step: 3, message: 'VAO will pick purchase orders automatically' },
    { step: 3, message: 'Purchase Orders will be processed' },
    { step: 3, message: 'Processed Purchase Orders will appear on VAO Dashboard' },
]

const STEPS_LABEL = new Map([[1, 'SIGNUP'], [2, 'CONFIGURATION'], [3, 'LOAD ORDER DATA']]);

export const MainStepperTemplate = () => {

    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const DRAWER_WIDTH = isSmallScreen ? '60%' : '20%';
    const { id } = useParams();

    const [currentStep, setCurrentStep] = useState(null);
    const [currentComponent, setCurrentComponent] = useState(null);
    const [token, setToken] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(isSmallScreen);

    const checkedCurrentStep = (currentStep) => {
        switch (currentStep) {
            case 1:
                return <FormSignupComponent
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    setToken={setToken}
                />
            case 2:
                return <FormMailboxComponent
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    token={token}
                />
            case 3:
                return <LoadOrderDataTable
                    currentStep={currentStep}
                    token={token}
                />
            default:
                return null
        }
    }

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    useEffect(() => {
        setCurrentComponent(checkedCurrentStep(currentStep))
    }, [currentStep])

    useEffect(() => {
        const parsedIdValue = Number(id.replace(':', ''))
        setCurrentStep(parsedIdValue);
    }, [id])

    return (
        <Grid container minHeight='100%' justifyContent='space-evenly'>
            <Grid item xs={12}>
                <ToolbarTable
                    currentStep={currentStep}
                    toggleDrawer={toggleDrawer}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems='center'>
                    <Grid item >
                        <Drawer
                            sx={{
                                width: DRAWER_WIDTH,
                                flexShrink: 0,
                                '& .MuiDrawer-paper': {
                                    width: DRAWER_WIDTH,
                                    boxSizing: 'border-box',
                                    backgroundColor: '#f5f5f5',
                                },
                            }}
                            variant={isSmallScreen ? 'temporary' : 'permanent'}
                            open={drawerOpen}
                            onClose={toggleDrawer}
                            anchor="left"
                        >
                            <Grid container paddingTop={9} spacing={2}>
                                <Grid item xs={12} style={{
                                    backgroundColor: '#e0e0e0'
                                }}>
                                    <Grid container paddingTop={6} paddingBottom={6} alignItems='center'>
                                        <Grid item xs={12}>
                                            <h2>Create your account</h2>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{
                                    backgroundColor: '#f5f5f5',
                                    textAlign: 'left'
                                }}>
                                    <Grid container paddingTop={2} spacing={2} justifyContent='flex-start'>
                                        {InnerMessages.map((element) => {
                                            if (element.step === currentStep) {
                                                return (
                                                    <>
                                                        <Grid item xs={1}>
                                                            <CheckCircleIcon />
                                                        </Grid>
                                                        <Grid item xs={11} style={{
                                                            fontSize: '17px'
                                                        }}>
                                                            {element.message}
                                                        </Grid>
                                                    </>
                                                )
                                            }

                                        })}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container alignItems="center" justifyContent='space-around'>
                                        {currentStep === 1 && (
                                            <>
                                                <Grid item xs={6}>
                                                    <img
                                                        src={'https://vao-open-bucket.s3.amazonaws.com/landing-page/aws.webp'}
                                                        style={{
                                                            maxWidth: "40%",
                                                            height: "auto"
                                                        }}
                                                        alt="AWS Logo"
                                                    >
                                                    </img>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <img
                                                        src={'https://vao-open-bucket.s3.amazonaws.com/landing-page/mongodb.png'}
                                                        style={{
                                                            maxWidth: "40%",
                                                            height: "auto"
                                                        }}
                                                        alt="MongoDB Logo"
                                                    >
                                                    </img>
                                                </Grid>
                                            </>
                                        )}
                                        {currentStep === 2 && (
                                            <>
                                                <Grid item xs={12}>
                                                    1.
                                                    <img
                                                        src={'https://vao-open-bucket.s3.amazonaws.com/landing-page/email.png'}
                                                        style={{
                                                            maxWidth: "55%",
                                                            height: "auto"
                                                        }}
                                                        alt="Outlook login"
                                                    >
                                                    </img>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    2.
                                                    <img
                                                        src={'https://vao-open-bucket.s3.amazonaws.com/landing-page/email1.png'}
                                                        style={{
                                                            maxWidth: "55%",
                                                            height: "auto"
                                                        }}
                                                        alt="Outlook permits"
                                                    >
                                                    </img>
                                                </Grid>
                                            </>
                                        )}
                                        {currentStep === 3 && (
                                            <>
                                                <Grid item xs={12}>
                                                    <img
                                                        src={'https://vao-open-bucket.s3.amazonaws.com/landing-page/email3.png'}
                                                        style={{
                                                            maxWidth: "90%",
                                                            height: "auto"
                                                        }}
                                                        alt="Outlook mailbox"
                                                    >
                                                    </img>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Drawer>
                    </Grid>
                    <Grid item paddingLeft={isSmallScreen ? 0 : DRAWER_WIDTH} marginLeft={5} paddingTop={15} >
                        <Grid container>
                            <Grid item xs={12}>
                                <StepperRenderer activeStep={currentStep - 1} />
                            </Grid>
                            <Grid item xs={12}>
                                {/*INSIDE COMPONENT, CONDITIONAL RENDERING*/}
                                <Divider sx={{ marginTop: 5, fontSize: 35 }}>{STEPS_LABEL.get(currentStep) || null}</Divider>
                                {currentComponent}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default MainStepperTemplate