import React, { useState, useEffect } from 'react'

import { AppBar, Container, Toolbar, Grid, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import SignUpGuidedTourComponent from '../SOPHome/GuidedTour/SignUpGuidedTour';
import useMediaQuery from '@mui/material/useMediaQuery';

const ToolbarTable = ({ currentStep, toggleDrawer }) => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();

    const [vaoLogo, setVaoLogo] = useState("https://vao-open-bucket.s3.amazonaws.com/landing-page/gray-logo-vao.png");

    const fetchImageDS1 = async () => {
        setVaoLogo("https://vao-open-bucket.s3.amazonaws.com/landing-page/gray-logo-vao.png");
    }

    const goToLogin = () => {
        navigate("/login")
    }

    const goToHome = () => {
        navigate("/middlePage")
    }

    useEffect(() => {
        fetchImageDS1();
    }, []);

    return (
        <AppBar position="fixed"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            style={{
                backgroundColor: '#f5f5f5',
                color: 'black',
                borderColor: 'black',
                borderBottom: 'solid',
            }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Grid container alignItems='center' justifyContent='space-between'>
                        {isSmallScreen && (
                            <Grid item xs={1}>
                                <Button
                                    variant="text"
                                    color="info"
                                    onClick={toggleDrawer}
                                >
                                    <MenuIcon />
                                </Button>
                            </Grid>
                        )}
                        <Grid item xs={1}>
                            <Button
                                variant="text"
                                color="info"
                                onClick={goToHome}
                            >
                                <img
                                    src={vaoLogo}
                                    style={{
                                        maxWidth: "70%",
                                        height: "auto"
                                    }}
                                    alt="VAO Logo"
                                >
                                </img>
                            </Button>
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container justifyContent={isSmallScreen ? '' : 'space-evenly'} alignItems='center'>
                                {currentStep < 2 && (
                                    <>
                                        <Grid item xs={6}>
                                            <b>Already have an account?</b>
                                        </Grid>
                                        <Grid item >
                                            <Button
                                                variant="text"
                                                color="info"
                                                onClick={goToLogin}
                                            >
                                                <b>
                                                    LOGIN
                                                </b>
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                                {currentStep > 1 && (
                                    <Grid item>
                                        <Button
                                            variant="text"
                                            color="info"
                                            onClick={goToHome}
                                        >
                                            <b>
                                                HOME
                                            </b>
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item xs={1}>
                                    <b>-</b>
                                </Grid>
                                <Grid item xs={1}>
                                    <SignUpGuidedTourComponent />
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default ToolbarTable