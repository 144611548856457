import _ from 'lodash';
import React, { useState } from 'react'
import {
    Modal, Typography, Card, CardContent,
    CardActions, Button, Grid, TextField,
    InputAdornment, CardHeader,
    FormGroup, FormControlLabel, Checkbox
} from '@mui/material';

import { createRecord, updateRecord } from '../../../../../APICalls';
import { useEffect } from 'react';
import { STORAGE_KEYS } from '../../../../../helpers/helpers';

const REMOVE_FIELDS = ["clientId", "clientName", "createdBy", "modifiedBy"]

const EditMasterDataConfig = ({
    openModal, handleCloseModal, originalColumns,
    refreshData, selectedId, selectedOrder, selectedMasterDataCollection
}) => {

    const [token, setToken] = useState(null);
    const [fieldErrors, setFieldErrors] = useState(false)
    const [registerData, setRegisterData] = useState(selectedOrder);
    const [succesfully, setSuccesfully] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const checkedFieldRemove = (element) => {
        return REMOVE_FIELDS.includes(element)
    }

    const isEmptyData = () => {
        return (Object.values(registerData).some((element) => element === ""))
    }

    const handleFillData = (field) => (event) => {
        setRegisterData({
            ...registerData,
            [field]: event.target.value.trim()
        })
    }

    const callUpdateRecordAPI = async () => {
        const {
            shiptoaddress,
            shiptoparty,
            soldtoparty,
            salesorganization,
            distributionchannel,
            division,
            materialdescription,
            materialnumber,
            plant,
            countryofdeliveringplant,
            incoterms,
            incotermsdescription,
        } = registerData
        const response = await updateRecord(token, selectedId, shiptoaddress, shiptoparty, soldtoparty,
            salesorganization, distributionchannel, division, materialdescription,
            materialnumber, plant, countryofdeliveringplant, incoterms,
            incotermsdescription, selectedMasterDataCollection)
        if (response === "Successful") {
            setSuccesfully(true);
            setErrorMessage(null);
            refreshData();
        } else {
            const { message } = response
            setErrorMessage(message)
            setSuccesfully(false);
        }
    }

    const handleEditData = () => {
        setSuccesfully(false);
        setErrorMessage(null);
        if (isEmptyData()) {
            setFieldErrors(true);
            return;
        } else {
            callUpdateRecordAPI();
        }
    }

    useEffect(() => {
        const tokenData = localStorage.getItem(STORAGE_KEYS.TOKEN);
        setToken(tokenData);
    }, [])

    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            style={{
                position: 'absolute',
                overflow: 'scroll',
                height: '100%',
                display: 'grid'
            }}
        >
            <Grid
                container
                justifyContent="center"
                flexDirection="row"
            >
                <Card
                    style={{
                        width: "95%",
                    }}
                >
                    <CardActions
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            color='error'
                            onClick={handleCloseModal}
                        >
                            X
                        </Button>
                    </CardActions>
                    <CardContent>
                        <Typography
                            variant="h4"
                            textAlign="center"
                        >
                            Edit current Master Data
                        </Typography>
                        <Grid
                            container
                            justifyContent="center"
                            textAlign="center"
                            paddingTop={5}
                        >
                            <Grid item>
                                <h4>Please fill ALL the below fields:</h4>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2} alignItems="center" paddingTop={2}>
                                    {originalColumns.map((element) => {
                                        if (checkedFieldRemove(element)) {
                                            return null;
                                        }
                                        return <Grid item xs={6}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={3}>
                                                    {element}:
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField
                                                        fullWidth
                                                        onInput={handleFillData(element)}
                                                        label={element}
                                                        variant="outlined"
                                                        error={fieldErrors && registerData[element] === ''}
                                                        helperText={fieldErrors && registerData[element] === '' ? "Missing field." : ""}
                                                        value={registerData[element]}
                                                    >
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        {errorMessage && (
                            <Grid item xs={12}>
                                <h5 style={{
                                    color: "#db5757"
                                }}>
                                    Error when updating Record: {errorMessage}
                                </h5>
                            </Grid>
                        )}
                        {succesfully && (
                            <Grid item xs={12}>
                                <h5 style={{
                                    color: "green"
                                }}>
                                    Updated succesfully!
                                </h5>
                            </Grid>
                        )}
                        <Button
                            size="medium"
                            variant="contained"
                            color='info'
                            onClick={handleEditData}
                        >
                            Create Record
                        </Button>
                    </CardActions>
                </Card >
            </Grid>
        </Modal >
    )
}

export default EditMasterDataConfig;
