import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import {
    Modal, Typography, Card, CardContent,
    CardActions, Button, Grid, AppBar, Toolbar
} from '@mui/material';

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

import { logoutAPI } from './APICalls';

const InactivityModal = ({
    openModal, handleCloseModal, token
}) => {
    const [timeRemaining, setTimeRemaining] = useState(600);

    const navigate = useNavigate();
    const handleInactivityCall = async () => {
        await logoutAPI(token)
            .then((response) => {
                const { status } = response
                if (status === "SUCCESS") {
                    navigate("/login")
                }
            });
    }

    useEffect(() => {
        let timerId;
        timerId = setInterval(() => {
            setTimeRemaining(prev => prev - 1);
        }, 1000);
        if (timeRemaining === 0) {
            clearInterval(timerId);
        }

        return function cleanup() {
            clearInterval(timerId);
        };
    }, []);

    const convertSecondsToMinutes = () => {
        const minutes = Math.floor(timeRemaining / 60);
        const seconds = timeRemaining - minutes * 60;
        const parsedSeconds = seconds >= 10 ? seconds : `0${seconds}`
        return `${minutes}:${parsedSeconds}`
    }

    useEffect(() => {
        if (timeRemaining === 0) {
            handleInactivityCall();
        }
    }, [timeRemaining]);

    return (
        <Modal
            open={openModal}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
            }}
        >
            <Grid
                container
                justifyContent="center"
                flexDirection="row"
            >
                <Card
                    style={{
                        width: "60%",
                    }}
                >
                    <AppBar position="relative">
                        <Toolbar className="toolbarStyle">
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <h2>
                                        Are you still there?
                                    </h2>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <CardContent>
                        <Grid
                            container
                            justifyContent="center"
                            padding={2}
                            spacing={5}
                        >
                            <Grid item>
                                <Typography
                                    variant="h4"
                                    textAlign="center"
                                >
                                    <b>
                                        We have detected a long inactivity period and your session is about to expire.
                                    </b>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <AccessTimeFilledIcon
                                            fontSize='large'
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="h4"
                                            textAlign="center"
                                        >
                                            Time remaining:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="h4"
                                            textAlign="center"
                                        >
                                            {convertSecondsToMinutes()}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            size="medium"
                            variant="contained"
                            color='info'
                            onClick={handleCloseModal}
                        >
                            Continue Session
                        </Button>
                    </CardActions>
                </Card >
            </Grid>
        </Modal >
    )
}

export default InactivityModal;
