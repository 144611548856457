import _ from "lodash";

import React, { useState } from 'react'
import {
    Modal, Typography, Card, CardContent, Grid,
    Alert, Button, CardActions
} from '@mui/material';

const ModalOutlook = ({
    openModal, handleCloseModal, navigateNextPage
}) => {

    const [clickedOpen, setClickedOpen] = useState(false);

    const openValidationPage = () => {
        setClickedOpen(true);
        window.open(`https://latm-outlook.vaoportal.world/login`, '_blank')
    }

    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
            }}
        >
            <Grid
                container
                justifyContent="center"
                flexDirection="row"
            >
                <Card
                    style={{
                        width: "80%",
                    }}
                >
                    <CardContent>
                        <Typography
                            variant="h4"
                            textAlign="center"
                        >
                            Hook Outlook to VAO
                        </Typography>
                        <br />
                        <Alert
                            action={
                                <Button
                                    variant="outlined"
                                    color="warning"
                                    onClick={openValidationPage}
                                >
                                    Click here and configure now!
                                </Button>
                            }
                            severity="warning">
                            <b>Would you like to connect your Outlook so we can fetch your emails and process them?</b>
                        </Alert>
                    </CardContent>
                    <CardActions
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            size="medium"
                            variant="contained"
                            color="success"
                            onClick={navigateNextPage}
                        >
                            I'll do it later
                        </Button>
                    </CardActions>
                </Card >
            </Grid>
        </Modal >
    )
}

export default ModalOutlook;
