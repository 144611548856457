import _ from 'lodash';

import {
    Grid, TextField, InputAdornment, IconButton,
    Button, FormGroup, FormControlLabel, Checkbox,
    Autocomplete, Box
} from '@mui/material'
import { useNavigate } from 'react-router-dom';

import PersonIcon from '@mui/icons-material/Person';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

import React, { useEffect, useState } from 'react'

import { STORAGE_KEYS } from "../../../../helpers/helpers";

import {
    createUserFirstStep, loginAPI, getUserClientInfoAPI, getPreFilledData,
    searchCountry, searchState, searchCity
} from "../../../../APICalls";

import VerificationEmailModal from './VerificationEmailModal';
import ErrorComponent from './ErrorComponent';

const FormSignupComponent = ({ currentStep, setCurrentStep }) => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [validationPassword, setValidationPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [openModalVerify, setopenModalVerify] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);


    const [agreedPrivacyPolicy, setAgreedPrivacyPolicy] = useState(false);

    const setCurrentCheckbox = () => {
        setAgreedPrivacyPolicy(!agreedPrivacyPolicy);
    }

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [markedAgree, setMarkedAgree] = useState(false);
    const [errorFieldsOnSubmit, setErrorFieldsOnSubmit] = useState({});

    const [registerData, setRegisterData] = useState({
        "firstName": "",
        "lastName": "",
        "email": "",
        "city": "",
        "country": "",
        "state": "",
        "zip": "",
        "password": "",
        "confirmPassword": "",
        "companyName": ""
    });
    const [fieldErrors, setFieldErrors] = useState(false)

    const isEmptyData = () => {
        const fieldsToEmpty = Object.keys(registerData).reduce((accum, key) => ({ ...accum, [key]: _.isEmpty(registerData[key]) }), {});
        setErrorFieldsOnSubmit(fieldsToEmpty);
        return (Object.values(registerData).some((element) => _.isEmpty(element)))
    }

    const callRegisterAPI = async () => {
        const { email, password, firstName, lastName, companyName, city, state, country, zip } = registerData;
        const response = await createUserFirstStep(email, password, firstName, lastName, companyName, city, state, country, zip)
        if (response === "Successful") {
            // await loginAfterRegister()
            setopenModalVerify(true)
        } else {
            const { message } = response
            setErrorMessage(message)
        }
    }

    const setLocalStorage = ({ access_token, user_type, user_id }) => {
        localStorage.setItem(STORAGE_KEYS.USER, registerData["email"]);
        localStorage.setItem(STORAGE_KEYS.TOKEN, access_token);
        localStorage.setItem(STORAGE_KEYS.LOGGED_IN, true);
        localStorage.setItem(STORAGE_KEYS.USER_TYPE, user_type);
        localStorage.setItem(STORAGE_KEYS.USER_ID, user_id);
        window.dispatchEvent(new Event("storage"));
    }

    const setLocalStorageClientInfo = ({ display_name, id, name, role_id }) => {
        localStorage.setItem(STORAGE_KEYS.COMPANY_NAME, display_name);
        localStorage.setItem(STORAGE_KEYS.ROLE_ID, role_id);
        localStorage.setItem(STORAGE_KEYS.ID, id);
        window.dispatchEvent(new Event("storage"));
    }

    const loginAfterRegister = async () => {
        const { email, password } = registerData;
        const validate = await loginAPI(email, password);
        if (validate.message) {
            navigate("/login");
        }

        const { access_token } = validate;

        if (access_token) {
            const getUserInfo = await getUserClientInfoAPI(access_token);
            if (!_.isNil(getUserInfo)) {
                const { display_name, id, name, role_id } = getUserInfo;
                setLocalStorageClientInfo({ display_name, id, name, role_id })
            }
            const { user_type, user_id } = validate;
            setLocalStorage({ access_token, user_id, user_type });
            if (currentStep === 2) {
                navigate("/home");
            }
            navigate("/register/:2")
        } else {
        }
    }

    const handleNextStep = () => {
        setErrorMessage(null);
        setMarkedAgree(false);
        if (isEmptyData()) {
            setFieldErrors(true);
            return;
        }
        if (!agreedPrivacyPolicy) {
            setMarkedAgree(true);
            return;
        }
        callRegisterAPI();
    }

    const handleCountryChange = (event, countryValue) => {
        if (_.isNil(countryValue)) {
            setSelectedCountry(null);
            setSelectedState(null);
            setSelectedCity(null);
            setRegisterData(prevData => ({
                ...prevData,
                "country": ""
            }));
            return;
        }
        setSelectedCountry(countryValue);
        setRegisterData(prevData => ({
            ...prevData,
            "country": countryValue.name
        }));
        setSelectedState(null);
        setSelectedCity(null);
    }

    const handleStateChange = (event, stateValue) => {
        if (_.isNil(stateValue)) {
            setSelectedState(null);
            setSelectedCity(null);
            setRegisterData(prevData => ({
                ...prevData,
                "state": ""
            }));
            return;
        }
        setSelectedState(stateValue);
        setRegisterData(prevData => ({
            ...prevData,
            "state": stateValue.name
        }));
        setSelectedCity(null);
    }

    const handleCityChange = (event, cityValue) => {
        if (_.isNil(cityValue)) {
            setSelectedCity(null);
            setRegisterData(prevData => ({
                ...prevData,
                "city": ""
            }));
            return;
        }
        setSelectedCity(cityValue);
        setRegisterData(prevData => ({
            ...prevData,
            "city": cityValue.name
        }));
    }

    const handleOpenPage = () => {
        window.open("https://www.vao.world/privacy", "_blank");
    }

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const handlePassword = (event) => {
        setPassword(event.target.value.trim());
        setRegisterData({
            ...registerData,
            "password": event.target.value.trim()
        })
    }

    const handleConfirmPassword = (event) => {
        setValidationPassword(event.target.value.trim());
        setRegisterData({
            ...registerData,
            "confirmPassword": event.target.value.trim()
        })
    }

    const handleAddRegisterData = (field) => (event) => {
        setRegisterData({
            ...registerData,
            [field]: event.target.value.trim()
        })
    }

    const handleOnCloseModal = () => {
        setopenModalVerify(false)
        navigate("/login");
    }

    const handleGetCountry = (country) => {
        handleSearchCountry(country);
    }

    const handleGetState = (state) => {
        if (!_.isNil(selectedCountry)) {
            handleSearchState(selectedCountry.id, state);
        }
    }

    const handleGetCity = (city) => {
        if (!_.isNil(selectedCountry) && !_.isNil(selectedState)) {
            handleSearchCity(selectedCountry.id, selectedState.id, city);
        }
    }

    const handleSearchCountry = async (countryValue) => {
        const { status, data } = await searchCountry(countryValue)
        if (status === 200) {
            setCountryList(data);
        }
    }

    const handleSearchState = async (countryId, stateValue) => {
        const { status, data } = await searchState(countryId, stateValue)
        if (status === 200) {
            setStateList(data);
        }
    }

    const handleSearchCity = async (countryId, stateId, cityValue) => {
        const { status, data } = await searchCity(countryId, stateId, cityValue)
        if (status === 200) {
            setCityList(data);
        }
    }

    const setSelectedCountryValue = (country) => {
        setSelectedCountry(country);
    }


    const validatePreRegisteredData = async () => {
        const localStorageValue = localStorage.getItem('tidContact');
        if (!_.isNil(localStorageValue) && localStorageValue !== "undefined") {
            const calledAPI = await getPreFilledData(localStorageValue)
            const { status, payload } = calledAPI;
            if (status === "SUCCESS") {
                if (payload) {
                    const { company, email, name } = payload
                    setRegisterData({
                        ...registerData,
                        'companyName': company,
                        email,
                        'firstName': name
                    })
                }
            }
        }
    }

    useEffect(() => {
        validatePreRegisteredData()
    }, [])

    return (
        <Grid container spacing={3} paddingTop={10} paddingBottom={10} paddingRight={6} className='signup-form'>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonIcon />
                            </InputAdornment>
                        ),
                    }}
                    onInput={handleAddRegisterData("firstName")}
                    label="First Name"
                    variant="outlined"
                    error={fieldErrors && registerData['firstName'] === ''}
                    value={registerData['firstName']}
                    helperText={fieldErrors && registerData[fieldErrors && 'firstName'] === '' ? "Missing field." : ""}
                    id='firstname'
                >
                </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonIcon />
                            </InputAdornment>
                        ),
                    }}
                    onInput={handleAddRegisterData("lastName")}
                    label="Last Name"
                    variant="outlined"
                    error={fieldErrors && registerData['lastName'] === ''}
                    value={registerData['lastName']}
                    helperText={fieldErrors && registerData['lastName'] === '' ? "Missing field." : ""}
                    id='lastname'
                >
                </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                        ),
                    }}
                    onInput={handleAddRegisterData("email")}
                    label="email"
                    variant="outlined"
                    error={fieldErrors && registerData['email'] === ''}
                    value={registerData['email']}
                    helperText={fieldErrors && registerData['email'] === '' ? "Missing field." : ""}
                    id='email'
                >
                </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
                <Autocomplete
                    id="country-select"
                    fullWidth
                    autoHighlight
                    value={selectedCountry}
                    size='large'
                    onChange={handleCountryChange}
                    onInputChange={(_event, value) => handleGetCountry(value)}
                    options={countryList}
                    getOptionLabel={(option) => {
                        // Check if option has an orderNumber
                        const emojiLabel = option.emoji || "";

                        // Check if option has customer email
                        const nameLabel = option.name || "";

                        // Concatenate orderNumber and soNumbers labels
                        return `${emojiLabel} ${nameLabel}`;
                    }}
                    renderOption={(propsSelect, option) => {
                        // Check if option has an orderNumber
                        const emojiLabel = option.emoji || "";

                        // Check if option has customer email
                        const nameLabel = option.name || "";
                        return (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...propsSelect}>
                                {`${emojiLabel} ${nameLabel}`}
                            </Box>
                        )
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder='Select Country'
                            inputProps={{
                                ...params.inputProps,
                            }}
                            value={selectedCountry}
                        />
                    )}
                />
                <ErrorComponent
                    show={errorFieldsOnSubmit?.country && _.isEmpty(registerData?.country)}
                    message="Choose a valid country."
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Autocomplete
                    id="country-select"
                    fullWidth
                    autoHighlight
                    value={selectedState}
                    size='large'
                    onChange={handleStateChange}
                    onInputChange={(_event, value) => handleGetState(value)}
                    options={stateList}
                    getOptionLabel={(option) => {

                        const nameLabel = option.name || "";

                        return `${nameLabel}`;
                    }}
                    renderOption={(propsSelect, option) => {

                        const nameLabel = option.name || "";

                        return (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...propsSelect}>
                                {`${nameLabel}`}
                            </Box>
                        )
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder='Select State'
                            inputProps={{
                                ...params.inputProps,
                            }}
                            value={selectedState}
                        />
                    )}
                />
                <ErrorComponent
                    show={errorFieldsOnSubmit?.state && _.isEmpty(registerData?.state)}
                    message="Choose a valid state."
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Autocomplete
                    id="country-select"
                    fullWidth
                    autoHighlight
                    value={selectedCity}
                    size='large'
                    onChange={handleCityChange}
                    onInputChange={(_event, value) => handleGetCity(value)}
                    options={cityList}
                    getOptionLabel={(option) => {

                        const nameLabel = option.name || "";

                        return `${nameLabel}`;
                    }}
                    renderOption={(propsSelect, option) => {

                        const nameLabel = option.name || "";

                        return (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...propsSelect}>
                                {`${nameLabel}`}
                            </Box>
                        )
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder='Select City'
                            inputProps={{
                                ...params.inputProps,
                            }}
                            value={selectedCity}
                        />
                    )}
                />
                <ErrorComponent
                    show={errorFieldsOnSubmit?.city && _.isEmpty(registerData?.city)}
                    message="Choose a valid city."
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    onChange={handlePassword}
                    error={fieldErrors && _.isEmpty(registerData['password'])}
                    helperText={fieldErrors && _.isEmpty(registerData['password']) ? "Missing field." : ""}
                    type={
                        showPassword
                            ? "text"
                            : "password"
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment
                                position="start"
                            >
                                <LockTwoToneIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword
                                        ? <VisibilityOffIcon />
                                        : <VisibilityIcon />
                                    }
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    id="confirmPassword"
                    label="Confirm Password"
                    name="confirmPassword"
                    onChange={handleConfirmPassword}
                    error={registerData['confirmPassword'] !== registerData['password']}
                    helperText={registerData['confirmPassword'] !== registerData['password'] ? "The password should be the same" : ""}
                    type={
                        showPassword
                            ? "text"
                            : "password"
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment
                                position="start"
                            >
                                <LockTwoToneIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword
                                        ? <VisibilityOffIcon />
                                        : <VisibilityIcon />
                                    }
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <ApartmentIcon />
                            </InputAdornment>
                        ),
                    }}
                    onInput={handleAddRegisterData("companyName")}
                    label="Company Name"
                    variant="outlined"
                    id='company'
                    error={fieldErrors && registerData['companyName'] === ''}
                    value={registerData['companyName']}
                    helperText={fieldErrors && registerData['companyName'] === '' ? "Missing field." : ""}
                >
                </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LocationOnIcon />
                            </InputAdornment>
                        ),
                    }}
                    onInput={handleAddRegisterData("zip")}
                    label="Zip"
                    variant="outlined"
                    id='zip'
                    error={fieldErrors && registerData['zip'] === ''}
                    helperText={fieldErrors && registerData['zip'] === '' ? "Missing field." : ""}
                    value={registerData['zip']}
                >
                </TextField>
            </Grid>
            <Grid item xs={12} alignSelf='flex-end' paddingTop={5} className='signup-last-tep'>
                <Grid container spacing={1}>
                    {errorMessage && (
                        <Grid item xs={12}>
                            <h5 style={{
                                color: "#db5757"
                            }}>
                                Error when creating user: {errorMessage}
                            </h5>
                        </Grid>
                    )}
                    <Grid item>
                        <Button
                            size="medium"
                            variant="contained"
                            color='info'
                            onClick={handleNextStep}
                        >
                            CONTINUE
                        </Button>
                    </Grid>
                    <Grid item>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={agreedPrivacyPolicy}
                                    onChange={setCurrentCheckbox}
                                    color="success"
                                    id="check"
                                />
                            } label={
                                <>
                                    <b>Agree to <a href="/Terms and Conditions.pdf" onClick={handleOpenPage} target="_blank" > Privacy policy / Terms and conditions</a></b>
                                    &nbsp;
                                    &nbsp;
                                </>} />
                            {(markedAgree) && (
                                <p style={{
                                    color: "#db5757"
                                }}>
                                    Please check and accept our privacy policy - terms and conditions.
                                </p>
                            )}
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>
            <VerificationEmailModal
                onClose={handleOnCloseModal}
                open={openModalVerify}
                email={registerData['email']}
            />
        </Grid>
    )
}

export default FormSignupComponent;