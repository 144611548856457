import React, { useEffect, useState } from 'react'
import {
  Card, CardContent, Grid,
} from '@mui/material';

const EmptyDataComponent = ({ noBelowMessage }) => {

  const [vaoLogo, setVaoLogo] = useState("https://vao-open-bucket.s3.amazonaws.com/landing-page/gray-logo-vao.png");

  const fetchImageDS1 = async () => {
    setVaoLogo("https://vao-open-bucket.s3.amazonaws.com/landing-page/gray-logo-vao.png");
  }

  useEffect(() => {
    fetchImageDS1();
  }, []);

  return (
    <Card>
      <Grid container justifyContent="center" alignItems="center">
        <CardContent>
          <Grid container justifyContent="center" spacing={0}>
            <Grid item xs={12}>
              <img
                src={vaoLogo}
                style={{
                  maxWidth: "20%",
                  height: "auto"
                }}
                alt="VAO Logo"
              >
              </img>
            </Grid>
            <Grid item xs={12}>
              <h3><b>No information to show now.</b></h3>
              {!noBelowMessage && (
                <h4>Please check your filters and try again.</h4>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Grid>
    </Card>

  )
}

export default EmptyDataComponent
