import _ from 'lodash';
import React, { useState } from 'react'
import {
    Modal, Typography, Card, CardContent,
    CardActions, Button, Grid, TextField,
    InputAdornment, CardHeader,
    FormGroup, FormControlLabel, Checkbox
} from '@mui/material';

import { createRecord } from '../../../../../APICalls';
import { useEffect } from 'react';
import { STORAGE_KEYS } from '../../../../../helpers/helpers';

const REMOVE_FIELDS = ["clientId", "clientName", "createdBy", "modifiedBy"]

const UploadMasterDataConfig = ({
    openModal, handleCloseModal, originalColumns,
    refreshData, selectedMasterDataCollection
}) => {

    const [token, setToken] = useState(null);
    const [fieldErrors, setFieldErrors] = useState(false)
    const [registerData, setRegisterData] = useState({
        'shiptoaddress': "", 'shiptoparty': "", "soldtoaddress": "", 'soldtoparty': "", 'materialdescription': "", 'materialnumber': "", 'salesorganization': "", 'distributionchannel': "",
        'division': "", "salesdistrict": "", "salesoffice": "", "salesgroup": "", "paymentterms": "", "equipmenttype": "", "ordertype": "", "orderreason": "", "partnerfunction": "",
        "partnernumber": "", "shippingcondition": "", "suom": "", 'plant': "", 'countryofdeliveringplant': "", 'incoterms': "", 'incotermsdescription': ""
    });
    const [succesfully, setSuccesfully] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const checkedFieldRemove = (element) => {
        return REMOVE_FIELDS.includes(element)
    }

    const isEmptyData = () => {
        return (Object.values(registerData).some((element) => element === ""))
    }

    const handleFillData = (field) => (event) => {
        setRegisterData({
            ...registerData,
            [field]: event.target.value.trim()
        })
    }

    const callCreateRecordAPI = async () => {
        const {
            shiptoaddress, shiptoparty, soldtoaddress, soldtoparty, materialdescription, materialnumber, salesorganization, distributionchannel, division, salesdistrict,
            salesoffice, salesgroup, paymentterms, equipmenttype, ordertype, orderreason, partnerfunction, partnernumber, shippingcondition,
            suom, plant, countryofdeliveringplant, incoterms, incotermsdescription,
            clientId,
            clientName,
            createdBy,
            modifiedBy,
        } = registerData
        const response = await createRecord(token, shiptoaddress, shiptoparty, soldtoaddress, soldtoparty, materialdescription, materialnumber,
            salesorganization, distributionchannel, division, salesdistrict, salesoffice, salesgroup, paymentterms, equipmenttype, ordertype,
            orderreason, partnerfunction, partnernumber, shippingcondition, suom, plant, countryofdeliveringplant, incoterms,
            incotermsdescription, selectedMasterDataCollection, clientId, clientName, createdBy, modifiedBy)
        if (response === "Successful") {
            setSuccesfully(true);
            setErrorMessage(null);
            refreshData();
            handleCloseModal();
        } else {
            const { message } = response
            setErrorMessage(message)
            setSuccesfully(false);
        }
    }

    const handleUploadData = () => {
        setSuccesfully(false);
        setErrorMessage(null);
        if (isEmptyData()) {
            setFieldErrors(true);
            return;
        } else {
            callCreateRecordAPI();
        }
    }

    useEffect(() => {
        const tokenData = localStorage.getItem(STORAGE_KEYS.TOKEN);
        setToken(tokenData);
    }, [])

    return (
        <Modal
            open={openModal}
            onClose={handleCloseModal}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
            }}
        >
            <Grid
                container
                justifyContent="center"
                flexDirection="row"
            >
                <Card
                    style={{
                        width: "100%",
                    }}
                >
                    <CardActions
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            size="small"
                            variant="contained"
                            color='error'
                            onClick={handleCloseModal}
                        >
                            X
                        </Button>
                    </CardActions>
                    <CardContent>
                        <Typography
                            variant="h4"
                            textAlign="center"
                        >
                            Upload new Master Data
                        </Typography>
                        <Grid
                            container
                            justifyContent="center"
                            textAlign="center"
                            paddingTop={5}
                        >
                            <Grid item>
                                <h4>Please fill ALL the below fields:</h4>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2} alignItems="center" paddingTop={2}>
                                    {originalColumns.map((element) => {
                                        if (checkedFieldRemove(element)) {
                                            return null;
                                        }
                                        return <Grid item xs={6}>
                                            <Grid container alignItems="center">
                                                <Grid item xs={3}>
                                                    {element}:
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField
                                                        fullWidth
                                                        onInput={handleFillData(element)}
                                                        label={element}
                                                        variant="outlined"
                                                        error={fieldErrors && registerData[element] === ''}
                                                        helperText={fieldErrors && registerData[element] === '' ? "Missing field." : ""}
                                                        value={registerData[element]}
                                                    >
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            size="medium"
                            variant="contained"
                            color='info'
                            onClick={handleUploadData}
                        >
                            Create Record
                        </Button>
                    </CardActions>
                    {errorMessage && (
                        <Grid item xs={12}>
                            <h5 style={{
                                color: "#db5757"
                            }}>
                                Error when creating Record: {errorMessage}
                            </h5>
                        </Grid>
                    )}
                    {succesfully && (
                        <Grid item xs={12}>
                            <h5 style={{
                                color: "green"
                            }}>
                                Created succesfully!
                            </h5>
                        </Grid>
                    )}
                </Card >
            </Grid>
        </Modal >
    )
}

export default UploadMasterDataConfig;
