
import _ from 'lodash';

import React, { useEffect, useState, useRef } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { Divider, Grid } from '@mui/material';

import PropTypes from 'prop-types';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CustomNavigation from './Navigation/CustomNavigation';

const VIEWER_MIN_SCALE = 1;
const VIEWER_MAX_SCALE = 3;

const PDFViewer = ({ isInput, rowFile, processId }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [scale, setScale] = useState(VIEWER_MIN_SCALE);
    const [width, setWidth] = useState(0);
    const [rotation, setRotation] = useState(0);

    const downloadPDF = () => {
        // create "a" HTML element with href to file & click
        const a = document.createElement("a");
        a.href = URL.createObjectURL(new Blob([rowFile], {
            type: "text/plain"
        }));
        // file extension, PDF
        a.setAttribute("download", `AttatchmentPDF.pdf`);
        document.body.appendChild(a);
        a.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(a);
    }


    const ErrorComponent = () => {
        return (
            <h2 style={{
                textAlign: "center"
            }}>
                No PDF information to show.
            </h2>
        )
    }

    const LoadingComponent = () => {
        return (
            <h2 style={{
                textAlign: "center"
            }}>
                Loading PDF, please wait...
            </h2>
        )
    }

    const pdfMessage = () => {
        return isInput
            ? "PDF Input Viewer:"
            : "PDF Output Viewer:";
    }

    // Reference to modify the PDF width Value to its container.
    const refPDFContainer = useRef(0)

    const onDocumentLoadSuccess = ({ numPages }) => setTotalPages(numPages);

    useEffect(() => {
        const widthValue = refPDFContainer.current.offsetWidth;
        if (!_.isNil(widthValue)) {
            setWidth(widthValue);
        }
    }, [refPDFContainer.current.offsetWidth])

    return (
        <div>
            <Grid
                item
                className="pdfContainer"
                sm={12} md={12} xs={12}
            >
                {/*<Grid
                    paddingTop={3}
                    paddingBottom={3}
                >
                    <Button
                        variant="contained"
                        size="large"
                        color="success"
                        onClick={() => downloadPDF(rowFile)}
                    >
                        <b>
                            Download PDF
                        </b>
                    </Button>
                </Grid>
                */}
                <div
                    style={{
                        overflow: "auto"
                    }}
                    ref={refPDFContainer}>
                    <Document
                        className="canvasCss"
                        file={rowFile}
                        loading={LoadingComponent}
                        error={ErrorComponent}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page
                            className="pageContainer"
                            loading={LoadingComponent}
                            error={ErrorComponent}
                            pageNumber={currentPage}
                            renderAnnotationLayer
                            renderTextLayer
                            scale={scale}
                            width={width}
                            rotate={rotation}
                        />
                    </Document>
                </div>
            </Grid>
            {totalPages && (
                <Grid
                    item
                    className="containerNavbar"
                    sm={12} md={12} xs={12}
                >
                    <CustomNavigation
                        currentPage={currentPage}
                        maxScale={VIEWER_MAX_SCALE}
                        minScale={VIEWER_MIN_SCALE}
                        scale={scale}
                        setCurrentPage={setCurrentPage}
                        setScale={setScale}
                        totalPages={totalPages}
                        rotation={rotation}
                        setRotation={setRotation}
                    />
                </Grid>
            )}
        </div>
    );
}

PDFViewer.propTypes = {
    rowFile: PropTypes.string.isRequired,
};

export default PDFViewer;
