import React from "react";
import { Grid } from "@mui/material";

import DataObjectIcon from '@mui/icons-material/DataObject';
import './email.css';

const LoadHTMLFromExcel = ({ rowFile }) => {

  const visualizeHTML = () => {
    return {
      __html: rowFile
    }
  }

  return (
    <Grid
      container
      textAlign="left"
    >
      <Grid
        item
        xs={12}
      >
        <DataObjectIcon
          fontSize="large"
        />
        <b style={{
          color: "blue",
          paddingLeft: "1%",
        }}
        >
          Attached Excel Information Viewer:
        </b>
        {<div
          className="article"
          dangerouslySetInnerHTML={visualizeHTML()}
        />}
      </Grid>
    </Grid>
  )
}

export default LoadHTMLFromExcel;
