import React, { useState } from 'react'
import {
    Modal, Typography, Card, CardContent, TextField,
    CardActions, Button, Grid, AppBar, Toolbar, InputAdornment
} from '@mui/material';

import ContactMailIcon from '@mui/icons-material/ContactMail';

import { inviteUsers } from '../../../../../APICalls';

const InviteNewUser = ({
    openModal, handleCloseModal, token
}) => {
    const [email, setEmail] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [responseError, setResponseError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);


    const handleEmail = (event) => {
        setEmail(event.target.value)
    }

    const handleInviteUser = async () => {
        setEmailError(null);
        setSuccessMessage(null);
        setResponseError(null);
        if (email) {
            const { status, data } = await inviteUsers(token, email);
            if (status === 200) {
                setSuccessMessage("User invited succesfully!");
            } else {
                setResponseError(data.message);
            }
        } else {
            setEmailError('Missing email field. Please Check.');
        }

    }


    return (
        <Modal
            open={openModal}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Grid
                container
                justifyContent="center"
            >
                <Card
                    style={{
                        width: "60%",
                    }}
                >
                    <AppBar position="relative">
                        <Toolbar className="toolbarStyle">
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <h2>
                                        Invite new user to your organization
                                    </h2>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <CardContent>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <p>Please write the email of the user you want to invite to your organization</p>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    size="small"
                                    placeholder='User Email'
                                    onChange={handleEmail}
                                    value={email}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment
                                                position="start"
                                                className="text-primary"
                                            >
                                                {<ContactMailIcon />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {emailError && (
                            <p
                                style={{
                                    color: "red",
                                    paddingTop: "5%"
                                }}
                            >
                                Error: {emailError}
                            </p>
                        )}
                        {successMessage && (
                            <p
                                style={{
                                    color: "green",
                                    paddingTop: "5%"
                                }}
                            >
                                {successMessage}
                            </p>
                        )}
                        {responseError && (
                            <p
                                style={{
                                    color: "red",
                                    paddingTop: "5%"
                                }}
                            >
                                {responseError}
                            </p>
                        )}
                    </CardContent>
                    <CardActions
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            size="medium"
                            variant="contained"
                            color='error'
                            onClick={handleCloseModal}
                        >
                            Close
                        </Button>
                        <Button
                            size="medium"
                            variant="contained"
                            color='info'
                            onClick={handleInviteUser}
                        >
                            Invite User
                        </Button>
                    </CardActions>
                </Card >
            </Grid>
        </Modal >
    )
}

export default InviteNewUser;
