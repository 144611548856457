import PropTypes from 'prop-types';
import React from 'react';

import CustomNextButton from './CustomNextButton.js';
import CustomPages from './CustomPages.js';
import CustomPrevButton from './CustomPrevButton.js';
import CustomZoomIn from './CustomZoomIn.js';
import CustomZoomOut from './CustomZoomOut.js';
import CustomRotateLeft from './CustomRotateLeft.js';
import CustomRotateRight from './CustomRotateRight.js';

import './styles.css';


const CustomNavigation = ({
  currentPage, maxScale, minScale, scale, setCurrentPage, setScale,
  totalPages, rotation, setRotation
}) => {
  const handleNextButton = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevButton = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleZoomIn = () => {
    setScale(scale + 0.50);
  };

  const handleZoomOut = () => {
    setScale(scale - 0.50);
  };

  const handleRotateLeft = () => {
    setRotation(rotation - 90)
  }

  const handleRotateRight = () => {
    setRotation(rotation + 90)
  }

  return (
    <div className='containerNavbar'>

      <CustomRotateLeft
        handleRotateLeft={handleRotateLeft}
      />
      {
        (scale > minScale) && (
          <CustomZoomOut handleZoomOut={handleZoomOut} />
        )
      }
      {
        (currentPage !== 1) && (
          <CustomPrevButton handlePrevButton={handlePrevButton} />
        )
      }
      <CustomPages
        page={currentPage}
        pages={totalPages}
      />
      {
        (currentPage !== totalPages) && (
          <CustomNextButton handleNextButton={handleNextButton} />
        )
      }
      {
        (scale < maxScale) && (
          <CustomZoomIn handleZoomIn={handleZoomIn} />
        )
      }
      <CustomRotateRight
        handleRotateRight={handleRotateRight}
      />
    </div >
  );
};
CustomNavigation.propTypes = {
  currentPage: PropTypes.number.isRequired,
  maxScale: PropTypes.number.isRequired,
  minScale: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setScale: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default CustomNavigation;