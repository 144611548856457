import React, { useEffect, useState } from 'react'

import {
    Grid, Typography
} from '@mui/material';
import { useLocation } from 'react-router-dom';

import ContainerSalesOrderNew from './ContainerSalesOrderNew';
import ContactUsSuccessPage from '../ContactUsSuccessPage';

const ContactUsMainPageSalesOrderNew = () => {
    const { pathname } = useLocation();
    const [success, setSuccess] = useState(false);
    const [pageNumberValue, setPageNumberValue] = useState(null);

    const validateCase = () => {
        const checkedString = pathname.split("/");
        const pageNumber = checkedString[checkedString.length - 1];
        const validatedString = pageNumber.includes(":")
            ? pageNumber.replace(":", "")
            : pageNumber;
        setPageNumberValue(Number(validatedString));
    }

    useEffect(() => {
        validateCase();
        if (pathname.includes("success")) {
            setSuccess(true);
        }
    }, [pathname])

    return (
        <Grid
            container
            alignItems={success ? 'center' : 'auto'}
            style={{
                backgroundColor: "white",
                backgroundImage: 'url(https://vao-open-bucket.s3.amazonaws.com/landing-page/Main-Background.jpg',
                backgroundSize: '100% 50%',
                width: '100%',
                height: 'auto'
            }}
            justifyContent="center"
        >
            {success
                ? <ContactUsSuccessPage />
                : <ContainerSalesOrderNew
                    setSuccess={setSuccess}
                    pageNumber={pageNumberValue} />
            }
        </Grid >
    )
}

export default ContactUsMainPageSalesOrderNew;