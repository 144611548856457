
import PropTypes from 'prop-types';
import React from 'react';

import RotateRightIcon from '@mui/icons-material/RotateRight';

const CustomRotateRight = ({
  handleRotateRight,
}) => (
  <div className="zoomButtonContainer"
  >
    <RotateRightIcon
      className="navbarIcons"
      onClick={handleRotateRight}
      size="sm"
    />
  </div >
);

CustomRotateRight.propTypes = {
  handleZoomIn: PropTypes.func.isRequired,
};

export default (CustomRotateRight);