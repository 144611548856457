import { CircularProgress, Grid } from '@mui/material';
import React from 'react'

const LoadingComponent = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      paddingTop="3%"
      style={{
        textAlign: "center"
      }}
    >
      <Grid item xs={12}>
        <CircularProgress color="info" />
      </Grid>
      <Grid item xs={12}>
        <h2>
          Loading data, please wait...
        </h2>
      </Grid>
    </Grid>
  )
}

export default LoadingComponent;
