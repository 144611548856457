import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
    Button,
    Modal,
    Paper,
    Typography,
    IconButton,
    MenuItem,
    Grid
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Select from 'react-select';

import { getMailboxesUsersList, updateUserMailboxes } from '../../../../../APICalls';
import { STORAGE_KEYS } from '../../../../../utils/utils';

export const MailboxesModal = ({
    user, handleShowModal, handleCloseModal, setRefreshData
}) => {
    const mailboxes = user['app_metadata']['mailboxes']
    const [userMailboxes, setUserMailboxes] = useState(null);
    const [updatedSuccesfully, setUpdatedSuccesfully] = useState(false);
    const [updatedError, setUpdatedError] = useState(false);
    const [tokenData, setTokenData] = useState(null);
    const [optionsMailboxes, setOptionsMailboxes] = useState(null);
    const [selectedMailboxes, setSelectedMailboxes] = useState(null);

    const updateMailboxesCall = async () => {
        const { user_id } = user;
        setUpdatedSuccesfully(false);
        setUpdatedError(false);

        await updateUserMailboxes(tokenData, user_id, selectedMailboxes)
            .then(response => {
                if (response === 'Successful') {
                    setTimeout(() => setRefreshData(true), 1500);
                    setUpdatedSuccesfully(true);
                    setUpdatedError(false);
                } else {
                    setUpdatedError(true);
                    setUpdatedSuccesfully(false);
                }
            });
    }

    const callUpdateMailboxesAPI = () => {
        updateMailboxesCall();
    }

    const apiCallMailboxes = async () => {
        const { status, data } = await getMailboxesUsersList(localStorage.getItem(STORAGE_KEYS.TOKEN));
        if (status === 200) {
            const parsedMailboxes = data.map((element) => {
                return { label: element, value: element }
            })
            setOptionsMailboxes(parsedMailboxes);
        }
    };

    const handleOnChangeSelect = (value) => {
        const newValue = value.map((element) => {
            return element.value
        })
        setSelectedMailboxes(newValue);
    }

    useEffect(() => {
        const tokenData = localStorage.getItem(STORAGE_KEYS.TOKEN);
        setTokenData(tokenData);
        apiCallMailboxes();
        setUserMailboxes(mailboxes);
        setSelectedMailboxes(mailboxes);
    }, []);

    const validateMailboxes = () => {
        return (
            <Select
                isMulti
                maxMenuHeight={240}
                menuPlacement="bottom"
                options={optionsMailboxes}
                placeholder="Mailboxes"
                onChange={handleOnChangeSelect}
                style={{ width: '100%' }}
                value={(!_.isNil(selectedMailboxes) || !_.isEmpty(selectedMailboxes))
                    ? selectedMailboxes.map((element) => {
                        return { label: element, value: element }
                    })
                    : []}
            />
        )
    }

    return (
        <Modal
            open={handleShowModal}
            onClose={handleCloseModal}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Paper style={{ width: "60%", position: 'relative', padding: '20px' }}>
                <IconButton
                    style={{ position: 'absolute', top: '5px', right: '5px' }}
                    onClick={handleCloseModal}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '10px', marginTop: '10px' }}>
                    <b>Manage Mailboxes</b>
                </Typography>
                <Typography><b>User ID:</b> {user['user_id']}</Typography>
                <Typography><b>Username:</b> {user['email']}</Typography>
                <Grid container paddingTop={2} paddingBottom={2} justifyContent='center'>
                    <Grid item xs={12} md={10}>
                        {validateMailboxes()}
                    </Grid>
                </Grid>
                {updatedSuccesfully && (
                    <Grid>
                        <div style={{ textAlign: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <b style={{ color: '#2e7d32' }}>Saved successfully!</b>

                        </div>
                    </Grid>
                )}
                {updatedError && (
                    <Grid>
                        <div style={{ textAlign: 'center', marginBottom: '10px', marginTop: '10px' }}>
                            <b style={{ color: '#FF5555' }}>Error when saving.</b>
                        </div>
                    </Grid>
                )}
                <div style={{ textAlign: 'right' }}>
                    <Button variant="contained" color="success" onClick={callUpdateMailboxesAPI}>
                        Save
                    </Button>
                </div>
            </Paper>
        </Modal>
    );
};
