import { STORAGE_KEYS } from "../helpers/helpers";
import { logoutAPI } from '../APICalls';

export const handleLogoutAPICall = async () => {
    await logoutAPI(localStorage.getItem(STORAGE_KEYS.TOKEN));
}

export const removeLocalStorage = () => {
    localStorage.removeItem(STORAGE_KEYS.USER);
    localStorage.removeItem(STORAGE_KEYS.TOKEN);
    localStorage.removeItem(STORAGE_KEYS.LOGGED_IN);
    localStorage.removeItem(STORAGE_KEYS.USER_TYPE);
    localStorage.removeItem(STORAGE_KEYS.USER_ID);
    localStorage.removeItem(STORAGE_KEYS.COMPANY_NAME);
    localStorage.removeItem(STORAGE_KEYS.ROLE_ID);
    localStorage.removeItem(STORAGE_KEYS.ID);
    localStorage.removeItem(STORAGE_KEYS.ORGANIZATION_ID);
    window.dispatchEvent(new Event("storage"));
};

const handleLogout = (callback = () => { }) => {
    handleLogoutAPICall();
    removeLocalStorage();
    callback();
}

export default handleLogout;
