import React from "react";

const ErrorComponent = ({ message, show }) => (
    show && (
        <div style={{ margin: '3px 14px 0px', textAlign: 'left', color: '#d32f2f', fontSize: '0.75rem', fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}>
            {message}
        </div>
    )
);

export default ErrorComponent;
