import React, { useEffect, useState } from 'react'

import {
    Grid, Typography, Card, CardContent, Button, Tooltip, Divider
} from '@mui/material';

import WebFont from 'webfontloader';

import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SpeedIcon from '@mui/icons-material/Speed';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FaqView from '../../Home/HomeComponents/ProductSection/FAQSection';

import ContactUsFillPageTranslucent from '../ContactUsFillPageTranslucent';

const REVIEWS = [
    "How does AI technology differentiate the best order management software from traditional solutions?",
    "Provide examples of successful implementations of order management solutions in manufacture and wholesale industries",
    "What are the cost implications of mis-shipments, and how can a order management platform mitigate these costs?",
    "How does order entry software contribute to improved accuracy and speed in order processing?",
    "How does your b2b order management platform handle customization requirements for different clients?"
]

const InnerMessagesAndImages = [
    { "id": 1, "message": "Save up to 86% on order processing costs", "image": "https://vao-open-bucket.s3.amazonaws.com/landing-page/Consultation1.png" },
    { "id": 2, "message": "Increase efficiency by 32%", "image": "https://vao-open-bucket.s3.amazonaws.com/landing-page/Consultation2.png" },
    { "id": 3, "message": "Reduce order Processing time up to 93%", "image": "https://vao-open-bucket.s3.amazonaws.com/landing-page/Consultation3.png" }
]

const ContainerSalesOrderNew = ({ setSuccess, pageNumber }) => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [selectedElement, setSelectedElement] = useState(null);
    const [isVisibleScroll, setIsVisibleScroll] = useState(false);

    const getElementValue = (pageNumber) => {
        const valueElement = InnerMessagesAndImages.find(element => element.id === pageNumber);
        setSelectedElement(valueElement);
    }

    useEffect(() => {
        if (pageNumber) {
            getElementValue(pageNumber);
        }
    }, [pageNumber])

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setIsVisibleScroll(true);
            } else {
                setIsVisibleScroll(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Montserrat:300,400,500,700', 'Saira:300,400,500,700', 'sans-serif'],
            },
        });
    }, []);

    return (
        <>
            <Grid item xs={12} md={6}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                            spacing={isSmallScreen ? 5 : 6}
                            padding={isSmallScreen ? 3 : 6}
                            paddingLeft={isSmallScreen ? 5 : 15}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    variant="h5"
                                    textAlign="left"
                                    style={{
                                        fontFamily: 'Montserrat, sans-serif',
                                        color: "#02dbd5"
                                    }}
                                >
                                    <b>
                                        Sales Order Automation
                                    </b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h4"
                                    textAlign="left"
                                    style={{
                                        fontFamily: 'Montserrat, sans-serif',
                                        color: "white"
                                    }}
                                >
                                    <b>
                                        Transform your Order Management with AI-Driven Automation
                                    </b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    textAlign="left"
                                    style={{
                                        fontFamily: 'Montserrat, sans-serif',
                                        color: "white"
                                    }}
                                >

                                    Effortlessly convert emailed orders into digital transactions with our cutting-edge AI solution, designed specifically for manufacturers
                                    and distributors. Achieve the speed and accuracy of EDI and e-commerce transactions while delivering exceptional customer experiences. Harness
                                    the power of automation to grow your business and satisfy your clients.
                                    <br />
                                    <br />
                                    Start today by booking a 30-minute intro call to discover how AI-driven automation can transform your business.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid >
            </Grid >
            <Grid item xs={12} md={6}>
                <Grid container
                    alignItems="center"
                    justifyContent="center"
                    paddingTop={isSmallScreen ? 5 : 2}
                    paddingBottom={isSmallScreen ? 5 : 2}
                    paddingLeft={isSmallScreen ? 2 : 5}
                    paddingRight={isSmallScreen ? 2 : 5}
                >
                    <Grid item xs={10}>
                        <ContactUsFillPageTranslucent setSuccess={setSuccess} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} paddingTop={10}>
                <Grid
                    container
                    spacing={5}
                    justifyContent="center"
                    padding={isSmallScreen ? 5 : 10}
                    paddingTop={0}
                    style={{
                        background: 'white'
                    }}
                >
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            textAlign="center"
                            style={{
                                fontFamily: 'Montserrat, sans-serif',
                                color: "#1a4047"
                            }}
                        >
                            <b>
                                How Can We Elevate Your Business?
                            </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} md={4}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <SpeedIcon style={{ fontSize: "80px", color: "#02dbd5" }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h6"
                                            textAlign="center"
                                            style={{
                                                fontFamily: 'Montserrat, sans-serif',
                                                color: "#1a4047"
                                            }}
                                        >
                                            Boost our On-Time, In-Full shipment <b>up to 23%</b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TrendingDownIcon style={{ fontSize: "80px", color: "#02dbd5" }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h6"
                                            textAlign="center"
                                            style={{
                                                fontFamily: 'Montserrat, sans-serif',
                                                color: "#1a4047"
                                            }}
                                        >
                                            Reduce <b>up to 35%</b> of customer churn.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <FavoriteIcon style={{ fontSize: "80px", color: "#02dbd5" }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h6"
                                            textAlign="center"
                                            style={{
                                                fontFamily: 'Montserrat, sans-serif',
                                                color: "#1a4047"
                                            }}
                                        >
                                            Elevate your CSAT and Net Promoter Score
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={10}>
                        <Divider style={{
                            background: "grey",
                            borderBottomWidth: 3
                        }} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} paddingTop={3} style={{ background: "white" }}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={12}
                        md={8}
                    >
                        <FaqView />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <img
                            src={'https://vao-open-bucket.s3.amazonaws.com/landing-page/Man.png'}
                            style={{
                                maxWidth: isSmallScreen ? "50%" : "80%",
                                height: "auto",
                            }}
                            alt="Background hero"
                        >
                        </img>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ContainerSalesOrderNew;