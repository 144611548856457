
import PropTypes from 'prop-types';
import React from 'react';

import RotateLeftIcon from '@mui/icons-material/RotateLeft';

const CustomRotateLeft = ({
  handleRotateLeft,
}) => (
  <div className="zoomButtonContainer"
  >
    <RotateLeftIcon
      className="navbarIcons"
      onClick={handleRotateLeft}
      size="sm"
    />
  </div >
);

CustomRotateLeft.propTypes = {
  handleZoomIn: PropTypes.func.isRequired,
};

export default (CustomRotateLeft);