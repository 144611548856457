import { Grid } from '@mui/material'
import React from 'react'

const EmptyDataComponent = () => {
  return (
    <Grid
      justifyContent="center"
      alignItems="stretch"
      textAlign="center"
    >
      <h2>
        There's no data to show.
      </h2>
    </Grid>
  )
}

export default EmptyDataComponent;